.c-search{
  position: relative;
  z-index: 1;
  background-color: var(--fondo-gris);
  padding: 16px;
  margin-bottom: 16px;
  &__form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    input[type="text"]{
      position: relative;
      z-index: 1;
      width: calc(100% - 100px);
      height: 44px;
      padding: 0 15px;
      font-size: 16px;
      color: var(--texto);
      border-radius: 6px;
      border: solid 1px var(--bordes);
      background-color: var(--blanco);
      font-family: var(--primary-font);
    }


  }
  &__btn{
        background-color: var(--enlace);
        border-radius: 6px;
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        width: min-content;
        height: 44px;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--blanco);
        cursor: pointer;
        transition: box-shadow .3s;
        font-family: var(--primary-font);
        padding: 0 16px;
        @media (--screen-md-min) {
          height: 35px;
        }
        span{
            &:not(.sr-only){
                display: block;
            }
        }
        .icon{
            display: none;
        }
        &:hover{
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        }
        &:active{
            border: solid 1px var(--enlace-oscuro);
        }
        &:focus{
            box-shadow: 0 0 4px 0 var(--enlace);
            background-color: var(--enlace);
        }

    }
  &__close{
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }
  &__title{
    font-family: var(--third-font);
    font-size: 28px;
    font-weight: 600;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: var(--texto-secundario);
    margin-bottom: 10px;
    @media (--screen-md-min) {
      font-size: 36px;
    }
  }
  &__filter{
    width:100%;
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (--screen-sm-min) {
      padding-top: 16px;
    }
    &__title{
      font-size: 16px;
      font-weight: 500;
      color: var(--texto-suave);
    }
  }
  &__radio{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    line-height: 1;
    position: relative;
    padding-left: 16px;
    input[type="radio"]{
      display: none;
      &:checked{
        & + .fake-check{
          &:after {
            background: var(--texto-secundario);
          }
        }
      }
    }
    .fake-check{
      border: 1px solid var(--texto-secundario);
      border-radius: 50%;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &:after {
        content: '';
        display: block;
        background: var(--fondo-gris);
        cursor: pointer;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transition: background-color .3s;
      }
    }
    p{
      color: var(--texto-secundario);
      font-size: 16px;
    }
  }
  &__list{
    @media (--screen-sm-max) {
      .u-show-xs {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--bordes);
      }
    }

    &-item{
      display: grid;
      grid-template-columns: 72px 1fr 120px;
      grid-column-gap: 10px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--bordes);
      position: relative;
      &__date{
        color: var(--texto-secundario);
        font-size: 11px;
        grid-column: 1 / span 2;
        @media (--screen-md-min) {
          font-size: 14px;
          grid-column: 1 / span 1;
        }
      }
      &__txt{
        grid-column: 1 / span 2;
        grid-row-start: 2;
        @media (--screen-md-min) {
          grid-row-start: 1;
          grid-column: 2 / span 1;
        }
      }
      &__thumb{
        grid-row:1 / span 2 ;
        @media (--screen-md-min) {
          grid-column: 3 / span 1 ;
          grid-row:1 / span 1 ;
        }
      }

    }

  }
  @media (--screen-sm-min) {
    padding: 20px;
    margin-bottom: 20px;
  }
  &-megamenu{
    margin-bottom: 0;
    padding-right: 60px;
    display: flex;
    align-items: center;
    gap: 8px;
    .c-search__form input[type="text"]{
        width: 100%;
    }
      @media (--screen-sm-min) {
          padding: 20px 60px 20px 20px;
          margin-bottom: 0;
      }
  }
}
