.home-boards {
  & > div {
    &:first-of-type {
      .o-container {
        padding-top: 16px;
        @media (--screen-md-min) {
          padding-top: 20px;
        }
      }
    }
    &:last-of-type {
      .o-container {
        padding-bottom:16px;
        @media (--screen-md-min) {
          padding-bottom:20px;
        }
        &-board {
          &:after {
            display:none;
          }
        }
      }
    }
  }
  @media (--screen-xs-max) {
    /*Bloque 3 temas*/
    .u-grid-col-3 {
      & > div {
        &:last-of-type {
          .c-article {
            &-opinion {
              &-double {
                &:last-child {
                  margin-bottom: 0 !important;
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
        &:not(:last-of-type ) {
          .c-article {
            &-double {
              &.c-article-last {
                margin-bottom:16px !important;
                &:after {
                  display:block;
                }
              }
              &-opinion {
                &.c-article-last {
                  margin-bottom:32px !important;
                  &:after {
                    display:block;
                  }
                }
              }
            }
          }
        }
        &:only-of-type {
          .c-article {
            &-opinion {
              &-double {
                &:last-child {
                  margin-bottom: 32px !important;
                  &:after {
                    display: block;
                  }
                }
              }
            }
            &-double {
              &.c-article-last {
                &:last-child {
                  margin-bottom:16px !important;
                  &:after {
                    display:block;
                  }
                }
              }
            }
          }
        }
      }
      .c-article {
        &-perfil {
          &:not(:last-child) {
            &.u-nomargin-bottom {
              /*margin-bottom:16px !important;*/
            }
          }
        }
        &-opinion {
          &:last-child {
            &.u-nomargin-bottom {
             /* margin-bottom:0 !important;*/
            }
          }
          &-double {
            &.u-nomargin-bottom {
              &:last-child {
                margin-bottom:32px !important;
                &:after {
                 display:block;
                }
              }
            }
          }
        }
      }
    }
    .u-margin {
      &-bt-xs {
        &.c-article-lateral {
          &.c-article-opinion {
            margin-bottom:32px !important;
              &.c-article-lateral-sm-only{
                  margin-bottom:16px !important;
              }
          }
        }
      }
    }
  }
  .txt_cartas {
    font-size: 14px;
    line-height: normal;
    font-family:  var(--primary-font);
    color: var(--texto);
    @media (--screen-sm-min) {
      font-size: 16px;
      line-height: 1.2;
    }
    a {
      color: var(--enlace);
      display: inline;
    }
  }
}
