/* TODO : Generate webfonts */
@font-face {
  font-family: ChronicleDispCondSemibold;
  src: local(ChronicleDispCondSemibold),
  url(./fonts/ChronicleDispCondSemibold.woff2) format("woff2"),
  url(./fonts/ChronicleDispCondSemibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: ChronicleTextG1-Roman;
  src: local(ChronicleTextG1-Roman),
  url(./fonts/ChronicleTextG1Roman.woff2) format("woff2"),
  url(./fonts/ChronicleTextG1Roman.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Flama';
  font-style: normal;
  font-weight: normal;
  src: local('Flama'), url('./fonts/FlamaRegular.woff') format('woff');
}

@font-face {
    font-family: "Flama-Bold";
    src: local("Flama-Bold"), url(./fonts/Flama-Bold.woff) format("woff"),
    url(./fonts/Flama-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Flama-Semibold";
    src: local("Flama-Semibold"), url(./fonts/Flama-Semibold.woff) format("woff"),
    url(./fonts/Flama-Semibold.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Flama-Basic";
    src: local("Flama-Basic"), url(./fonts/Flama-Basic.woff) format("woff"),
    url(./fonts/Flama-Basic.woff2) format("woff2");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}
