.c-header {
    & + .c-header > .o-container { /* astrolabio */
        @media (--screen-sm-max) {
            padding: 0;
        }
    }

& > .o-container {
    position: relative;
    min-height: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: var(--c-header-bg);
            padding-top: 10px;
    @media (--screen-sm-max) {
        border-bottom: 1px solid var(--c-header-border2-color);
    }
    @media (--screen-xs-max) {
        padding-bottom: 10px;
    }
}
&__foundation {
    font-size: 10px;
    line-height: 7px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--acento);
    margin-top: 5px;
    @media (--screen-sm-min) {
        font-size: 22px;
        line-height: 24px;
        padding-bottom: 20px;
        margin-top: 0;
    }
}
&__brand {
    &-mini {
        height: 35px;
        padding: 10px 0;
    }
    margin: 0 auto;
    width: 232px;
    height: 22.238px;
    transition: width 0.3s, height 0.3s;
    a {
        color: var(--acento);
        display: block;
        line-height: 0;
        svg {
            width: 100%;
            height: 100%;
        }
        span {
            overflow: hidden;
            display: block;
            text-indent: -9999px;
            width: 0;
            height: 0;
        }
    }
    @media (--screen-md-min) {
        width: 540px;
        height: 52px;
        margin-top: 32px;
    }
}
&__nav {
    &-btn {
        --width: 18px;
        --height: 12px;
        --height-slash: 2px;
        position: absolute;
        top: 0;
        left: 0;
        @media (--screen-md-min) {
            --height-slash: 4px;
            --height: 40px;
            display: none;
        }
        outline: none;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        width: var(--width);
        height: var(--height);

        padding: 26px 15px;
        box-sizing: content-box;
        transition: background-color 0.3s;

        .hamburger-box {
            display: block;
            position: relative;
            margin: 0 auto;
        }

        .hamburger-inner {
            display: block;
            top: 50%;
            margin-top: -2px;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            width: var(--width);
            height: var(--height-slash);
            background-color: var(--c-header-burger-color);
            border-radius: var(--height-slash);
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        .hamburger-inner::before,
        .hamburger-inner::after {
            content: "";
            display: block;
        }

        .hamburger-inner::before {
            top: -5px;
        }

        .hamburger-inner::after {
            bottom: -5px;
        }

        &-2 {
            display: block;
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                width: unset;
                height: unset;
                background-color: unset;
                border-radius: unset;
                position: unset;
                transition-property: unset;
                transition-duration: unset;
                transition-timing-function: unset;
            }
        }
    }
    &-btn-search {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: var(--c-header-search-color);
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-transform: uppercase;
        cursor: pointer;
        position: absolute;
        right: 0;
        z-index: 1;
        svg {
            margin-right: 10px;
        }
        @media (--screen-md-min) {
            position: absolute;
            right: inherit;
            left: 10px;
            top: 10px;
        }
        &-2 {
            svg {
                color: var(--enlace);
            }
            @media (--screen-md-min) {
                position: absolute;
                right: 0;
                left: unset;
                top: unset;
            }
        }
    }
}
&__bottom {
    @media (--screen-md-min) {
        width: 100%;
        border-bottom: 1px solid var(--c-header-border-color);
        margin-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 3;
        .c-header__date {
            margin-left: auto;
        }
    }
    @media (--screen-sm-max) {
        .c-header__date {
            display: none;
        }
    }
}
&__top {
    display: none;
    @media (--screen-md-min) {
        width: 100%;
        position: relative;
        padding-top: 10px;
        padding-bottom: 20px;
        display: block;
    }
}
&__cite {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: var(--texto-suave);
    span {
        padding-left: 5px;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: var(--texto-secundario);
        &:before {
            content: "-";
        }
    }
}
&__share {
    display: flex;
    justify-self: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media (--screen-md-min) {
        position: absolute;
        right: 20px;
        top: 6px;
    }
    a {
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: var(--acento);
        color: var(--blanco);
        padding: 6px;
        svg {
            width: 100%;
            height: 100%;
        }
        &:not(:last-child) {
            margin-right: 4px;
        }
    }
}
&__claim {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    &__thumb {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        margin-left: 10px;
        margin-right: 6px;
        .c-detail__media__thumb img {
            width: 40px;
            height: 42px;
            margin: 10px 8px 0 0;
            object-fit: contain;
            @media (--screen-md-min) {
                margin-top: 0;
                margin-right: 10px;
                width: 51px;
                height: 54px;
            }
        }
        figcaption {
            margin: 10px 0;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            white-space: nowrap;
            color: var(--acento);
            @media (--screen-md-min) {
                margin: 10px 0 12px 0;
                font-size: 14px;
                width: auto;
            }
        }
        @media (--screen-md-min) {
            align-items: flex-start;
            margin-left: 0;
        }
    }
    &__title {
        font-family: var(--c-header-claim-title-font);
        margin: 10px 0;
        font-size: 19px;
        font-style: italic;
        line-height: 22px;
        letter-spacing: -0.23px;
        text-align: left;
        color: var(--enlace-oscuro);
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        @media (--screen-md-min) {
            position: absolute;
            left: 61px;
            top: 30px;
            padding: 0;
            font-size: 20px;
            margin: 10px 0;
            margin: unset;
        }
    }
}
&__date {
    display: none;
    @media (--screen-lg-min) {
        display: block;
        position: absolute;
        top: 77px;
        right: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: var(--texto-secundario);
    }
}
&-sticky {
    & > .o-container {
        border-top: 2px solid var(--c-header-border-color);
        min-height: var(--c-header-height);
    }
    @media (--screen-sm-max) {
        position: sticky;
        top: 0;
        z-index: 3;
    }
}
&__app {
    &__claim {
        background-color: #fff;
        .c-header__claim {
            &::before {
                content: "";
                display: block;
                width: 100%;
                left: 0;
                border-bottom: 1px solid var(--negro);
                position: absolute;
            }
            @media (--screen-md-min) {
                position: unset;
            }
            &__thumb {
                .c-detail__media__thumb img {
                    @media (--screen-md-min) {
                        margin-top: 10px;
                    }
                }
            }
            &__title {
                @media (--screen-md-min) {
                    left: 68px;
                }
            }
        }
        @media (--screen-md-min) {
            padding: 0 10px;
        }
    }
}
&__options {
    position: absolute;
    top: 13px;
    right: 16px;
    .c-header__user {
        cursor: pointer;
    }
    &-vertical {
        bottom: 9px;
        top: unset;

    }
}
&__user {
    span {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        background-color: var(--auxiliares-acierto);
        color: var(--blanco);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        display: block;
    }
}
/* cabecera de sección */
&-section {
    @media (--screen-sm-max) {
        &:not(.c-header-sticky) {
            display: none;
        }
    }
    @media (--screen-sm-min) {
        & > .o-container:after {
            content: "";
            height: 2px;
            width: 100%;
            background-color: var(--c-header-bg);
            position: absolute;
            bottom: -3px;
        }
    }
    & > .o-container {
        @media (--screen-md-min) {
            /*padding-top: 20px;*/
            margin-bottom: 2px;
            border-bottom: 1px solid var(--c-header-border-color);
        }
    }
    .c-header__bottom {
        justify-content: center;
    }
    .c-header__brand {
        @media (--screen-md-min) {
            width: 314px;
            height: 30px;
            margin-top: 0;
        }
    }
    .c-header__foundation {
        @media (--screen-md-min) {
            font-size: 20px;
            line-height: 20px;
            padding-bottom: 20px;
        }
    }

    &.c-header-tv {
        .c-header__brand {
            width: 230px;
            height: 39px;
            @media (--screen-md-min) {
                width: 300px;
                height: 50px;
            }
        }
    }
    .c-header__options {
        top:10px;
        @media (--screen-sm-min) {
            top: 35px;
        }
    }
    & .o-container button.c-header__nav-btn-2 {
        @media (--screen-sm-min) {
            top: 30px;
        }
    }
}
&-special {
    background-color: var(--c-header-special-bg);
    height: 44px;
    > .o-container {
        flex-direction: row;
        justify-content: initial;
        align-items: initial;
        background-color: var(--c-header-special-bg);
        border-top: 0;
        min-height: 44px;
    }
    .c-header__brand {
        width: 168px;
        height: 44px;
        margin: 0;
        padding: 14px 0 0 14px;
        a {
            color: var(--c-header-special-color);
        }
        @media (--screen-md-min) {
            padding: 14px 0;
        }
    }
    .c-header__nav-btn {
        display: block;
        border: 0;
        height: 44px;
        padding: 0;
        right: 0;
        left: initial;
        .hamburger-inner {
            background-color: var(--c-header-special-burger);
            height: 2px;
            &::before,
            &::after {
                background-color: var(--c-header-special-burger);
                height: 2px;
            }
        }
        @media (--screen-sm-max) {
            margin-right: 14px;
        }
    }
    &.c-header-sticky {
        position: sticky;
        top: 0;
        z-index: 3;
        display: block;
    }
}
&__wrapper {
    display: initial;
    .o-section-search {
        position: absolute;
        top: 61px;
        left: 0;
        right: 0;
        @media (--screen-md-min) {
            top: -2px;
        }
    }
    .c-header__nav-btn {
        @media (--screen-md-min) {
            top: 4px;
            left:-4px;
        }
    }
    @media (--screen-md-min) {
        position: relative;
        .c-header {
            &.c-header-sticky {
                position: relative;
                + .c-header {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 3;
                    .o-container {
                        background: none;
                    }
                }
            }
        }
    }
    .c-header-special {
        &.c-header-sticky {
            position: sticky;
            top: 0;
            z-index: 3;
            display: block;
        }
    }
}
&-vertical {
    &.c-header-sticky {
        @media (--screen-sm-min) {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    }
    .o-container {
        --c-header-height: 45px;
        border-top: 1px solid var(--c-header-border-color);

        &.header-mini {
            --c-header-height: 85px;
            display: grid;
            grid-template-rows: 40px 30px;

            .c-header__brand {
                height: auto;
                &-mini {
                    padding: 0;
                    width: 168px;
                    height: 16px;
                    margin: 0 auto;
                }
            }
            .c-header__nav-btn {
                padding: 44px 0px 0px 15px;
                @media (--screen-sm-min) {
                    padding: 66px 0px 0px 15px;
                }
            }
            @media (--screen-sm-min) {
                --c-header-height: 116px;
                grid-template-rows: 40px 60px;
            }
        }
        .c-header__brand {
            width: auto;
            height: auto;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                max-width: 180px;
                width: 100%;
                height: 30px;

                @media (--screen-sm-min) {
                    max-width: 408px;
                    height: 55px;
                    width: 408px;
                }
            }
            @media (--screen-md-min) {
                margin: 0;
            }
            @media (--screen-xs-max) {
                width: 208px;
                height: 20px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .c-header__nav-btn {
            --width: 24px;
            --height: 24px;
            padding: 9px 10px 10px 9px;
            @media (--screen-md-min) {
                display: block;
                padding: 33px 27px 33px 27px;
            }
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                --height-slash: 24px;
                background: none;
                position: relative;
            }
            .hamburger-inner {
                margin: 0;
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
            &-2 {
                top: 5px;
                @media (--screen-md-min) {
                    padding: 9px 10px 10px 9px;
                    left: 13px;
                    top: 4px;
                }
            }
        }
        @media (--screen-sm-min) {
            --c-header-height: 90px;
        }
    }
}
&-special-vertical {
    background-color: var(--blanco);
    height: 36px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    > .o-container {
        min-height: 36px;
    }
    .c-header__brand {
        width: 168px;
        height: 16px;
        margin: 0 auto;
        padding: 0;
        z-index: 4;
    }
}
&-tv {
    .o-container {
        background-color: #030303;
        display: flex;
        flex-direction: row;
        min-height: 54px;

        @media (--screen-md-min) {
            padding-bottom: 31px;
            padding-top: 31px;
        }
    }
    .c-header {
        &__brand {
            a {
                color: #fff;
            }
        }
        &__nav-btn {
            .hamburger-inner {
                background-color: #fff;
                &:before,
                &:after {
                    background-color: #fff;
                }
            }
        }
        &__nav-btn-search {
            color: #fff;

            svg {
                color: #fff;
            }
        }

        &__date {
            color: #fff;
        }
    }
    .open-navigation .c-header__nav-btn .hamburger-inner {
        background-color: #fff;
    }
}
&-registro {
    .c-header {
        &__nav-btn {
            padding: 23px 15px;
            @media (--screen-md-min) {
                padding: 15px 15px;
            }
        }
    }
    & ~ nav.o-section-head {
        @media (--screen-sm-max) {
            top: 67px;
        }
    }
}

}

.open-navigation {
.c-header {
    & > .o-container {
        @media (--screen-sm-max) {
            flex-direction: column;
            justify-content: center;
            width: 100vw;
            z-index: 2;
        }
    }
    &__nav {
        &-btn {
            z-index: 3;
            .hamburger-inner {
                transform: rotate(45deg);
                background-color: var(--c-header-burger-color);
            }
            .hamburger-inner::before {
                top: 0;
                opacity: 0;
            }
            .hamburger-inner::after {
                bottom: 0;
                transform: rotate(-90deg);
            }
        }
    }
    &-special {
        .c-header__nav-btn {
            .hamburger-inner {
                background-color: var(--c-header-special-burger);
            }
        }
    }
    &-vertical {
        .c-header {
            &__nav-btn {
                right: 0;
                left: auto;
                .hamburger-inner,
                .hamburger-inner::before,
                .hamburger-inner::after {
                    background: none;
                }
                .hamburger-inner {
                    transform: rotate(0deg);
                    &:before {
                        opacity: 1;
                    }
                }
                &-search {
                    left: 23px;
                    svg {
                        margin-right: 0;
                    }
                }
                @media (--screen-md-min) {
                    right: auto;
                    left: 0;
                }
                &-2 {
                    left: 0;
                    right: initial;
                    @media (--screen-md-min) {
                        left: 13px;
                    }
                }
            }
        }
        @media (--screen-xs-max) {
            /*background: #dddddd;*/
            border-top: 1px solid var(--c-header-border-color);
        }
    }
    &-registro {
        & ~ .content {
            @media (--screen-sm-max) {
                margin-top: 44px;
            }
        }
        &.c-header {
            @media (--screen-xxs-max) {
                z-index: 4;
            }
        }
        .c-header {
            &__nav-btn {
                .hamburger-inner,
                .hamburger-inner::before,
                .hamburger-inner::after {
                    background: none;
                }
                .hamburger-inner {
                    transform: rotate(0deg);
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
        & ~ aside .c-nav__menu {
            @media (--screen-xxs-max) {
                height: calc(100vh - 106px);
                top: 107px;
            }
            @media (--screen-xs-min) {
                top: 0;
                height: 100vh;
            }
        }
        & ~ nav.o-section-head {
            @media (--screen-sm-max) {
                top: 4px;
            }
        }
    }

}
@media (--screen-sm-max) {
    .c-header {
        z-index: 2;
    }
    .c-aside {
        position: fixed;
        top: 0;
        height: 44px;
        width: 100%;
        z-index: 4;
    }
    .o-section-head {
        padding: 63px 0 0 0;
        position: fixed;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 100vh;
    }
}
}

.sticky-mini {
.c-header-vertical {
    .o-container {
        &.header-mini {
            .c-header__brand {
                &-mini {
                    @media (--screen-sm-max) {
                        display: none;
                    }
                }
                img {
                    @media (--screen-sm-max) {
                        height: 30px;
                    }
                }
            }
            .c-header__nav-btn {
                @media (--screen-sm-max) {
                    padding: 10px 15px 11px;
                }
            }
            @media (--screen-sm-max) {
                grid-template-rows: 45px 0;
                --c-header-height: 45px;
            }
        }
    }
}
}
