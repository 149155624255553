.c-line{
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--bordes);
  max-width: 980px;
  margin: 0 auto 20px;
}
.u-border {
  &-xs {
    @media (--screen-xs-max) {
      &:after {
        content:"";
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--bordes);
        max-width: 980px;
        margin: 0 auto 16px;
      }
      &.u-margin-after-xs-tp {
        &:after {
          margin-top: 16px;
        }
      }
      &.c-article {
        &:after {
          margin:16px auto;
        }
      }
      &.c-article-opinion, &.c-article-lateral-case-2, &.c-poll {
        &:after {
          margin-top:16px;
          margin-bottom:0;
        }
      }
      &.c-article-opinion, &.c-article-perfil, &.c-article-module-branded, &.c-poll, &.c-article-lateral.c-article-bg {
        &:after {
          position:absolute;
          left:0;
        }
      }
      &.c-article-opinion, &.c-poll {
        &:after {
          bottom:-16px;
        }
      }
      &.c-article-perfil, &.c-article-lateral.c-article-bg {
        &:after {
          bottom: -32px;
        }
      }
      &.c-article-double {
        &:after {
          margin-bottom:16px;
        }
      }
    }
  }
  @media (--screen-sm-min) {
    &:after {
      content:"";
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--bordes);
      max-width: 980px;
      margin: 0 auto 20px;
    }
    &.c-article{
        &:after{
           margin-top: 20px;
        }
    }
  }
  @media (--screen-sm-max) {
    &-bt {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        &:after {
            content:"";
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--bordes);
            max-width: 980px;
            margin: auto auto 0;
        }
      .c-article__txt {
        margin-bottom: 16px;
      }
    }
  }
}

.o-container-board {
    &:not(.c-article-opening-reverse){
        .u-border {
           &-xs {
               @media (--screen-xs-max) {
                   &.bg-modulo {
                       position: relative;
                       margin-bottom: 32px;
                       &:after {
                           position:absolute;
                           left:0;
                           bottom: -32px;
                       }
                   }
               }
           }
        }
    }
    &.c-article-opening-reverse{
        .u-border {
            &-xs {
                @media (--screen-xs-max) {
                    &.bg-modulo {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

