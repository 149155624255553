.o-section {
    &-head {
        padding: 0;
        position: sticky;
        top: 54px;
        z-index: 4;
        & > .o-container {
            position: relative;
            padding: 0;
            .c-nav__group {
                justify-content: center;
                @media (--screen-md-min) {
                    padding: 0 20px;
                }
                &.c-nav__group__horizontal {
                    @media (--screen-md-min) {
                        padding: 8px 0 0;
                    }
                }
            }
        }
        @media (--screen-md-min) {
            top: 0;
        }
    }
    &-search {
        background-color: var(--grey-02);
        display: none;
        position: sticky;
        top: 54px;
        z-index: 4;
        .c-search {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            margin-bottom: 3px;
            height: 60px;
            @media (--screen-sm-min) {
                height: 45px;
            }
        }
        & > .o-container {
            padding: 0;
        }
        @media (--screen-sm-only) {
            .c-search {
                height: 60px;
            }
        }
        @media (--screen-md-min) {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
    &-border {
        & > .o-container {
            border-top: 4px solid var(--fondo);
        }
    }
    &-books {
        .c-title {
            .c-title__name {
                padding-bottom: 0;
            }
        }
        .c-article__thumb {
            &:not(.c-article__thumb-rounded) {
                img {
                    border: 1px solid var(--bordes);
                    height: auto;
                    object-fit: initial;
                }
            }
        }
        .c-article {
            &:not(.c-article-lateral) {
                .c-article__txt {
                    padding-bottom: 20px;
                }
            }
            &__epigraph {
                margin-bottom: 0;
            }
            &__subtitle {
                margin-top: 8px;
            }
            @media (--screen-sm-min) {
                margin-bottom: 0 !important;
                &:last-child {
                    margin-bottom: 16px;
                }
            }
        }
        .c-article-lateral-sm-only {
            @media (min-width: 768px) and (max-width: 991px) {
                figure {
                    margin: 0 auto;
                }
            }
        }
        @media (--screen-xs-max) {
            .u-border {
                &-xs {
                    &.c-article-lateral {
                        &:after {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    &-obituary {
        .c-title {
            .c-title__name {
                color: var(--enlace-oscuro);
                a {
                    color: var(--enlace-oscuro);
                }
            }
        }
    }
    &-ratingnew {
        .o-container {
            &::after {
                display: none;
            }
            .u-bg-fondo {
                padding: 10px 0 10px 10px;
                position: relative;
            }
        }
        .c-title {
            &-module {
                text-transform: uppercase;
                border-bottom: 0;
                margin-bottom: 14px;
                position: initial;
                @media (--screen-sm-only) {
                    width: 170px;
                }
                .c-title__name {
                    border-bottom: 4px solid var(--acento);
                    position: initial;
                    &::after {
                        display: none;
                    }
                    a {
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
    &-add {
        & > .o-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 0 auto;
            padding-left: 0;
            padding-right: 0;
            &.u-show-xs-only {
                @media (--screen-sm-min) {
                    display: none;
                }
            }
        }
    }
    &.u-bg-fondo {
        margin: 0 -16px;
        padding: 16px;
        @media (--screen-md-min) {
            margin: 0 -20px;
            padding: 20px;
        }
        .c-title {
            padding: 0;
            margin-bottom: 16px;
            @media (--screen-md-min) {
                margin-bottom: 20px;
            }
            border-bottom: 4px solid var(--fondo-intermedio);
            &__name {
                font-family: var(--primary-font);
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--texto);
                border-bottom: 4px solid var(--acento);
                padding-bottom: 4px;
                display: block;
                width: max-content;
                margin-bottom: -4px;
                padding-right: 4px;
            }
        }
    }
    &-opinion {
        .c-article-opinion {
            padding: 0;
            &.c-article-author-image {
                grid-template-columns: initial;
            }
        }
        .u-border,
        .u-border-xs {
            &:after {
                margin-bottom: 0;
                margin-top: 0;
                background-color: var(--fondo-intermedio);
            }
        }
        .u-grid {
            @media (max-width: 767px) {
                &-xs {
                    grid-row-gap: 16px;
                }
            }
        }
        .o-container {
            /*padding-bottom:16px;
      @media (--screen-md-min) {
        padding-bottom:20px;
      }*/
        }
    }
    /*&-bg-fondo {
    &:before {
      content:"";
      display: block;
      width:100%;
      max-width:980px;
      margin:0 auto;
      height: 16px;
      background-color: var(--blanco);
    }
    @media (--screen-md-min) {
      &:before {
        height:20px;
      }
    }
  }*/
    &-newopening .c-article-opinion.c-article-author-image {
        grid-template-columns: 1fr 60px;
        .c-article__thumb img {
            width: 60px;
            height: 60px;
        }
    }
    &-lomas {
        .c-lomas {
            &__title {
                border-bottom: 1px solid var(--bordes);
                @media (--screen-sm-min) {
                    margin-bottom: 10px;
                }
            }
            &__list {
                @media (--screen-sm-min) {
                    column-count: 2;
                    column-gap: 20px;
                    &__item {
                        padding: 0 0 10px;
                        &:not(:last-child) {
                            border-bottom: none;
                        }

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            grid-column-start: 1;
                        }

                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            grid-column-start: 2;
                        }
                    }
                }
            }
        }
        .listing_lomas_visto {
            padding-bottom: 20px;
        }
    }
    &-ranking {
        .o-container {
            &::after {
                display: none;
            }
        }
        .c-subtitle {
            font-family: var(--primary-font);
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
            color: var(--texto-secundario);
            margin-top: 3px;
        }
        .u-grid-columns-3 {
            @media (--screen-xs-max) {
                height: inherit;
                z-index: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                overflow: auto;
                grid-column-gap: 20px;
            }
        }
        .c-ranking {
            display: flex;
            &__thumb {
                margin-left: 20px;
            }
            &__txt {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                @media (--screen-xs-max) {
                    width: 170px;
                }
            }
            &__number {
                font-family: var(--third-font);
                font-size: 21px;
                line-height: 23px;
                color: var(--acento);
                font-weight: 600;
                @media (--screen-sm-min) {
                    font-size: 25px;
                    line-height: 27px;
                }
            }
            &__title {
                font-family: var(--third-font);
                font-size: 19px;
                line-height: 21px;
                color: var(--enlace-oscuro);
                font-weight: 600;
                margin-top: auto;
                a {
                    color: var(--enlace-oscuro);
                }
                @media (--screen-sm-min) {
                    font-size: 21px;
                    line-height: 23px;
                }
            }
            &__description {
                font-family: var(--primary-font);
                font-size: 14px;
                line-height: 17px;
                color: var(--texto-suave);
            }
            @media (--screen-xs-max) {
                width: 253px;
            }
        }
    }
    &-vertical {
        .c-nav__group {
            text-align: center;
            display: block;
            border-top: 0;
            .c-nav__links {
                height: 30px;
                &__item {
                    font-size: 13px;
                }
                .current {
                    &:after {
                        left: 10px;
                        bottom: -2px;
                    }
                }
            }
            @media (--screen-sm-max) {
                border: 0;
            }
        }
    }
}
.open {
    &-search {
        .o-section-search {
            display: block;
        }
    }
    &-navigation {
        .o-section {
            &-branded_special {
                .c-nav__menu {
                    top: 0;
                    &__close {
                        display: none;
                    }
                }
                &.o-section-head {
                    top: 44px;
                    @media (--screen-sm-max) {
                        padding-top: 1px;
                    }
                }
            }
            &-vertical.o-section-head {
                padding-top: 1px;
                z-index: 4;
                .o-container {
                    .c-nav__menu {
                        background: var(--blanco);
                        width: 100%;
                        top: 0;
                        position: fixed;
                        height: 100vh;
                        z-index: 9999;
                        .o-container {
                            width: 100%;
                            background: var(--c-nav-bg);
                            @media (--screen-sm-min) {
                                width: 360px;
                            }
                        }
                        &__close {
                            display: block;
                            cursor: pointer;
                            width: 38px;
                            height: 36px;
                            margin: 0 0 0 auto;
                            padding: 6px;
                        }
                        .c-search {
                            margin-bottom: 0;
                            padding: 0 19px 17px;
                            p {
                                display: inline;
                                font-size: 20px;
                                font-weight: 600;
                                color: var(--c-nav-hamburger-search-color);
                                font-family: var(--primary-font);
                                margin-bottom: 11px;
                            }
                            div {
                                display: inline;
                                a {
                                    display: inline;
                                    img {
                                        display: inline;
                                        width: 160px;
                                    }
                                }
                            }
                            &__form {
                                margin-top: 10px;
                                border: solid 1px var(--c-search-border);
                                background-color: var(--blanco);
                                border-radius: 5px;
                                input[type="text"] {
                                    border: 0;
                                    height: 38px;
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: var(--c-nav-hamburger-search-color);
                                    font-family: var(--primary-font);
                                    width: calc(100% - 32px);
                                }
                                input::placeholder {
                                    color: var(--c-nav-hamburger-search-color);
                                }
                                button {
                                    right: 0;
                                    left: auto;
                                    color: var(--enlace-oscuro);
                                    top: 7px;
                                    svg {
                                        margin-right: 7px;
                                    }
                                }
                            }
                        }
                        > .o-container {
                            > div {
                                height: 100vh;
                                height: calc(100vh - 36px);
                                background: var(--blanco);
                            }
                        }
                        &__link {
                            background: white;
                            padding: 35px 27px 0 27px;
                            border-top: 1px solid var(--c-search-border);
                            > li {
                                margin-bottom: 7px;
                                padding-bottom: 2px;
                                a {
                                    font-size: 14px;
                                    padding: 0;
                                    display: inline-block;
                                    font-weight: 600;
                                    position: relative;
                                    color: #37474f;
                                }
                                ul {
                                    padding-bottom: 0;
                                    background: none;
                                    > li {
                                        margin-left: 17px;
                                        line-height: 14px;
                                        a {
                                            font-size: 12px;
                                            padding: 4px 0;
                                            font-weight: 400;
                                        }
                                        &:before {
                                            display: none;
                                        }
                                        &.current {
                                            position: relative;
                                            > a:after {
                                                content: "";
                                                height: 3px;
                                                width: 100%;
                                                position: absolute;
                                                bottom: 0;
                                                display: block;
                                                background-color: var(
                                                    --c-nav-current-color
                                                );
                                            }
                                        }
                                    }
                                }
                                &.current {
                                    position: relative;
                                    padding-bottom: 8px;
                                    div > a:after {
                                        content: "";
                                        height: 3px;
                                        width: 100%;
                                        position: absolute;
                                        bottom: -3px;
                                        display: block;
                                        background-color: var(
                                            --c-nav-current-color
                                        );
                                    }
                                }
                            }
                        }
                        @media (--screen-sm-min) {
                            width: 360px;
                            border-top: 1px solid var(--c-nav-border-color);
                            right: auto;
                            left: 0;
                        }
                    }
                }
                @media (--screen-sm-min) {
                    padding-top: 0;
                }
            }
        }
    }
}
.section-vertical,
.section-salud {
    main {
        > .c-title-section {
            display: none;
        }
    }
}

.section-debatetv {
    .o-section-head {
        .c-nav {
            &__group {
                background-color: #ffff;
            }
            &__links__item {
                color: #000;
            }
        }
    }
}

.login-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e3e3e3;

    .hidden {
        display: none;
    }
    .form-item {
        text-align: center;
        margin-bottom: 10px;
    }

    inpput[type="text"] {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }

    button[type="submit"] {
        background-color: #04AA6D; /* Green */
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;

        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;

        cursor: pointer;

        &.info {
            background-color: #007bff;
        }
    }

    .user-password-login-form {
        a {
            text-decoration: underline;
        }

        .alert {
            position: relative;
            padding: $alert-padding-y $alert-padding-x;
            margin-top: $alert-margin-top;
            border: $alert-border-width solid transparent;
            @include border-radius($alert-border-radius);
            margin-bottom: 10px;
        }

        .alert-success {
            @include alert-variant(#d4edda, #c3e6cb, #155724);
        }

        .alert-danger {
            @include alert-variant(#f8d7da, #f5c6cb, #721c24);
        }

        &.is-login {
            .sign-in-item {
                display: none;
            }
        }

        &.is-sign-in {
            .log-in-item {
                display: none;
            }
        }
    }
}
