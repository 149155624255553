.c-related {
    &__txt {
        width: 100%;
        max-width: calc(100% - 28px);
    }
    &__title {
        font-size: 16px;
        font-weight: 600;
        color: var(--texto);
        font-family: var(--primary-font);
        line-height: 1.3;
        margin: 0;
        a {
            color: var(--texto);
        }
    }
    &__pretitle {
        color: var(--c-article-pretitle-color);
        padding-right: 4px;
        display: none;
    }
    &-pretitle {
        .c-related__pretitle {
            display: inline;
        }
        .c-related__title {
            a {
                display: inline;
            }
        }
    }
    &-arrow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        z-index: 1;
        .c-related__txt {
            max-width: calc(100% - 10px);
        }
        .icon {
            color: var(--texto-secundario);
            display: block;
            max-width: 18px;
            height: 18px;
            width: 100%;
            margin-right: 5px;
            visibility: visible;
        }
    }
    .c-related__icon {
        display: none;
        margin-right: 5px;
        float: left;
    }
    .cont-related {
        width: 100%;
    }
    &-module-video,
    &-module-gallery,
    &-module-podcast,
    &-module-interactivo,
    &-module-encuesta {
        .c-related__icon {
            @extend %ext__mini-icon-media;
            .icon {
                visibility: hidden;
                color: var(--acento);
                max-width: 12px;
                height: 12px;
                margin-right: 0;
            }
        }
        .c-related__txt {
            display: inline;
        }
    }
    &-module-live {
        .c-related__icon {
            display: inline-flex;
            &:after {
                content: "Directo";
                padding-left: 13px;
                color: var(--acento);
                font-weight: 600;
            }
            &:before {
                content: "";
                display: block;
                width: 9px;
                height: 9px;
                background-color: var(--acento);
                border-radius: 50%;
                overflow: hidden;
                animation: bullet 1s infinite;
                animation-delay: 1s;
                animation-duration: 1s;
                position: absolute;
                margin-top: 5px;
            }
            svg {
                display: none;
            }
        }
        .c-related__txt {
            display: inline;
        }
    }
    &-module-video {
        .c-related__icon {
            display: flex;
            .icon_video {
                visibility: visible;
            }
        }
    }
    &-module-gallery {
        .c-related__icon {
            display: flex;
            .icon_gallery {
                visibility: visible;
            }
        }
    }
    &-module-podcast {
        .c-related__icon {
            display: flex;
            .icon_podcast {
                visibility: visible;
            }
        }
    }
    &-module-interactivo {
        .c-related__icon {
            display: flex;
            .icon_interactivo {
                visibility: visible;
            }
        }
    }
    &-module-encuesta {
        .c-related__icon {
            display: flex;
            .icon_encuesta {
                visibility: visible;
            }
        }
    }
}
