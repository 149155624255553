*{
  box-sizing: border-box;
  margin:0;
  padding: 0;
}
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;

  font-size: 16px;
  line-height: 1.2;
  color: var(--texto);
  background-color: var(--fondo-pagina);
  font-family:  var(--primary-font);

}
::-moz-selection {
  color: var(--blanco);
  background: var(--texto);
}
::selection {
  color: var(--blanco);
  background: var(--texto);
}
h1,h2,h3{

}

::marker {
  content: '';
}

input,
button,
select,
textarea {
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  border:none;
  outline: none;
}
a{
  text-decoration: none;
  display: block;
  color:var(--texto);
}
/*img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  line-height: 0;
  display: block;
}*/
img{
  display: block;
  line-height: 0;

}
figure {
  margin: 0;
}

ul, ol{ list-style: none; margin:0; padding: 0;}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.img-tracking{
    display: none;
    width:1px;
}

body.app #didomi-notice {
  display: none;
}

body.app #didomi-host {
  display: none;
}
