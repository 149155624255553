.c-add{
  /* add 300x300 / 300x600 */
  &-300{
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
  /* wallpaper */
  &-wall{
    display: none;
    @media (--screen-sm-min) {
      display: block;
      &-top {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        top:0;
        text-align: center;
        img {
          width: 100%;
          display: block;
          height: 250px;
        }
        a {
          line-height: 0;
        }
      }
      &-container {
        position: fixed;
        top: 0;
        height: 100vh;
        z-index: 4;
        display: flex;
        flex-direction: column;
        &-left {
          margin-left: 0;
          text-align: right;
          right: calc(50% + 490px);
          width: auto;
        }
        &-right {
          right: 0;
          margin-right: 0;
          text-align: left;
          width: auto;
          left: calc(50% + 490px);
        }
      }
      &-left, &-right {
        position: sticky;
        top: 0;
        overflow: hidden;
        a {
          line-height: 0;
        }
      }
    }
    &-container {
      &-left {
        @media (--screen-md-min) {
          right: calc(50% + 500px);
        }
      }
      &-right {
        @media (--screen-sm-min){
            right: inherit;
        }
        @media (--screen-md-min) {
          left: calc(50% + 500px);
        }
      }
    }
    &-top {
      img {
        @media (--screen-sm-min){
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /* bottom */
  &-bottom{
        position: sticky;
        bottom:0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        z-index: 2;
        background-color: var(--blanco);
        outline: solid var(--bordes) 1px;
        @media (--screen-md-min) {
            display: none;
        }
    }
  &-990-gallery{
    margin-bottom:20px;
    display:grid;
    justify-content:center;
  }
}
.top-ads {
    justify-content: center;
    align-items: center;
    display: flex;
  @media (--screen-xs-max) {
    background-color: var(--blanco);
    padding: 2px;
  }
}
.fotter-ads {
    height: 250px;
    justify-content: center;
    align-items: center;
    display: flex;
  @media (--screen-xs-max) {
    background-color: var(--blanco);
    padding: 16px;
    height:auto;
  }
}
.close-button-container{
    position: absolute;
    bottom: 100%;
    right: 0;
    cursor: pointer;
    margin-bottom: -25px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    font-family: Arial,sans-serif;
    font-size: 14px;
    z-index: 1001;
    color: #000000;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.close-button-img{
    z-index: 1001;
    width: 50px;
    height: 50px;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-user-select: none;
    max-width: none;
}
.c-detail-live .c-add-300 {order: 1;padding-bottom: 15px;}
#lateralder_desktop_1 img {width: inherit;}
body{
    &.header-vertical{
        .c-add-wall-container {
            top: 36px;
        }
        &.sticky{
            .c-add-wall-container {
                top: 0;
            }
        }
    }
}
