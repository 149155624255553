/*
  Breakpoints
*/

/* Min */
@custom-media --screen-xs-min (min-width: 480px);
@custom-media --screen-sm-min (min-width: 768px);
@custom-media --screen-md-min (min-width: 992px);
@custom-media --screen-lg-min (min-width: 1140px);

/* Max */
@custom-media --screen-xsxs-max (max-width: 376px);
@custom-media --screen-xxs-max (max-width: 479px);
@custom-media --screen-xs-max (max-width: 767px);
@custom-media --screen-sm-max (max-width: 991px);
@custom-media --screen-md-max (max-width: 1139px);

/* Only */
@custom-media --screen-xs-only (max-width: 767px);
@custom-media --screen-xsxs-only (min-width: 480px) and (max-width: 767px);
@custom-media --screen-sm-only (min-width: 768px) and (max-width: 991px);
@custom-media --screen-md-only (min-width: 992px) and (max-width: 1139px);
@custom-media --screen-lg-only (min-width: 1140px);
