.u-nomargin {
  margin:0;
  &-left{
    margin-left: 0;
  }
  &-right{
    margin-right: 0;
  }
  &-bottom{
    margin-bottom: 0 !important;
  }
  &-top{
    margin-top: 0;
  }
  &-xs {
    &-bottom {
      @media (--screen-xs-max) {
        margin-bottom: 0 !important;
      }
    }
  }
}
.u-margin {
  @media (--screen-xs-max) {
    &-left-xs {
      margin-left: 16px;
    }
    &-right-xs {
      margin-right: 16px;
    }
    &-bt-xs {
      margin-bottom: 16px;
    }
  }
  @media (--screen-sm-max) {
    &-left-sm {
      margin-left: 16px;
    }
    &-right-sm {
      margin-right: 16px;
    }
    &-bt-sm {
      margin-bottom: 16px !important;
    }
    &-tp-sm {
      margin-top: 20px;
      &-2 {
        margin-top:16px;
      }
    }
  }
  @media (--screen-sm-min) {
    &-bt {
      margin-bottom: 20px !important;
    }
    &-left {
      margin-left: 16px;
    }
    &-right {
      margin-right: 16px;
    }
    &-tp {
      margin-top: 20px;
    }
  }
}
