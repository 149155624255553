.o-tab {
  width: 100%;
  input[type="radio"] {
    display: none;
  }
  &__content{
    & > div {
      visibility: hidden;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity .3s ease-out;
    }

  }

  #option-1:checked ~ .o-tab__nav label[for="option-1"],
  #option-2:checked ~ .o-tab__nav label[for="option-2"],
  #option-3:checked ~ .o-tab__nav label[for="option-3"]{
    &:after{
      content: '';
      height: 4px;
      display: block;
      width: 100%;
      background-color: var(--acento);
      position: absolute;
      bottom: 0;
      left:0;
      transition:  background-color .3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
  #option-1:checked ~ .o-tab__content > div:nth-child(1),
  #option-2:checked ~ .o-tab__content > div:nth-child(2),
  #option-3:checked ~ .o-tab__content > div:nth-child(3) {
    /*display: block;*/
    visibility: visible;
    height: auto;
    opacity: 1;
  }
}