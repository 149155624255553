.o-container {
  width: 100%;
  max-width:980px;
  margin:0 auto;
  background-color: var(--blanco);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  &.u-bg-fondo {
    padding-bottom:16px;
    padding-top: 16px;
    &.o-container-board {
        &::after {
            display:none;
        }
    }
  }
  @media (--screen-sm-min) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    &.u-bg-fondo {
      padding-bottom:20px;
      padding-top: 20px;
    }
  }
  @media (--screen-md-min) {
    max-width: 1000px;
    padding-left: 30px;
    padding-right: 30px;
  }
  &-board {
    &:after {
      content:"";
      display: block;
      width:100%;
      height: 1px;
      background-color: var(--c-line-board-color);
      margin-top:16px;
    }
    &.no-after {
      @media (--screen-sm-max) {
        &:after {
          display: none;
        }
      }
    }
    @media (--screen-sm-min) {
      &:after {
        margin-top:20px;
      }
    }
  }
}
.content{
  z-index: 1;
  position: relative;
  .o-section{
    &.o-section-head + .o-section{
      & > .o-container{
          padding-top: 16px;
          @media (--screen-sm-min) {
            padding-top: 20px;
          }
      }
    }
  }

    &.hidden {
        display: none;
    }
}
