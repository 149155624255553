.c-detail__closed-bluebox {
    position: absolute;
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
    padding: 16px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0/30%);
    border: 1px solid #1e88e5;
    background-color: #f4f9fe;
    z-index: 9;
    max-width: 342px
}

.c-detail__closed-bluebox .c-form__btn {
    display: inline-block;
    padding: 8px 16px 7px;
    width: auto;
}

.c-detail__closed-bluebox .c-icon-close {
    position: absolute;
    right: -7px;
    top: 7px;
    background-repeat: no-repeat;
}

.c-detail__closed-bluebox.active {
    display: flex;
}

@media (min-width: 992px) {
    .c-detail__closed-bluebox {
        grid-gap:10px;
        gap: 10px;
        padding: 20px
    }
}

.c-detail__closed-bluebox_info-icon {
    flex-grow: 0
}

.c-detail__closed-bluebox_text-container {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px
}

.c-detail__closed-bluebox_title {
    font-weight: 600;
    color: #1e88e5
}

.c-detail__closed-bluebox_title,.c-detail__closed-bluebox_txt {
    font-family: Flama,sans-serif;
    font-size: 14px;
    line-height: 1.2
}

.c-detail__closed-bluebox_txt {
    color: #333131
}

.c-detail__trinity-player {
    position: relative;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
}

.trigger-closed-box{
    width: 100%;
}

.c-detail__trinity-player .c-detail__closed-bluebox {
    top: -30px;
    transform: translateX(5%);
}

@media (min-width: 992px) {
    .c-detail__trinity-player {
        width:100% !important;
    }
}

@media (min-width: 992px) {
    .c-detail__trinity-player .c-detail__closed-bluebox {
        transform:translateX(40%);
    }
}


