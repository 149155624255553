.c-user {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1042px;
    margin: 0 auto;

    &-auth-navigation {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        border-bottom: 1px solid #d1d1d1;
        &__list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
        &__item {
            color: var(--grey-05);
            text-transform: uppercase;
            font-weight: 500;
            font-size: 16px;
            padding: 5px;
            &.current {
                @media (--screen-md-min) {
                    color: var(--color-01);
                    font-weight: 700;
                }
            }
            @media (--screen-sm-max) {
                display: none;
            }
            @media (--screen-md-min) {
                & + .c-user-navigation__item {
                    border-left: 1px solid var(--black);
                    margin-left: 20px;
                    padding-left: 20px;
                }
            }

            .active {
                background-color: red;
            }
        }

        &__item.active {
            font-weight: bold;
            text-decoration: underline;
        }

        &__btn {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 16px;
            color: var(--color-01);
            font-weight: 700;
            font-family: var(--profile-font);
            &:after {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                clip-path: polygon(0 15%, 0 85%, 50% 50%);
                background-color: var(--color-01);
                margin-left: 8px;
                transition: all 0.3s;
            }
            @media (--screen-md-min) {
                display: none;
            }
        }
        @media (--screen-sm-max) {
            &.open {
                flex-direction: column;
                z-index: 2;
                .c-user-navigation {
                    &__list {
                        background-color: var(--white);
                        padding: 10px;
                        border: 1px solid #707070;
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                        flex-direction: column;
                        gap: 10px;
                        position: absolute;
                        top: 35px;
                        left: 0;
                        width: 100%;
                    }
                    &__item {
                        display: block;
                    }
                    &__btn {
                        &:after {
                            transform: rotate(90deg) translate(5px, 5px);
                        }
                    }
                }
            }
        }

        @media (--screen-md-min) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
        @media (--screen-lg-min) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    &-navigation {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        border-bottom: 1px solid #d1d1d1;
        &__list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
        &__item {
            color: var(--grey-05);
            text-transform: uppercase;
            font-weight: 500;
            font-size: 16px;
            &.current {
                @media (--screen-md-min) {
                    color: var(--color-01);
                    font-weight: 700;
                }
            }
            @media (--screen-sm-max) {
                display: none;
            }
            @media (--screen-md-min) {
                & + .c-user-navigation__item {
                    border-left: 1px solid var(--black);
                    margin-left: 20px;
                    padding-left: 20px;
                }
            }
        }
        &__btn {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 16px;
            color: var(--color-01);
            font-weight: 700;
            font-family: var(--profile-font);
            &:after {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                clip-path: polygon(0 15%, 0 85%, 50% 50%);
                background-color: var(--color-01);
                margin-left: 8px;
                transition: all 0.3s;
            }
            @media (--screen-md-min) {
                display: none;
            }
        }
        @media (--screen-sm-max) {
            &.open {
                flex-direction: column;
                z-index: 2;
                .c-user-navigation {
                    &__list {
                        background-color: var(--white);
                        padding: 10px;
                        border: 1px solid #707070;
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                        flex-direction: column;
                        gap: 10px;
                        position: absolute;
                        top: 35px;
                        left: 0;
                        width: 100%;
                    }
                    &__item {
                        display: block;
                    }
                    &__btn {
                        &:after {
                            transform: rotate(90deg) translate(5px, 5px);
                        }
                    }
                }
            }
        }

        @media (--screen-md-min) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
        @media (--screen-lg-min) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    &-body {
        padding: 10px 0 40px;
        /*&__title {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 20px;
            padding-top: 20px;
        }
        &__subtitle {
            font-family: var(--profile-font);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: -0.16px;
            color: #717171;
            margin-bottom: 20px;
            &:not(.u-text-center) {
                text-align: left;
            }
            &.max-558 {
                max-width: 558px;
                margin: 20px auto;
            }
        }
        &__link {
            display: inline-block;
            color: var(--color-02);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: -0.16px;
        }
        &__category {
            text-transform: uppercase;
            color: var(--black);
            font-weight: bold;
            font-size: 14px;
        }
        &__icons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #717171;
            margin-bottom: 20px;
            gap: 20px;
            .link {
                color: #717171;
                cursor: pointer;
            }
        }*/

        .c-form__text,
        .c-form__select {
            max-width: inherit;
        }
        &:not(.c-user-body-perfil, .c-user-body-conf) {
            .u-grid {
                grid-template-columns: 1fr 1fr;
                @media (--screen-sm-min) {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
                @media (--screen-md-min) {
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                }
            }
        }
        /* Modif.*/

        &-conf {
            .c-user-body {
            }
            .c-form__switch__text {
                p {
                    color: #717171;
                    letter-spacing: -0.16px;
                    font-weight: 600;
                }
            }
            .u-grid {
                gap: 40px 20px;
                margin-bottom: 20px;
                grid-template-columns: 1fr;
                @media (--screen-sm-min) {
                    grid-template-columns: 1fr 1fr;
                }
                @media (--screen-md-min) {
                    gap: 40px 40px;
                    grid-template-columns: 1fr 1fr;
                }
                @media (--screen-lg-min) {
                    gap: 40px 230px;
                }
            }
        }
        &-descargas {
        }
        &-favoritos {
        }
        &-perfil {
        }
        &-suscripciones {
        }
    }
    &-card {
        position: relative;
        z-index: 1;
        &__txt {
            padding-top: 10px;
            display: grid;
            grid-template-columns: 1fr 14px;
            grid-gap: 14px;
            min-height: 60px;
            .icon {
                color: rgba(46, 46, 46, 0.6);
                z-index: 2;
                svg {
                    width: 28px;
                    height: 28px;

                    &.small {
                        width: 14px;
                        height: 14px;
                    }
                }

                &.subscription-icon {
                    cursor: pointer;
                }

                &.favorites-icon {
                    cursor: pointer;
                }
            }
        }
        &__title {
            color: var(--black);
            font-size: 12px;
            line-height: 1.33;
            font-weight: 600;
            a {
                color: var(--black);
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
        &__thumb {
            position: relative;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            .icon {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: rgba(0, 0, 0, 0.7);
                width: 38px;
                height: 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                svg {
                    width: 11px;
                    height: 13px;
                }
            }
        }

        /* modif.*/
        &-active {
            .c-user-card__txt {
                .icon {
                    color: var(--color-01);
                }
            }
        }
        &-author {
            padding: 0 33px;

            .c-user-card {
                &__thumb {
                    border-radius: 50%;
                    overflow: hidden;
                }
                &__txt {
                    display: flex;
                    grid-gap: 0;
                    flex-direction: column;
                    align-items: center;
                    .icon {
                        margin-top: 4px;
                    }
                }
                &__title {
                    text-align: center;
                    a {
                        font-size: 14px;
                        font-weight: bold;
                        color: #a6255e;
                    }
                }

                &__socialicon {
                    display: flex;
                    .icon-twitter {
                        color: #000000;
                        margin-right: 3px;
                        font-size: 10px;
                    }
                    a {
                        font-size: 10px;
                        font-weight: 600;
                        color: #000000;
                    }
                }
            }
        }
        &-audio {
            .c-user-card {
                &__thumb {
                    border-radius: 12px;
                    overflow: hidden;
                }
            }
        }
    }

    &-button {
        background-color: #a6245e;
        color: #fff;
        text-align: center;
        width: 167px;
        padding: 12px 0;
        border-radius: 30px;
        margin: 0 5px 20px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
    }

    &-buttons-center {
        text-align: center;
    }

    &-button-link {
        font-size: 16px;
        font-weight: 600;
        color: #0070a7;
        cursor: pointer;
    }

    .alert {
        position: relative;
        padding: $alert-padding-y $alert-padding-x;
        margin-top: $alert-margin-top;
        margin-bottom: $alert-margin-bottom;
        border: $alert-border-width solid transparent;
        @include border-radius($alert-border-radius);
    }

    .alert-success {
        @include alert-variant(#d4edda, #c3e6cb, #155724);
    }

    .alert-danger {
        @include alert-variant(#f8d7da, #f5c6cb, #721c24);
    }

    .hidden {
        display: none;
    }

    &__form {
        .alert {
            position: relative;
            padding: $alert-padding-y $alert-padding-x;
            margin-top: $alert-margin-top;
            margin-bottom: $alert-margin-bottom;
            border: $alert-border-width solid transparent;
            @include border-radius($alert-border-radius);
        }

        .alert-success {
            @include alert-variant(#d4edda, #c3e6cb, #155724);
        }

        .alert-danger {
            @include alert-variant(#f8d7da, #f5c6cb, #721c24);
        }

        .hidden {
            display: none;
        }
    }
}



.c-user-card__title a:before {
    content: none !important;
}
