.c-opinion {
    position: relative;
    z-index: 1;
    &__thumb {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        width: max-content;
        img {
            object-fit: cover;
        }
        & + .c-opinion__txt {
            padding-left: 10px;
            max-width: calc(100% - 28px - 10px - 48px);
        }
        &.unknown-avatar + .c-opinion__txt {
            padding-left: 0;
            max-width: calc(100% - 28px);
        }
    }
    &__txt {
        width: 100%;
        max-width: calc(100% - 28px);
    }
    &__title {
        font-weight: 600;
        letter-spacing: -0.27px;
        font-size: 23px;
        color: var(--texto);
        font-family: var(--third-font);
        font-style: italic;
        margin: 0;
        a {
            color: var(--texto);
            &:not(.link-with-video) {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
        @media (--screen-md-min) {
        }
    }
    &__author {
        font-size: 14px;
        color: var(--texto);
        font-weight: 600;
        text-transform: uppercase;
        a {
            color: var(--texto);
        }
        span {
            color: var(--texto);
            display: block;
        }
        span[name="author-position"] {
            text-transform: none;
            color: var(--texto-secundario);
            font-weight: normal;
        }
    }
    &-arrow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        .icon {
            color: var(--texto-secundario);
            display: block;
            max-width: 18px;
            height: 18px;
            width: 100%;
            margin-right: 5px;
            visibility: visible;
        }
    }
}
.c-article {
    &-opinion {
        &.size-1 {
            .c-opinion {
                &__title {
                    @extend %ext__title-1;
                }
            }
        }
        &.size-2 {
            .c-opinion {
                &__title {
                    @extend %ext__title-2;
                }
            }
        }
        &.size-3 {
            .c-opinion {
                &__title {
                    @extend %ext__title-3;
                }
            }
        }
        &.size-4 {
            .c-opinion {
                &__title {
                    @extend %ext__title-4;
                }
            }
        }
        &.size-5 {
            .c-opinion {
                &__title {
                    @extend %ext__title-5;
                }
            }
        }
        &.size-6 {
            .c-opinion {
                &__title {
                    @extend %ext__title-6;
                }
            }
        }
    }
}
