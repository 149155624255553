.c-title {
    --color: var(--red-02);
    border-top: 1px solid var(--color);
    border-bottom: 1px dashed var(--color);

    &__section_name {
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.07;
        font-family: var(--third-font);
        color: var(--texto-secundario);
        padding: 8px 0;

        a {
            color: var(--texto-secundario);
        }

        @media (--screen-sm-min) {
            font-size: 35px;
            line-height: 1.06;
        }
    }

    .c-title__name {
        text-transform: uppercase;
        font-weight: var(--c-title-name-font-weight);
        font-size: var(--c-title-name-font-size);
        line-height: var(--c-title-name-line-height);
        font-family: var(--c-title-name-font);
        color: var(--c-title-name-color);
        padding: var(--c-title-name-padding);

        a {
            color: var(--c-title-name-color);
        }

        &.c-logosvg span {
            overflow: hidden;
            display: block;
            text-indent: -9999px;
            width: 0;
            height: 0;
        }
    }

    &.size-1 {
        .c-title__name {
            font-size: 32px;
        }
    }

    &.size-2 {
        .c-title__name {
            font-size: 24px;
        }
    }

    &.font-acento .c-title__name {
        color: var(--acento);

        a {
            color: var(--acento);
        }
    }

    &.font-dark .c-title__name {
        color: var(--enlace-oscuro);

        a {
            color: var(--enlace-oscuro);
        }
    }

    &-module {
        border-bottom: 4px solid var(--fondo-intermedio);
        position: relative;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 24px;
        box-sizing: border-box;
        .c-title__name {
            font-family: var(--primary-font);
            color: var(--texto);
            font-size: 14px;
            line-height: 24px;
            position: relative;
            display: inline;
            padding: 0 0 4px;
            a {
                color: var(--texto);
                display: inline;
            }
            &:after {
                content: " ";
                height: 4px;
                width: 100%;
                background: var(--acento);
                position: absolute;
                bottom:inherit;
                left: 0;
                display: block;
            }

        }

        @media (--screen-sm-min) {
            padding: 0;
        }
    }

    &-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        @media (--screen-sm-min) {
            padding: 10px 0;
            background-color: var(--fondo-pagina);
            max-width: 1000px;
            margin: 0 auto;
        }
        &__name {
            padding:0;
        }
        &-case-1 {
            justify-content: flex-start;
            margin: 0;
            background: none;

            .c-title__name {
                color: var(--texto);
                a{
                    color: var(--texto);
                }
                span {
                    color: var(--acento);
                    text-transform: none;
                }
            }

        }
        &-case-2 {
            justify-content: flex-start;
            padding: 0;
            margin: 0;
            background: none;
            .c-title__name {
                color: var(--acento);
                font-size: 23px;
                line-height: 25px;
                padding: 2px 0 19px;
                @media (--screen-sm-min) {
                    font-size: 24px;
                    line-height: 27px;
                    padding: 5px 0 23px;
                }
                a{
                    color: var(--acento);
                }
            }
        }
        &-case-3 {
            justify-content: flex-start;
            padding: 0;
            margin: 0;
            background: none;
            .c-title__name {
                color: var(--acento);
                font-size: 33px;
                line-height: 35px;
                padding: 0 0 5px;
                @media (--screen-sm-min) {
                    line-height: 38px;
                    padding: 1px 0 18px;
                }
                a{
                    color: var(--acento);
                }
            }
        }
        &-case-4 {
            justify-content: flex-start;
            padding: 0;
            margin: 0;
            background: none;
            .c-title__name {
                color: var(--acento);
                font-size: 21px;
                line-height: 23px;
                padding: 0;
                text-transform: uppercase;
                @media (--screen-sm-min) {
                    line-height: 24px;
                }
                a{
                    color: var(--acento);
                }
            }
        }
    }

    &-menu{
        .c-title__nav{
            display: none;
        }
    }

}
.c-article-sub-menu{
    .c-title{
        &-menu{
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
            .c-title{
                max-width: 300px;
                & > {
                    font-size: 0;
                }
                &__nav{
                    display: flex;
                    &.open{
                        .c-title__nav {
                            &__btn {
                                position: absolute;
                                right: 18px;
                                top: 18px;
                                z-index: 3;
                                .icon-closemenu {
                                    display: block;
                                    width: 21px;
                                    height: 21px;
                                }
                                .icon-menu, span {
                                    display: none;
                                }
                            }
                            &__list {
                                li{
                                    &:not(:last-child){
                                        @media (--screen-sm-max){
                                            padding: 0 0 8px;
                                            border-bottom: 1px solid var(--bordes);
                                        }
                                    }
                                }
                                @media (--screen-sm-max){
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    z-index: 2;
                                    min-width: 220px;
                                    display: flex;
                                    flex-grow: 0;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    gap: 8px;
                                    padding: 45px 16px 16px;
                                    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                                    background-color: var(--blanco);
                                }
                            }
                        }
                    }
                    &__btn{
                        display: flex;
                        cursor: pointer;
                        padding: 0;
                        border: 0;
                        svg{
                            color: var(--texto-suave);
                        }
                        span{
                            display: block;
                            font-family: 'Flama', sans-serif;
                            font-family: var(--primary-font);
                            font-size: 14px;
                            line-height: 16px;
                            color: #37474f;
                            color: var(--enlace-oscuro);
                            text-transform: uppercase;
                            padding: 8px 0;
                        }
                        .icon-closemenu{
                            display: none;
                        }
                        @media (--screen-md-min){
                            display: none;
                        }
                    }
                    &__list{
                        display: none;
                        li{
                            a{
                                font-family: var(--primary-font);
                                font-size: 14px;
                                line-height: 16px;
                                color: var(--enlace-oscuro);
                                text-transform: uppercase;
                                @media (--screen-md-min){
                                    margin-bottom: 10px;
                                }
                            }
                        }
                        @media (--screen-md-min){
                            display: flex;
                            grid-gap: 10px;
                        }
                    }
                    @media (--screen-sm-max){
                        margin-top: 5px;
                    }
                }
            }
            @media (--screen-md-min){
                border-bottom: 1px solid var(--bordes);
                margin-bottom: 20px;
                align-items: flex-end;
            }
        }
    }
}
