.c-button {
    &-primary {
        @extend %ext__primary-button;
    }
    &-primary-rounded {
        @extend %ext__primary-button-rounded;
    }
    &-secundary {
        @extend %ext__secundary-button;
    }
    &-secundary-rounded {
        @extend %ext__secundary-button-rounded;
    }
}
.btn-back {
    display: none;
    @media (--screen-xs-max) {
        cursor: pointer;
        position: sticky;
        left: 32px;
        bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        background: var(--enlace);
        color: var(--blanco);
    }
}
