.c-icon {
    &-close {
        background-image: url("../images/icon/iconos_aspa-black.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
        width: 44px;
        height: 44px;
        display: flex;
        cursor: pointer;
    }

    &-media {
        background-image: url("../images/icon/iconos_play.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 48px;
        border-radius: 75px;
        border: 4px solid var(--white);
        width: 64px;
        height: 64px;
        position: absolute;
        bottom: 20px;
        left: 19.9px;
        cursor: pointer;

        @media (--screen-md-min) {
            background-size: 104px;
            border: 6px solid var(--white);
            width: 120px;
            height: 120px;
            left: 30px;
            bottom: 29.8px;
        }
    }

    &-social {
        color: var(--enlace-oscuro);
        background-color: var(--fondo-gris);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &-following {
        padding: 6px;
        border-radius: 4px;
        border: solid 2px var(--enlace-oscuro);
        font-family: var(--primary-semibold-font);
        font-size: 11px;
        cursor: pointer;
        text-transform: uppercase;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;

        &:after {
            content: "";
            background-image: url("../images/icon/iconos_seguirAutorAlt.svg");
            background-size: 100%;
            background-position: center;
            width: 16px;
            height: 12px;
            display: block;
            margin-left: 5px;
        }

        &.active {
            border: solid 2px var(--auxiliares-acierto);
            color: var(--auxiliares-acierto);

            &:after {
                background-image: url("../images/icon/iconos_seguirAutorAlt-green.svg");
            }
        }
    }

    &-add {
        background-image: url("../images/icon/iconos_mas.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-color: var(--enlace);
        border-radius: 22px;
        width: 44px;
        height: 44px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 1px 4px 0 var(--negro-transparente);
        }

        &:focus {
            box-shadow: 0 0 6px 0 var(--enlace);
        }

        &:active {
            box-shadow: inset 0 2px 1px 0 var(--negro-transparente);
        }

        &:disabled {
            background-color: var(--bordes);
            pointer-events: none;
        }
    }
}
