.c-footer{
  z-index: 1;
  position: relative;
  font-family: var(--c-footer-font);

  .o-container {
    padding: 30px 20px 15px;
    color: var(--c-footer-color);
    background-color: var(--c-footer-bg);
    @media (--screen-md-min) {
      padding: 30px 30px 15px;
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media (--screen-md-min) {
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 25px;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @media (--screen-md-min) {
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 0;
    }
  }
  &__brand {
    width: 198px;
    height: 30px;
    transition: width .3s, height .3s;
    @media (--screen-md-min) {
        width: 216px;
        height: 36px;
    }
    a {
      color: var(--c-footer-color);
      display: block;
      line-height: 0;

      svg, img {
        width: 100%;
        height: 100%;
      }
    }

  }
  &__nav{
    &__menu{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;
      @media (--screen-md-min) {
         margin: 0;
      }
      a{
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: var(--c-footer-nav-color);
        padding: 0 10px;
        text-transform: uppercase;
        @media (--screen-md-min) {
            a {
              padding: 0 5px;
            }
        }
      }
      @media (--screen-md-min) {
        li{
          &:not(:last-child){
            margin-right: 5px;
          }
          &:first-child {
            a {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  &__share{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
    @media (--screen-md-min) {
      margin: 0;
    }
    a{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: var(--c-footer-share-color);
      background-color: var(--c-footer-share-bg-color);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    strong {
      &:not(:last-child){
        margin-right: 8px;
      }
    }
  }
  &__copyright{
    align-self: center;
    font-family: var(--c-footer-copy-font);
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.23px;
    text-align: center;
    color: var(--c-footer-copy-color);
  }
}
