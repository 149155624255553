/*
  Variables del Root
*/
:root {
    /* global vars */
  --gap-xs: 4px;
  --gap-s: 8px;
  --gap-m: 32px;
  --gap: 20px;
  --primary-font:  'Flama', sans-serif;
  --primary-bold-font: "Flama-Bold", sans-serif;
  --primary-semibold-font: "Flama-Semibold", sans-serif;
  --secondary-font:  'ChronicleTextG1-Roman', sans-serif;
  --third-font: 'ChronicleDispCondSemibold', Georgia;
  --profile-font: var(--primary-font);

    --bordes: rgba(102, 102, 102, 0.4);

  --negro: #000000;
  --black: #000000;
  --blanco: #ffffff;
  --white: #ffffff;
  --bordes: #dddddd;
  --acento: #c51126;
  --texto: #030303;
  --texto-suave: #333131;
  --texto-secundario: #90837d;
  --enlace: #4a90e2;
  --fondo: #e1dcd6;
  --fondo-pagina: #f3f3f3;
  --enlace-oscuro: #37474f;
  --fondo-intermedio: #91877e;
  --fondo-oscuro: #2e2216;
  --fondo-gris: #f3f3f3;
  --fondo-gris2: #efefef;
  --fondo-beige: #e5e4d3;
  --fondo-verde: #2d492f;
  --fondo-crema: #ffe9d4;
  --fondo-rosa: #fbd0d5;
  --fondo-verde2: #b6e9b8;
  --fondo-verde3: #f1f9f8;
  --fondo-verde4: #DCE8DC;
  --fondo-oscuro2: #acc4d0;
  --color-naranja: #f57c00;
  --auxiliares-informacion: #1e88e5;
  --auxiliares-advertencia: #fbc02d;
  --auxiliares-acierto: #388e3c;
  --auxiliares-error: #e64a19;
  --color-01:#A6255E;
  --color-02: #0070a7;
  --grey: #D1D1D1;
  --grey-01: #A2A2A2; /* Prog secundari player*/
  --grey-02: #F1F1F1; /* seccio i noticia */
  --grey-03: #333333;
  --grey-04: #d1d1d1;
  --grey-05: #666;
  --grey-06: #707070;
  --bordes-oscuro: #c7c7c7;
  --negro-transparente: rgba(0, 0, 0, 0.3);
  --fondo-error: #fef6f3;
  --fondo-informacion: #f4f9fe;


  --twitter: #469bfc;
  --instagram: #ba00b4;
  --whatsapp: #4dab54;
  --facebook: #3c5a96;
  --youtube: #ff0000;

    /*header*/
  --c-header-bg: var(--blanco);
  --c-header-color: var(--texto);
  --c-header-search-color: var(--texto-suave);
  --c-header-burger-color: var(--fondo-oscuro);
  --c-header-border-color: var(--fondo-oscuro);
  --c-header-border2-color: var(--fondo-oscuro);
  --c-header-claim-color: var(--texto-secundario);
  --c-header-claim-title-color: var(--texto);
  --c-header-claim-title-font: var(--third-font);
  --c-header-special-bg: var(--negro);
  --c-header-special-color: var(--blanco);
  --c-header-special-burger: var(--blanco);
  --c-header-height: 63px;

    /* nav */
  --c-nav-bg: var(--blanco);
  --c-nav-color: var(--texto);
  --c-nav-font: var(--primary-font);
  --c-nav-burger-color: var(--fondo-oscuro);
  --c-nav-border-color: var(--fondo-oscuro);
  --c-nav-current-color: var(--acento);
  --c-nav-horizontal-title-color: var(--texto-secundario);
  --c-nav-horizontal-color: var(--texto-suave);
  --c-nav-border-hamburger-color: var(--bordes);

    /* footer */
  --c-footer-bg: var(--fondo-oscuro);
  --c-footer-color: var(--blanco);
  --c-footer-font: var(--primary-font);
  --c-footer-nav-color: var(--blanco);
  --c-footer-share-bg-color: var(--blanco);
  --c-footer-share-color: var(--fondo-oscuro);
  --c-footer-copy-color: var(--blanco);
  --c-footer-copy-font: var(--third-font);

    /* detail */
  --c-detail-epigraph-font: var(--primary-font);
  --c-detail-epigraph-font-size: 16px;
  --c-detail-epigraph-color: var(--acento);
  --c-detail-title-font: var(--third-font);
  --c-detail-title-font-weight: 600;
  --c-detail-title-font-size: 42px;
  --c-detail-title-line-height: 1.05;
  --c-detail-title-color: var(--texto);
  --c-detail-subtitle-font: var(--secondary-font);
  --c-detail-subtitle-font-size: 21px;
  --c-detail-subtitle-line-height: normal;
  --c-detail-subtitle-color: var(--texto-suave);
  --c-detail-body-firstletter-color: var(--texto);
  --c-detail-body-text-font: var(--secondary-font);
  --c-detail-body-text-font-size: 19px;
  --c-detail-body-text-line-height: 1.26;
  --c-detail-body-text-color: var(--texto);
  --c-detail-body-ladillo-color: var(--acento);
  --c-detail-share-bg: var(--fondo-intermedio);
  --c-detail-box-comments-bg: var(--acento);
  --c-detail-author-font: var(--third-font);
  --c-detail-author-font-size: 33px;
  --c-detail-author-line-height: 38px;
  --c-detail-author-color: var(--texto);
  --c-detail-biography-font: var(--secondary-font);
  --c-detail-biography-font-size: 17px;
  --c-detail-biography-line-height: 21px;
  --c-detail-biography-color: var(--texto-suave);
    @media (--screen-md-min) {
        --c-detail-epigraph-font-size: 20px;
        --c-detail-title-font-size: 60px;
        --c-detail-title-line-height: 1.03;
        --c-detail-subtitle-font-size: 27px;
        --c-detail-subtitle-line-height: normal;
        --c-detail-body-text-font-size: 24px;
        --c-detail-body-text-line-height: normal;
        --c-detail-author-font-size: 39px;
        --c-detail-author-line-height: 41px;
        --c-detail-biography-font-size: 21px;
        --c-detail-biography-line-height: 25px;
    }

    /*title board*/
  --c-title-name-font: var(--third-font);
  --c-title-name-font-weight: 600;
  --c-title-name-font-size: 32px;
  --c-title-name-line-height: 1.07;
  --c-title-name-color: var(--texto-secundario);
  --c-title-name-padding: 8px 0 16px;

    /*article*/
  --c-article-pretitle-color: var(--acento);
  --c-article-epigraph-font: var(--primary-font);
  --c-article-epigraph-font-size: 14px;
  --c-article-epigraph-color: var(--texto-secundario);
  --c-article-epigraph-highlighted-color: var(--acento);
  --c-article-title-font: var(--third-font);
  --c-article-title-font-weight: 600;
  --c-article-title-font-size: 23px;
  --c-article-title-line-height: 1.09;
  --c-article-title-color: var(--texto);
  --c-article-author-font: var(--primary-font);
  --c-article-author-font-size: 11px;
  --c-article-author-color: var(--texto);
  --c-article-comment-color: var(--acento);
    @media (--screen-md-min) {
        --c-article-title-font-size: 27px;
    }

    /*Portadas automáticas*/
    --c-description-tag-font: var(--primary-font);
    --c-description-tag-font-size: 14px;
    --c-description-tag-line-height: 17px;
    --c-description-tag-color: var(--texto-suave);

    /*líneas de separación entre boards*/
  --c-line-board-color: var(--bordes);

    /*iconos multimedia*/
  --c-icon-multimedia-bg: var(--blanco);
  --c-icon-multimedia-color: var(--acento);
  --c-icon-multimedia-border: var(--acento);

    /*textos multimedia*/
    --c-icon-txt-font: var(--primary-font);
    --c-icon-txt-size: 13px;
    --c-icon-txt-line-height: 16px;
    --c-icon-txt-font-weight: 600;
    --c-icon-time-font: var(--primary-font);
    --c-icon-time-size: 13px;
    --c-icon-time-line-height: 16px;
    --c-icon-time-font-weight: 400;
    --c-icon-time-color: var(--texto-secundario);

    /*ext__title-1*/
  --ext__title-1-font-size: 23px;
  --ext__title-1-line-height: 1.09;
    @media (--screen-sm-min){
        --ext__title-1-font-size: 24px;
        --ext__title-1-line-height: 27px;
    }
    /*ext__title-2*/
  --ext__title-2-font-size: 28px;
  --ext__title-2-line-height: 1.07;
    @media (--screen-sm-min){
        --ext__title-2-font-size: 29px;
        --ext__title-2-line-height: 33px;
    }
    /*ext__title-3*/
  --ext__title-3-font-size: 33px;
  --ext__title-3-line-height: 1.06;
    @media (--screen-sm-min){
        --ext__title-3-font-size: 33px;
        --ext__title-3-line-height: 38px;
    }
    /*ext__title-4*/
  --ext__title-4-font-size: 37px;
  --ext__title-4-line-height: 1.05;
    @media (--screen-sm-min){
        --ext__title-4-font-size: 40px;
        --ext__title-4-line-height: 46px;
    }
    /*ext__title-5*/
  --ext__title-5-font-size: 42px;
  --ext__title-5-line-height: 1.05;
    @media (--screen-sm-min){
        --ext__title-5-font-size: 45px;
        --ext__title-5-line-height: 54px;
    }
    /*ext__title-6*/
  --ext__title-6-font-size: 50px;
  --ext__title-6-line-height: 1.04;
    @media (--screen-sm-min){
        --ext__title-6-font-size: 54px;
        --ext__title-6-line-height: 64px;
    }
}

$white:    #fff !default;
$border-radius-profile:               .25rem !default;
$border-radius-lg-profile:            .3rem !default;
$border-radius-sm-profile:            .2rem !default;
$font-weight-bold-profile:            700 !default;
$border-width-profile:                1px !default;

$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-top:               1rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius-profile !default;
$alert-link-font-weight:            $font-weight-bold-profile !default;
$alert-border-width:                $border-width-profile !default;

$alert-bg-level:                    -10 !default;
$alert-border-level:                -9 !default;
$alert-color-level:                 6 !default;

$enable-gradients:                            false !default;
$body-bg:                   $white !default;


