.c-poll{
    background-color: var(--fondo-gris);
    padding: 10px;
    position: relative;
    &__pretitle{
        margin-bottom: 13px;
        display: flex;
        flex-direction: row;
        gap:5px;
        justify-content: flex-start;
        align-items: center;
        &__icon{
            width: 24px;
            height: 24px;
            color: var(--acento);
        }
        &__name{
            font-size: 14px;
            font-weight: 600;
            color: var(--acento);
            text-transform: uppercase;
        }
    }
    &__title, .c-poll-title{
        margin-bottom: 13px;
        font-size: 16px;
        font-weight: 600;
        color: var(--texto);
        padding-bottom:8px;
    }
    &__msg, &__msg__total__votes, &__msg__total__votes__without__user__voted{
        font-weight: 300;
        font-size: 11px;
        color: var(--texto);
        margin-bottom: 13px;
    }
    &__msg__total__votes, &__msg__total__votes__without__user__voted{
        display:none;
    }
    &__result{
        &__item{
            clear:both;
            color: var(--texto-suave);
            padding: 1px;
            &:not(:last-child){
                margin-bottom: 5px;
            }
            input[type=radio] {
                margin-right: 7px;
                width: 18px;
                height: 18px;
                border: 2px solid var(--texto);
            }
            .poll-option {
              vertical-align: top;
            }
            .p1 {
                font-size: 14px;
                color: var(--texto);
                font-weight: 600;
                display: inline-flex;
                vertical-align: top;
            }
            .p2{
                background-color:var(--bordes);
                display:block;
                height:10px;
                margin:2px 15px 10px 0;
                vertical-align:middle;
                width: 100%;
                font-size: 16px;
                line-height: 1.31;
                .index{
                    background:var(--acento);
                    display:block;
                    height:10px;
                }
            }
            .count{
                font-size: 14px;
                color: var(--texto);
                font-weight: 400;
                &:before{
                    content: '-';
                    display: inline-block;
                    padding-right: 2px;
                }
            }
        }
    }
    &__footer{
        padding: 10px 0 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__btn{
            background-color: var(--enlace);
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            width: min-content;
            height: 44px;
            font-size: 14px;
            text-transform: uppercase;
            color: var(--blanco);
            cursor: pointer;
            transition: box-shadow .3s;
            font-family: var(--primary-font);
            padding: 14px;
            margin-right: 25px;
            span{
                &:not(.sr-only){
                    display: block;
                }
            }
            &:hover{
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
            }
            &:active{
                border: solid 1px var(--enlace-oscuro);
            }
            &:focus{
                box-shadow: 0 0 4px 0 var(--enlace);
                background-color: var(--enlace);
            }
            &:disabled{
                background-color: var(--bordes);
                box-shadow: none;
            }
        }
        &__link{
            font-size: 14px;
            color: var(--enlace);
            text-decoration: none;
            text-transform: uppercase;
        }
    }
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    &.u-nomargin {
        &-bottom {
            @media (--screen-xs-max) {
                margin-bottom: 32px !important;
                &:after {
                    margin-top: 0 !important;
                }
                &.u-col-2 {
                    margin-bottom:0 !important;
                }
                &.c-article-last:last-child {
                    margin-bottom:0 !important;
                }
            }
        }
    }
    &-module-double {
      &:last-child {
          .c-article-lateral-case-3:after {
              display: none;
          }
      }
    }
    &-double {
        margin-bottom: 32px;
        @media (--screen-sm-min) {
            margin-bottom: 40px;
            &.u-border:after {
                margin: 20px auto;
                bottom: -40px;
                position: absolute;
                left: 0;
            }
        }
    }
}
.poll {
    .poll-bar,
    .poll-percent,
    .poll-total-votes,
    .poll-option-votes,
    .c-poll__msg,
    .c-poll__title{
        display: none;
    }
    &.poll-voted {
        input, button {
            display: none;
        }
        .poll-bar,
        .poll-percent,
        .poll-total-votes,
        .poll-option-votes,
        .c-poll__title,
        .c-poll__msg,
        .c-poll__footer__btn{
            display: block;
        }
        .c-poll__result__item {
            color: var(--texto);
            padding: 0;
            &:not(:last-child){
                margin-bottom: 0;
            }
        }
        .poll-option {
            font-size: 14px;
            font-weight: 600;
        }
    }
    &.results {
        .c-poll__result__item {
            padding: 0;
            &:not(:last-child){
                margin-bottom: 0;
            }
        }
        .poll-option {
            font-size: 14px;
            font-weight: 600;
        }
    }
}
@media (--screen-sm-max) {
    .u-grid-columns-2-xs {
        .c-poll__footer {
            flex-direction: column;
            &__btn {
                margin-bottom: 12px;
                margin-right: 0;
            }
            &__link {
                margin-bottom: 12px;
            }
        }
    }
}
.poll-show-total-votes{
    font-weight: 300;
    font-size: 11px;
    color: #030303;
    color: var(--texto);
    margin-bottom: 13px;
}
