.u-pt-20{
    padding-top: 20px;
}
.u-pt-0{
    padding-top: 0;
}
@media (--screen-xs-max) {
    .u-pb-xs-0{
        padding-bottom: 0 !important;
    }
    .u-pt-xs-0{
        padding-top: 0 ;
    }
    .u-pl-xs-0{
        padding-left: 0 ;
    }
    .u-pr-xs-0{
        padding-right: 0 ;
    }
    .u-padding-xs-0{
        padding: 0;
    }
    .u-pt-xs {
        padding-top: 16px ;
    }
    .u-pb-xs {
        padding-bottom: 16px ;
    }
}
@media (--screen-sm-min) {
  .u-pb-0{
    padding-bottom: 0 !important ;
  }
  .u-pt-0{
    padding-top: 0 ;
  }
  .u-pl-0{
    padding-left: 0 ;
  }
  .u-pr-0{
    padding-right: 0 ;
  }
  .u-padding-0{
    padding: 0;
  }
  .u-padding-16{
    padding: 16px;
  }
  .u-pt-16 {
    padding-top: 16px ;
  }
  .u-pb-16 {
    padding-bottom: 16px ;
  }
}
