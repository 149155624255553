.c-description {
    &__cont {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        @media (--screen-sm-min) {
            flex-direction: row;
            margin-bottom: 20px;
        }
    }
    img {
        display: block;
        width: 88px;
        height: 88px;
        margin-bottom: 14px;
        border-radius: 50%;
        @media (--screen-sm-min) {
            margin-right: 18px;
            margin-bottom: 0;
        }
    }
    &__txt {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: var(--c-description-tag-font-size);
        line-height: var(--c-description-tag-line-height);
        font-family: var(--c-description-tag-font);
        color: var(--c-description-tag-color);
        @media (--screen-sm-min) {
            text-align: left;
        }
    }
    .c-line {
        margin: 0;
    }
}
