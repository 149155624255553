.c-pagination {
  text-align: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  --color: var(--acento);
  ul {
    margin: 0 auto 20px;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    li {
      font-family: var(--primary-font);
      font-size: 16px;
      font-weight: 700;
      background-color: var(--fondo-gris);
      margin:0 4px;
      width: 32px;
      height: 32px;
      &.active {
        color: var(--texto);
        cursor: initial;
        span{
          color: var(--texto);
        }
      }
      &.disabled {
        a{
          cursor: initial;
        }
        .c-pagination__icon{
         color:var(--bordes);
       }

      }
      a {
        color : var(--color);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
      }
      span {
        color: var(--color);
        overflow: hidden;
        position: relative;
        width: 32px;
        height: 32px;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .sr-only {
        display: none;
      }
      @media (--screen-xs-max) {
          font-size: 12px;
          margin: 0 3px;
          width: 30px;
          height: 30px;
      }
    }
    @media (--screen-sm-min) {
      justify-content: space-between;

    }
  }
  &__icon{
    display: block;
    width: 24px;
    height: 24px;
  }
  &__next{
  }
  &__prev{

  }
  &__first{

  }
  &__last{

  }

}
