.c-detail {
    /* elementos */
    &__epigraph {
        font-size: var(--c-detail-epigraph-font-size);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        font-family: var(--c-detail-epigraph-font);
        letter-spacing: normal;
        text-align: left;
        color: var(--c-detail-epigraph-color);
        margin-bottom: 10px;
        a {
            color: var(--c-detail-epigraph-color);
        }
    }
    &__title {
        font-size: var(--c-detail-title-font-size);
        font-weight: var(--c-detail-title-font-weight);
        font-stretch: normal;
        font-style: normal;
        line-height: var(--c-detail-title-line-height);
        letter-spacing: normal;
        text-align: left;
        color: var(--c-detail-title-color);
        font-family: var(--c-detail-title-font);
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        .c-detail__epigraph {
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            color: var(--acento);
            font-family: var(--primary-font);
            a {
                color: var(--acento);
            }
            @media (--screen-md-min) {
                font-size: 20px;
            }
        }
        &__author {
            font-size: var(--c-detail-author-font-size);
            line-height: var(--c-detail-author-line-height);
            font-family: var(--c-detail-author-font);
            color: var(--c-detail-author-color);
            margin-bottom: 2px;
        }
    }
    &__subtitle {
        font-size: var(--c-detail-subtitle-font-size);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: var(--c-detail-subtitle-line-height);
        letter-spacing: normal;
        text-align: left;
        color: var(--c-detail-subtitle-color);
        font-family: var(--c-detail-subtitle-font);
        margin-bottom: 16px;
        a {
            color: var(--enlace);
            display: inline;
            font-weight: 400;
        }
        h2 {
            font-size: var(--c-detail-subtitle-font-size);
            line-height: var(--c-detail-subtitle-line-height);
            font-weight: 400;
        }
        li {
            &:before {
                content: "";
                background: url(../images/icon/arrow.svg) center no-repeat;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 12px;
                left: 0;
            }
            padding: 5px 0 5px 35px;
            position: relative;
            line-height: inherit;
            @media (--screen-xs-max) {
                padding: 5px 0 5px 24px;
                &:before {
                    width: 18px;
                    height: 18px;
                    top: 10px;
                }
            }
        }
    }
    &__biography {
        font-size: var(--c-detail-biography-font-size);
        line-height: var(--c-detail-biography-line-height);
        font-family: var(--c-detail-biography-font);
        color: var(--c-detail-biography-color);
        text-align: center;
        @media (--screen-sm-min) {
            text-align: left;
        }
    }
    &__body {
        padding-top: 20px;
        margin-bottom: 20px;
        border-top: 1px solid var(--bordes);
        & > h3,
        & > h2.subtitle {
            font-family: var(--third-font);
            font-size: 33px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal;
            text-align: left;
            color: var(--c-detail-body-ladillo-color);
            @media (--screen-md-min) {
                font-size: 41px;
                line-height: 1.05;
            }
        }
        & > blockquote {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 12px 0;
            p {
                font-family: var(--third-font);
                font-size: 28px;
                font-weight: 600;
                font-stretch: normal;
                font-style: italic;
                line-height: 1.07;
                letter-spacing: normal;
                text-align: left;
                color: var(--texto);
                padding-top: 8px;
                @media (--screen-md-min) {
                    font-size: 35px;
                    line-height: 1.06;
                }
            }
            &:before {
                content: "{";
                font-family: Arial, sans-serif;
                color: var(--acento);
                font-weight: bold;
                font-size: 31px;
                padding-left: 20px;
                margin-right: 8px;
                padding-top: 0;
                @media (--screen-md-min) {
                    padding-left: 40px;
                    font-size: 40px;
                }
            }
            @media (--screen-md-min) {
            }
        }
        & > * {
            &:not(
                    :last-child,
                    .c-detail__box-right,
                    .c-detail__post .c-detail__address,
                    .c-detail__media.image_list:not(&.image_description)
                ) {
                margin-bottom: 20px;
            }
        }
        div.c-detail__obituary + div.paragraph {
            &:first-letter {
                text-transform: uppercase;
                font-size: 94px;
                line-height: 28px;
                letter-spacing: 1.26px;
                float: left;
                margin-top: 24px;
                margin-bottom: 20px;
                margin-right: 6px;
            }
        }
        &:after {
            content: "";
            clear: both;
            display: block;
        }
        & > p.paragraph:empty {
            @include paragraph-lists;
        }
        &
            > p.paragraph:has(
                .c-detail__box.c-detail__box-right.c-detail__branded
            ) {
            @include paragraph-lists;
        }
    }
    &__tags {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 20px;
        white-space: nowrap;
        &__title {
            font-size: 14px;
            font-weight: 600;
            color: var(--texto-secundario);
            text-transform: uppercase;
            padding: 7px 10px 7px 0;
            @media (--screen-md-min) {
                font-size: 16px;
                padding: 11px 10px 13px 0;
            }
        }
        &__list {
            padding: 0;
            grid-gap: 8px;
            height: inherit;
            z-index: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #f3f3f3;
            }
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            @media (--screen-md-min) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 16px;
            }
        }
        &__item {
            padding: 7px 10px;
            border-radius: 4px;
            background-color: var(--fondo-gris);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (--screen-md-min) {
                padding: 11px 10px 13px;
            }
            a {
                display: block;
                font-size: 14px;
                font-weight: 300;
                color: var(--texto);
                text-transform: uppercase;
                @media (--screen-md-min) {
                    font-size: 16px;
                }
            }
        }
        @media (--screen-md-min) {
            white-space: inherit;
        }
    }
    &__info {
        font-family: var(--primary-basic-font);
        font-size: 16px;
        line-height: 1.05;
        color: var(--texto-secundario);
        margin-bottom: 14px;
        @media (--screen-md-min) {
            margin-bottom: 0;
        }
        &__locate {
            font-size: 16px;
            color: var(--texto-secundario);
            &:after {
                content: "-";
                padding: 0 3px;
            }
        }
        &__update {
            color: var(--acento);
            &:before {
                content: "-";
                padding: 0 3px;
                color: var(--texto-secundario);
            }
        }
        &__date {
        }
    }
    &__share {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        width: 100%;
        @media (--screen-md-min) {
            justify-content: center;
            width: auto;
        }
        &-icons {
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
        }
        .c-icon {
            color: var(--enlace-oscuro);
            background-color: var(--fondo-gris);
            border-radius: 50%;
            width: 32px;
            height: 32px;
            padding: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
                width: 100%;
                height: 100%;
            }
            &-whatsapp {
                @media (--screen-md-min) {
                    display: none;
                }
            }
            &-print {
                display: none;
                background-color: transparent;
                @media (--screen-md-min) {
                    display: block;
                }
            }
            &-save{
                cursor: pointer;
                background-color: transparent;
            }
            &-save-success{
                cursor: pointer;
                background-color: transparent;
            }
        }
        .whatsapp-web-icon {
            display: none;
            padding-top: 4px;
            @media (--screen-md-min) {
                display: block;
            }
        }
    }
    &__toolbar {
        margin-bottom: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: self-start;
        flex-direction: column;
        .c-detail__closed-bluebox {
            top: 65px;
            right: 20px;
            @media (--screen-md-min) {
                top: 40px;
            }
        }
        @media (--screen-md-min) {
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }
    }
    &__comment {
        width: auto;
        min-width: 32px;
        height: 32px;
        margin: 0;
        padding: 0 7px;
        border-radius: 4px;
        background-color: var(--texto);
        position: relative;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        font-family: var(--primary-font);
        line-height: 1.2;
        color: var(--blanco);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:before {
            position: absolute;
            content: "";
            border-top: 15px solid var(--texto);
            border-right: 15px solid transparent;
            bottom: -6px;
            left: 4px;
        }
    }
    &__comments {
        .box-comments {
            margin-bottom: 20px;
            border-bottom: 1px solid var(--fondo-oscuro);
            cursor: pointer;
            background: var(--white);
            padding-bottom: 1px;
            .box2-comments {
                background: var(--c-detail-box-comments-bg);
                border-bottom: 1px solid var(--fondo-oscuro);
                padding: 7px 9px 12px 6px;
                @media (--screen-md-min) {
                    padding: 12px 16px 15px 16px;
                }
            }
            .number-comments {
                display: inline;
                font-family: var(--third-font);
                font-size: 23px;
                line-height: 25px;
                color: var(--blanco);
                @media (--screen-md-min) {
                    font-size: 24px;
                    line-height: 27px;
                }
                &:before {
                    content: "";
                    width: 32px;
                    height: 30px;
                    background: url(../images/icon/globos-comentarios.png)
                        no-repeat center center;
                    display: inline-block;
                    margin-right: 10px;
                    float: left;
                }
            }
            .title-comments {
                font-family: var(--third-font);
                font-size: 23px;
                line-height: 25px;
                display: inline-block;
                color: var(--blanco);
                @media (--screen-md-min) {
                    font-size: 24px;
                    line-height: 27px;
                }
            }
            .icon {
                float: right;
                margin-top: 3px;
                @media (--screen-md-min) {
                    margin-top: 4px;
                }
                &.arrow-down {
                    &:before {
                        content: "";
                        background: url(../images/icon/punta-derecha-white.svg)
                            no-repeat 0 0;
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        transform: rotate(90deg);
                    }
                }
                &.arrow-up {
                    &:before {
                        content: "";
                        background: url(../images/icon/punta-derecha-white.svg)
                            no-repeat 0 0;
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        transform: rotate(-90deg);
                    }
                }
            }
        }
        #disqus_thread {
            display: none;
        }
    }
    &__signature {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (--screen-md-min) {
            flex-direction: row;
            align-items: center;
        }
    }
    .article-info .c-detail__author {
        @media (--screen-md-min) {
            min-height: 68px;
        }
    }
    &__author {
        display: block;
        &__thumb {
            display: inline-block;
            margin-bottom: 12px;
            @media (--screen-md-min) {
                margin-bottom: 5px;
            }
            picture {
                display: inline-block;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 8px;
            }
            &__opinion {
                display: inline-block;
                picture {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 3px;
                }
                img {
                    width: 72px;
                }
            }
        }
        > .c-detail__author__txt {
            margin-bottom: 12px;
            @media (--screen-md-min) {
                margin-bottom: 5px;
            }
        }
        + .c-detail__author .c-detail__author__txt {
            @media (--screen-sm-max) {
                padding-top: 0;
            }
        }
        &__txt {
            display: inline-block;
            vertical-align: top;
            line-height: normal;
            padding-right: 15px;
            padding-top: 10px;
        }
        &__thumb + &__txt {
            margin-bottom: 0;
        }
        &__name {
            text-decoration: none;
            font-weight: 600;
            font-size: 24px;
            font-style: normal;
            line-height: 1.09;
            letter-spacing: -.23px;
            color: var(--texto);
            font-style: normal;
            a {
                display: inline-block;
            }
            font-family: var(--third-font);
        }
        &__blog {
            font-style: normal;
            text-transform: none;
            color: var(--texto-secundario);
            font-weight: 600;
            padding-right: 5px;
            font-size: 12px;
            @media (--screen-md-min) {
                font-size: 12px;
            }
        }
        &__job__position {
            font-style: normal;
            text-transform: none;
            color: var(--texto-secundario);
            font-size: 14px;
            display: block;

            @media (--screen-md-min) {
                font-size: 12px;
            }
        }
        &__twitter {
            margin-top: 5px;
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            &:before {
                content: "";
                background: url(../../images/icon/twitter.svg) center no-repeat;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                left: 0;
            }
            &:empty {
                display: none;
            }
            span {
                display: none;
            }
        }
        &__facebook {
            margin-top: 5px;
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            &:before {
                content: "";
                background: url(../../images/icon/facebook_v2.svg) center
                    no-repeat;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                left: 0;
            }
            &:empty {
                display: none;
            }
            span {
                display: none;
            }
        }
        &__instagram {
            margin-top: 5px;
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            &:before {
                content: "";
                background: url(../../images/icon/instagram.svg) center
                    no-repeat;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                left: 0;
            }
            &:empty {
                display: none;
            }
            span {
                display: none;
            }
        }
        &__cont {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 15px;
            @media (--screen-sm-min) {
                flex-direction: row;
                margin-bottom: 20px;
            }
            img {
                display: block;
                width: 88px;
                height: 88px;
                margin-bottom: 14px;
                border-radius: 50%;
                @media (--screen-sm-min) {
                    margin-right: 18px;
                    margin-bottom: 0;
                }
            }
            .c-detail__author__cont__txt {
                display: flex;
                flex-direction: column;
                align-items: center;
                .social {
                    a:not(:last-child) {
                        margin-right: 3px;
                    }
                    & + .c-detail__biography {
                        margin-top: 6px;
                    }
                }
                @media (--screen-sm-min) {
                    align-items: initial;
                }
            }
        }
    }

    &__author.no-author {
        display: none;
    }
    &__media {
        position: relative;
        &__thumb {
            img {
                display: block;
                width: 100%;
                object-fit: cover;
                height: auto;
                @media (--screen-sm-max) {
                    height: 100%;
                }
            }
        }
        &__txt {
            border-bottom: 1px solid var(--bordes);
            padding: 10px 0;
            &__branded-special {
                margin: 0 20px;
                @media (--screen-sm-min) {
                    margin: 0;
                }
                @media (--screen-md-min) {
                    margin: 0 30px;
                }
            }
        }
        &__subtitle {
            text-align: left;
            color: var(--texto);
            font-size: 14px;
        }
        &__credit {
            color: var(--texto);
        }
        &__author {
            color: var(--texto-secundario);
        }
        &__credit + &__author {
            &:before {
                content: " - ";
            }
        }
        &-widget {
            margin-bottom: 8px;
            @media (--screen-xs-max) {
                margin-top: 18px;
            }
        }
        &__icon {
            --c-icon-multimedia-bg: none;
            --c-icon-multimedia-color: var(--blanco);
            position: absolute;
            bottom: 20px;
            left: 20px;
            @extend %ext__icon-media;
            padding: 0;
            width: 64px;
            height: 64px;
            border: 4px solid var(--blanco);
            border-radius: 50%;
            svg {
                width: 48px;
                height: 48px;
            }
            @media (--screen-md-min) {
                width: 120px;
                height: 120px;
                border-width: 6px;
                svg {
                    width: 104px;
                    height: 104px;
                }
            }
        }
        &-video {
            .c-detail__media__thumb {
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    background-image: linear-gradient(
                        11deg,
                        var(--negro) 0%,
                        rgba(0, 0, 0, 0) 50%
                    );
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 104px;
                }
            }
        }
        &.image_list:not(&.image_description) {
            margin-bottom: 0;
            figcaption {
                display: none;
            }
        }
    }
    &__cite {
        display: block;
        padding: 12px 0;
        svg {
            width: 40px;
            height: 31.3px;
            color: var(--acento);
            float: left;
            @media (--screen-md-min) {
                width: 50px;
                height: 39.1px;
            }
        }
        &__title {
            font-family: var(--third-font);
            font-size: 28px;
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.07;
            letter-spacing: normal;
            text-align: left;
            color: var(--texto);
            position: relative;
            margin-left: 10px;
            padding-top: 3px;
            margin-bottom: 8px;
            padding-left: 5px;
            display: grid;
            @media (--screen-md-min) {
                font-size: 35px;
                line-height: 1.06;
                margin-bottom: 10px;
            }
        }
        &__author,
        &__job {
            font-family: var(--primary-font);
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            display: block;
            padding-left: 50px;
            @media (--screen-md-min) {
                padding-left: 60px;
                font-size: 20px;
            }
        }
        &__author {
            color: var(--acento);
        }
        &__job {
            color: var(--texto-secundario);
            @media (--screen-md-min) {
                font-size: 16px;
            }
        }
    }

    .main_video {
        position: relative;
        .c-detail__media__thumb {
            position: relative;
            &::before {
                content: "";
                display: block;
                background-image: linear-gradient(
                    11deg,
                    var(--negro) 0%,
                    rgba(0, 0, 0, 0) 50%
                );
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 104px;
                @media (--screen-md-min) {
                    height: 180px;
                }
            }
        }
        .c-detail__media__icon {
            svg {
                @media (--screen-md-min) {
                    width: 72px;
                    height: 72px;
                }
            }
            @media (--screen-md-min) {
                bottom: 30px;
                left: 30px;
                width: 120px;
                height: 120px;
            }
        }
    }

    &__box {
        padding-left: 28px;
        &__title {
            border-bottom: 1px solid var(--bordes);
            text-transform: uppercase;
            color: var(--acento);
            font-size: 15px;
            font-weight: 600;
            font-family: var(--primary-font);
            padding: 4px 0 9px;
            margin-bottom: 14px;
            @media (--screen-md-min) {
                font-size: 20px;
                padding: 8px 0 10px;
                margin-bottom: 10px;
            }
        }
        &__list {
            &-bullet {
                list-style-type: disc;
                list-style-position: inside;
                .c-detail__box__list__item {
                    font-family: var(--secondary-font);
                    font-size: 15px;
                    line-height: 1.24;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--texto-suave);
                    display: block;
                    position: relative;
                    padding-left: 24px;
                    &::before {
                        content: "";
                        width: 9px;
                        height: 9px;
                        background-color: var(--fondo-intermedio);
                        border-radius: 50%;
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: 6px;
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    a {
                        color: var(--enlace);
                    }
                    u,
                    b,
                    i,
                    s,
                    a {
                        display: contents;
                    }
                    @media (--screen-md-min) {
                        font-size: 21px;
                        padding-left: 26px;
                        &:not(:last-child) {
                            margin-bottom: 9px;
                        }
                        &::before {
                            width: 10px;
                            height: 10px;
                            top: 9px;
                        }
                    }
                }
            }
        }
        .c-detail__media {
            &__subtitle {
                font-size: 11px;
            }
        }

        .c-article {
            background-color: var(--blanco);
            border: solid 1px var(--fondo-oscuro);
            padding: 21px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: -28px;
            box-sizing: border-box;

            &__txt {
                width: 100%;
            }

            &:not(.c-detail__branded) {
                &__thumb {
                    width: 120px;
                    height: 90px;
                    margin-left: 20px;
                    & + .c-article__txt {
                        padding-top: 0;
                        max-width: calc(100% - 140px);
                    }
                }
            }
        }

        .c-opinion {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: -28px;
            box-sizing: border-box;
        }
        &-right {
            padding-left: 0;
            display: block;
            float: right;
            width: 100%;
            max-width: 175px;
            margin-left: 20px;
            @media (--screen-md-min) {
                max-width: 220px;
            }
            .c-detail__box__title {
                margin-bottom: 0;
                font-size: 11px;
                font-weight: 600;
                padding-bottom: 7px;
                @media (--screen-md-min) {
                    font-size: 14px;
                    padding-bottom: 11px;
                    margin-bottom: 4px;
                }
            }
            .c-detail__box__list {
                border-bottom: 4px solid var(--fondo-intermedio);
                margin-bottom: 10px;
                &__item {
                    padding: 8px 0;
                    a {
                        font-size: 23px;
                        font-weight: 600;
                        font-family: var(--third-font);
                        line-height: 1.09;
                        letter-spacing: -0.23px;
                        color: var(--texto);
                        @media (--screen-md-min) {
                            font-size: 27px;
                            line-height: 1.07;
                            letter-spacing: -0.27px;
                        }
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid var(--bordes);
                    }
                }
            }
            &.c-detail__branded {
                .c-branded {
                    margin-bottom: 10px;
                    .c-article__txt {
                        padding: 16px;
                        width: 100%;
                        max-width: inherit;
                    }
                }
            }
        }
    }
    &__despiece {
        padding: 16px;
        background-color: var(--fondo-gris);
        margin-bottom: 20px;
        border-bottom: 8px solid var(--bordes);
        & > h3 {
            font-family: var(--third-font);
            font-size: 33px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal;
            text-align: left;
            color: var(--texto);
            @media (--screen-md-min) {
                font-size: 41px;
                line-height: 1.05;
            }
        }
        & > * {
            &:not(:last-child, .c-detail__box-right) {
                margin-bottom: 20px;
            }
        }
        &.bg-blanco {
            background-color: var(--blanco);
            padding: 0 0 16px;
        }
    }
    &__pros-contras {
        background-color: var(--fondo-gris);
        padding: 15px;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        > div {
            display: flex;
            &:first-child {
                margin-bottom: 18px;
                h3 {
                    color: var(--auxiliares-acierto);
                }
                @media (--screen-sm-min) {
                    margin-bottom: 0;
                }
            }
        }
        svg {
            margin-right: 9px;
            min-width: 24px;
        }
        h3 {
            font-size: 19px;
            line-height: 21px;
            font-family: var(--third-font);
            color: var(--auxiliares-error);
            display: inline-block;
            text-transform: uppercase;
            margin-bottom: 6px;
            @media (--screen-md-min) {
                font-size: 21px;
                line-height: 23px;
            }
        }
        ul {
            li {
                font-size: 14px;
                line-height: 17px;
                font-family: var(--primary-font);
                color: var(--texto);
                margin-top: 4px;
                > a {
                    display: inline;
                }
                @media (--screen-md-min) {
                    margin-top: 5px;
                }
            }
        }
        @media (--screen-md-min) {
            padding: 20px;
        }
    }
    .paragraph + .c-detail__post {
        margin-top: 20px;
    }
    &__post {
        order: 1;
        padding: 16px;
        background-color: var(--fondo-gris);
        margin-bottom: 20px;
        border-bottom: 1px solid var(--bordes);
        .c-detail__info {
            &__day {
                font-size: 14px;
                font-weight: 600;
                color: var(--texto);
                display: none;
                margin-right: 5px;
            }
            &__date {
                font-size: 14px;
                font-weight: 600;
                color: var(--texto);
            }
            &__update {
                font-size: 14px;
                color: var(--texto-secundario);
                &::before {
                    display: none;
                }
            }
        }
        .c-detail__body {
            border-top: none;
            padding-top: 5px;
            @media (--screen-md-min) {
                padding-top: 25px;
            }
        }
        &__bottom{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .c-icon{
                color: var(--acento);
            }
        }
        h3 {
            font-family: var(--third-font);
            font-size: 33px;
            font-weight: 600;
            line-height: 1.06;
            text-align: left;
            color: var(--acento);
            @media (--screen-md-min) {
                font-size: 41px;
                line-height: 1.05;
            }
        }
        address {
            margin-bottom: 0;
            .c-detail__author {
                margin-bottom: 10px;
            }
        }
        &-opinion {
            .c-detail__info {
                margin-bottom: 15px;
                @media (--screen-md-min) {
                    margin-bottom: 20px;
                }
            }
            address {
                margin-bottom: 20px;
                .c-detail__author {
                    margin-bottom: 0;
                    .c-detail__author__txt {
                        font-size: 16px;
                        vertical-align: bottom;
                        .c-detail__author__name {
                            color: var(--texto-secundario);
                            font-size: 16px;
                        }
                        @media (--screen-md-min) {
                            font-size: 20px;
                            .c-detail__author__name {
                                color: var(--texto-secundario);
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .c-detail__body {
                padding-top: 0;
            }
            h3 {
                font-style: italic;
                font-size: 28px;
                line-height: 30px;
                color: var(--texto);
                @media (--screen-md-min) {
                    font-size: 35px;
                    line-height: 37px;
                }
            }
        }
        &-main {
            background: var(--fondo-rosa);
        }
    }
    &__post ~ .c-detail__post {
        h3 {
            font-size: 28px;
            line-height: 30px;
            color: var(--texto);
            @media (--screen-md-min) {
                font-size: 35px;
                line-height: 37px;
            }
        }
    }
    &__post-pinned {
        order: -1;
    }
    &__gallery {
        margin-bottom: 16px;
        @media (--screen-md-min) {
            margin-bottom: 20px;
        }
        &__item {
            border-bottom: 1px solid var(--bordes);
            &__txt {
                padding: 8px 0 16px;
                @media (--screen-md-min) {
                    padding: 10px 80px 20px;
                }
            }
            &__thumb {
                @extend %ext__no-padding;
                img {
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    height: auto;
                }
            }
            &__index {
                display: none;
                font-size: 11px;
                color: var(--acento);
                margin-bottom: 8px;
                @media (--screen-md-min) {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
            &__credit {
                font-family: var(--third-font);
                font-size: 28px;
                line-height: 30px;
                letter-spacing: -0.23px;
                color: var(--acento);
                margin-bottom: 5px;
                @media (--screen-md-min) {
                    font-size: 29px;
                    line-height: 33px;
                }
                padding-bottom: 10px;
            }
            &__author {
                font-size: 16px;
                color: var(--texto-suave);
            }
            &:not(:last-child) {
                margin-bottom: 16px;
                @media (--screen-md-min) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__review {
        border-top: solid 1px #707070;
        border-bottom: solid 1px #707070;
        background-color: var(--fondo-gris);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 9px -16px 20px -16px;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        min-height: 220px;
        &__thumb {
            display: block;
            width: 100%;
            max-width: 142px;
            height: 202px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: #707070;
            padding: 1px;
            margin-top: -10px;
            margin-right: 16px;
            @media (--screen-md-min) {
                max-width: 152px;
                height: 242px;
                margin-right: 20px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__txt {
            padding: 10px 0;
            min-height: 220px;
            display: flex;
            flex-direction: column;
        }
        &__title {
            font-family: var(--third-font);
            font-size: 30px;
            line-height: 1.17;
            color: var(--texto);
            margin-bottom: 10px;
            order: 1;
            @media (--screen-md-min) {
                font-size: 33px;
                line-height: 1.12;
                margin-bottom: 5px;
            }
        }
        &__author {
            font-size: 14px;
            line-height: 1.14;
            color: var(--texto-suave);
            margin-bottom: 10px;
            order: 2;
            @media (--screen-md-min) {
                font-size: 22px;
                line-height: 1.18;
                margin-bottom: 20px;
            }
        }
        .c-detail__score {
            .star {
                width: 20px;
                height: 20px;
                &-disable {
                    &:before {
                        width: 16px;
                        height: 16px;
                    }
                }
                &-middle {
                    &:before {
                        width: 16px;
                        height: 16px;
                    }
                }
                @media (--screen-md-min) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        &__more {
            order: 4;
            margin-top: auto;
            p {
                font-size: 12px;
                line-height: 1.33;
                text-align: left;
                color: var(--texto-secundario);
                text-transform: uppercase;
                @media (--screen-md-min) {
                    font-size: 14px;
                    line-height: 1.29;
                }
            }
        }
        &.opinion {
            height: unset;

            .c-detail__review__txt {
                min-height: unset;

                .c-detail__review__more {
                    margin-top: 15px;
                }
            }
        }
        @media (--screen-md-min) {
            margin-left: 0;
            margin-right: 0;
            padding-left: 24px;
            padding-right: 24px;
            min-height: inherit;
            height: 220px;
        }
    }
    &__obituary {
        background-color: var(--fondo-gris);
        padding: 10px;
        box-sizing: border-box;
        &__cont {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__thumb {
            display: block;
            width: 100%;
            max-width: 60px;
            height: 60px;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__txt {
            padding: 0 0 10px;
            display: flex;
            flex-direction: column;
        }
        &__title {
            font-family: var(--third-font);
            font-size: 23px;
            line-height: 1.09;
            color: var(--enlace-oscuro);
            margin-bottom: 7px;
            order: 1;
            @media (--screen-md-min) {
                font-size: 27px;
                line-height: 1.07;
            }
        }
        &__job {
            font-size: 11px;
            line-height: 1.14;
            color: var(--texto-suave);
            margin-bottom: 7px;
            order: 2;
            font-weight: 600;
            @media (--screen-md-min) {
                font-size: 14px;
                line-height: 1.29;
            }
        }
        &__dates {
            font-size: 14px;
            line-height: 1.14;
            color: var(--texto-secundario);
            order: 3;
            margin-bottom: 2px;
            &__date {
                font-weight: 600;
                margin-right: 3px;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
        &__description {
            font-family: var(--secondary-font);
            font-size: 17px;
            line-height: 1.25;
            color: var(--texto-suave);
            @media (--screen-md-min) {
                font-size: 21px;
                line-height: 1.2;
            }
        }
        .c-line {
            margin-bottom: 10px;
        }
    }
    &__commerce {
        border-bottom: solid 1px var(--bordes);
        background-color: var(--fondo-gris);
        padding: 0 10px;
        &__txt {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 10px 0;
            &:nth-child(1) {
                border-bottom: 1px solid var(--bordes);
            }
        }
        &__title {
            order: 1;
            font-size: 24px;
            font-weight: 600;
            color: var(--acento);
            @media (--screen-md-min) {
                padding-right: 175px;
            }
        }
        &__address {
            font-size: 21px;
            line-height: 1.19;
            order: 2;
            color: var(--texto-suave);
            a {
                display: inline-flex;
                flex-direction: row;
                justify-content: flex-start;
                align-content: center;
                &:after {
                    content: "";
                    width: 24px;
                    height: 24px;
                    background: url(../images/icon/address.png) no-repeat center
                        center;
                    display: block;
                    margin-left: 10px;
                }
            }
            @media (--screen-md-min) {
                padding-right: 175px;
            }
        }
        &__cta {
            font-size: 16px;
            font-weight: 700;
            padding-right: 3px;
            color: var(--texto-suave);
            a {
                display: inline;
                color: var(--enlace);
                font-weight: 500;
                text-decoration: none;
            }
        }
        &__cta2 {
            font-size: 16px;
            font-weight: normal;
            color: var(--texto-suave);
            a {
                display: inline;
                color: var(--enlace);
                font-weight: 500;
                text-decoration: none;
            }
        }
        &__list {
            @media (--screen-md-min) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px 10px;
            }
        }
        .c-detail__score {
            @media (--screen-md-min) {
                position: absolute;
                right: 0;
                bottom: 10px;
            }
        }
    }
    &__reviewitems {
        background-color: var(--fondo-gris);
        padding: 10px;
        position: relative;
        box-sizing: border-box;
        &__main {
            grid-template-columns: 1fr 100px;
            grid-column-gap: 10px;
            display: grid;
            align-items: end;
            position: relative;
            margin-bottom: 8px;
            .c-detail__reviewitems__txt div {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
        &__secondary {
            grid-template-columns: 1fr 40px;
            grid-column-gap: 10px;
            display: grid;
            position: relative;
            margin-bottom: 9px;
            .c-detail__reviewitems__thumb {
                border-radius: 50%;
            }
            .c-detail__reviewitems__txt__item {
                &:first-child {
                    margin-bottom: 6px;
                }
                strong {
                    color: var(--texto);
                }
                &:first-child {
                    span {
                        font-weight: 600;
                        color: var(--acento);
                    }
                }
            }
        }
        > .c-detail__reviewitems__txt__list {
            li {
                margin-top: 9px;
                @media (--screen-sm-min) {
                    margin-top: 9px;
                }
            }
            @media (--screen-sm-min) {
                display: grid;
                grid-gap: 9px 10px;
                grid-template-columns: repeat(2, 1fr);
            }
            .c-detail__reviewitems__txt__item {
                grid-template-columns: 95px 1fr;
                &.descripcion {
                    grid-column: 1 / 3;
                    grid-template-columns: auto;
                }
            }
        }
        &__title {
            font-size: 19px;
            line-height: 21px;
            font-family: var(--third-font);
            color: var(--texto);
            @media (--screen-sm-min) {
                font-size: 21px;
                line-height: 23px;
            }
        }
        &__subtitle {
            font-size: 12px;
            line-height: 13px;
            font-weight: 600;
            font-family: var(--primary-font);
            color: var(--texto-secundario);
        }
        &__thumb {
            grid-column-start: 2;
            grid-row-start: 1;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            display: block;
        }
        &__txt {
            display: block;
            &__item {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 60px 1fr;
                strong {
                    font-size: 12px;
                    line-height: 13px;
                    font-weight: 600;
                    font-family: var(--primary-font);
                    color: var(--texto-secundario);
                }
                span {
                    font-size: 12px;
                    line-height: 13px;
                    font-weight: 400;
                    font-family: var(--primary-font);
                    color: var(--texto);
                }
            }
        }
        .c-line {
            margin-bottom: 8px;
        }
    }
    &__episodes {
        margin-bottom: 16px;
        &__title {
            color: var(--acento);
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            border-bottom: 1px solid var(--bordes);
            padding-bottom: 8px;
            @media (--screen-md-min) {
                font-size: 20px;
                padding-bottom: 10px;
            }
        }
        &__item {
            border-bottom: 1px solid var(--bordes);
            padding: 8px 0;
            &__epigraph {
                font-size: 14px;
                color: var(--texto-secundario);
                margin-bottom: 4px;
                @media (--screen-md-min) {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }
            &__title {
                font-size: 23px;
                font-weight: 600;
                font-family: var(--third-font);
                line-height: 1.09;
                letter-spacing: -0.23px;
                @media (--screen-md-min) {
                    font-size: 27px;
                    line-height: 1.07;
                    letter-spacing: -0.27px;
                }
            }
            @media (--screen-md-min) {
                padding: 10px 0;
            }
        }
    }
    &__top {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        & > p {
            font-family: var(--secondary-font);
            font-size: 19px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.26;
            letter-spacing: normal;
            text-align: left;
            color: var(--texto);
            @media (--screen-md-min) {
                font-size: 24px;
                line-height: normal;
            }
        }
        & > h2 {
            font-family: var(--third-font);
            font-size: 33px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.06;
            letter-spacing: normal;
            text-align: left;
            color: var(--acento);
            @media (--screen-md-min) {
                font-size: 41px;
                line-height: 1.05;
            }
        }
        & > * {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    .paragraph {
        font-family: var(--c-detail-body-text-font);
        font-size: var(--c-detail-body-text-font-size);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: var(--c-detail-body-text-line-height);
        letter-spacing: normal;
        text-align: left;
        color: var(--c-detail-body-text-color);
        &:first-child {
            &:first-letter {
                color: var(--c-detail-body-firstletter-color);
                text-transform: uppercase;
                font-size: 94px;
                line-height: 28px;
                letter-spacing: 1.26px;
                float: left;
                margin-top: 24px;
                margin-bottom: 20px;
                margin-right: 6px;
            }
        }
        & > a {
            color: var(--enlace);
            display: inline;
        }
        & > b {
            display: inline;
            a {
                color: var(--enlace);
            }
        }
        & > * {
            a {
                display: inline;
                color: var(--enlace);
            }
        }
        & > ol {
            li {
                counter-increment: my-counter;
                font-size: 19px;
                line-height: 1.26;
                margin-bottom: 11px;
                &:before {
                    content: counter(my-counter) ".";
                    margin-right: 7px;
                }
                a {
                    display: inline;
                    color: var(--c-detail-body-text-color);
                }
                @media (--screen-md-min) {
                    font-size: 24px;
                    line-height: normal;
                }
            }
        }
        & > ul {
            li {
                font-size: 19px;
                line-height: 1.26;
                margin-bottom: 11px;
                padding-left: 48px;
                position: relative;
                &:before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background-color: var(--fondo-intermedio);
                    border-radius: 50%;
                    display: inline-block;
                    position: absolute;
                    top: 9px;
                    left: 20px;
                }
                a {
                    display: inline;
                    color: var(--enlace);
                }
                @media (--screen-md-min) {
                    font-size: 24px;
                    line-height: normal;
                }
            }
        }
        .c-branded .c-article__txt > * a {
            display: inline;
            color: var(--texto-suave);
        }

        &[data-closed-content-panel] {
            background-color: yellow;
        }
    }
    .main_video {
        .video-player {
            &.player-inline {
                height: 528px;
                @media (--screen-sm-max) {
                    height: 426px;
                }
                @media (--screen-xs-max) {
                    height: 315px;
                }
            }
        }
    }
    .video-player {
        cursor: pointer;
        .embed-responsive {
            position: relative;
            display: none;
            height: 0;
            padding: 0;
            overflow: hidden;
        }
        .embed-responsive .embed-responsive-item,
        .embed-responsive iframe,
        .embed-responsive embed,
        .embed-responsive object,
        .embed-responsive video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
        &.player-inline {
            height: 349px;
            .embed-responsive {
                display: block;
                height: 100%;
            }
            @media (--screen-sm-max) {
                height: 528px;
            }
            @media (--screen-xs-max) {
                height: 315px;
            }
        }
        .c-detail__media__thumb {
            @media (--screen-xs-max) {
                background-color: var(--negro);
            }
            img {
                @media (--screen-xs-max) {
                    height: 315px;
                }
            }
        }
        &.format_16_9 {
            img {
                @media (--screen-xs-max) {
                    height: auto;
                }
            }
            .c-detail__media__icon {
                margin-top: -44px;
                @media (--screen-xs-max) {
                    margin-top: -41px;
                }
            }
            &.player-inline {
                height: auto;
                .embed-responsive {
                    height: auto;
                    padding-bottom: 0;
                    @media (--screen-xs-max) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        &.format_4_3 {
            .c-detail__media__icon {
                margin-top: -44px;
            }
        }
    }
    .content-free-html {
        text-align: center;
        display: block;
        .block-element.container-center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .podcast {
            iframe {
                margin: 0 auto 10px;
                display: block;
                border: 1px solid #eee;
                @media (--screen-sm-max) {
                    height: 180px;
                }
            }
        }
    }
    .content-free-html-mam {
        text-align: center;
        display: block;
        margin-bottom: 16px;
        background: #ffffff;
    }

    &__score {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        order: 3;
        .star {
            display: none;
            width: 20px;
            height: 20px;
            background-color: var(--acento);
            clip-path: polygon(
                50% 0%,
                61% 35%,
                98% 35%,
                68% 57%,
                79% 91%,
                50% 70%,
                21% 91%,
                32% 57%,
                2% 35%,
                39% 35%
            );
            position: relative;
            &:not(:last-child) {
                margin-right: 4px;
            }
            &-disable {
                display: none;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                &:before {
                    width: 10px;
                    height: 10px;
                    content: "";
                    display: block;
                    background-color: var(--fondo-gris);
                    margin: auto auto;
                    clip-path: polygon(
                        50% 0%,
                        61% 35%,
                        98% 35%,
                        68% 57%,
                        79% 91%,
                        50% 70%,
                        21% 91%,
                        32% 57%,
                        2% 35%,
                        39% 35%
                    );
                }
            }
            &-middle {
                display: none;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;
                &:before {
                    width: 10px;
                    height: 10px;
                    content: "";
                    display: block;
                    background-color: var(--fondo-gris);
                    margin: auto auto;
                    clip-path: polygon(
                        50% 0%,
                        61% 35%,
                        98% 35%,
                        68% 57%,
                        79% 91%,
                        50% 70%,
                        21% 91%,
                        32% 57%,
                        2% 35%,
                        39% 35%
                    );
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: var(--acento);
                    width: 50%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }
    /* Valoracion Estrellas */
    .r_0 {
        .c-detail {
            &__score {
                .solid {
                    display: none;
                }
                .star-disable {
                    display: flex;
                }
            }
        }
    }
    .r_0-5 {
        .c-detail {
            &__score {
                .solid {
                    display: none;
                }
                .star-disable {
                    &:nth-last-child(-n + 4) {
                        display: flex;
                    }
                }
                .star-middle {
                    display: flex;
                }
            }
        }
    }
    .r_1 {
        .c-detail {
            &__score {
                .solid {
                    &:first-child {
                        display: flex;
                    }
                }

                .star-disable {
                    &:nth-last-child(-n + 4) {
                        display: flex;
                    }
                }
            }
        }
    }
    .r_1-5 {
        .c-detail {
            &__score {
                .solid {
                    &:first-child {
                        display: flex;
                    }
                }

                .star-disable {
                    &:nth-last-child(-n + 3) {
                        display: flex;
                    }
                }

                .star-middle {
                    display: flex;
                }
            }
        }
    }
    .r_2 {
        .c-detail {
            &__score {
                .solid {
                    &:nth-child(-n + 2) {
                        display: flex;
                    }
                }

                .star-disable {
                    &:nth-last-child(-n + 3) {
                        display: flex;
                    }
                }
            }
        }
    }
    .r_2-5 {
        .c-detail {
            &__score {
                .solid {
                    &:nth-child(-n + 2) {
                        display: flex;
                    }
                }

                .star-disable {
                    &:nth-last-child(-n + 2) {
                        display: flex;
                    }
                }

                .star-middle {
                    display: flex;
                }
            }
        }
    }
    .r_3 {
        .c-detail {
            &__score {
                .solid {
                    &:nth-child(-n + 3) {
                        display: flex;
                    }
                }

                .star-disable {
                    &:nth-last-child(-n + 2) {
                        display: flex;
                    }
                }
            }
        }
    }
    .r_3-5 {
        .c-detail {
            &__score {
                .solid {
                    &:nth-child(-n + 3) {
                        display: flex;
                    }
                }

                .star-disable {
                    &:last-child {
                        display: flex;
                    }
                }

                .star-middle {
                    display: flex;
                }
            }
        }
    }
    .r_4 {
        .c-detail {
            &__score {
                .solid {
                    &:nth-child(-n + 4) {
                        display: flex;
                    }
                }

                .star-disable {
                    &:last-child {
                        display: flex;
                    }
                }
            }
        }
    }
    .r_4-5 {
        .c-detail {
            &__score {
                .solid {
                    &:nth-child(-n + 4) {
                        display: flex;
                    }
                }

                .star-disable {
                    display: none;
                }

                .star-middle {
                    display: flex;
                }
            }
        }
    }
    .r_5 {
        .c-detail {
            &__score {
                .solid {
                    display: flex;
                }

                .star-disable {
                    display: none;
                }
            }
        }
    }

    /* modificadores */
    & > .u-grid-col-span-3 {
        .c-detail__media {
            margin-bottom: 20px;
            .c-detail__media__thumb {
                margin-left: -16px;
                margin-right: -16px;

                @media (--screen-sm-min) {
                    margin-left: -20px;
                    margin-right: -20px;
                }
                @media (--screen-md-min) {
                    margin-left: 0;
                    margin-right: 0;
                }
                @media (--screen-sm-max) {
                    aspect-ratio: 16/8.5;
                }
            }
            @media (--screen-md-min) {
                margin-bottom: 0;
            }
        }
    }
    &-opinion {
        & > .u-grid-col-span-3 {
            border-bottom: 1px solid var(--fondo-intermedio);
        }
        @media (--screen-sm-min) {
            grid-template-columns: 1fr 620px 1fr;
        }
        .c-detail__title {
            text-align: center;
            font-style: italic;
        }
        .c-detail__subtitle {
            text-align: center;
        }
        .c-detail__toolbar {
            flex-direction: column;
        }
        .c-detail__signature {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (--screen-md-min) {
                align-items: center;
            }
        }
        .c-detail__author {
            &__txt {
                text-align: center;
                padding-left: 0;
                line-height: 22px;
                display: inherit;
                padding-right: 0;
            }
            &__thumb {
                display: block;
                width: 120px;
                height: 120px;
                margin: 0 auto 10px;
                picture {
                    margin-right: 0;
                }
                img {
                    width: 100%;
                }
            }
            &__blog {
                display: inline;
                font-size: 16px;
                text-transform: uppercase;
                padding-right: 0;
                @media (--screen-md-min) {
                    font-size: 20px;
                    padding-right: 5px;
                }
            }
            &__name {
                font-weight: 500;
                padding-right: 5px;
                color: var(--acento);
                text-transform: uppercase;
                font-size: 16px;
                padding-right: 0;
                @media (--screen-md-min) {
                    font-size: 20px;
                    display: inline;
                }
            }
        }
        .c-detail__info {
            text-align: center;
        }
        .c-detail__share {
            margin-left: inherit;
            margin-top: 20px;
        }
        .c-detail__body {
            border-top: none;
            .c-detail__despiece {
                background-color: var(--blanco);
                border-bottom: 0;
                padding: 0;
                .paragraph {
                    &:first-child {
                        &:first-letter {
                            color: var(--texto);
                            font-size: 24px;
                            margin: 0;
                            line-height: normal;
                        }
                    }
                }
            }
            @media (--screen-md-min) {
                padding-top: 0;
            }
            .paragraph {
                &:first-child {
                    &:first-letter {
                        color: var(--acento);
                    }
                }
            }
        }
        &.c-detail-letters {
            .paragraph:not(:last-child) {
                margin-bottom: 20px;
            }
            > .u-grid-col-span-3 {
                border-bottom: 0;
                @media (--screen-sm-min) {
                    grid-column-start: 2;
                }
            }
            .c-detail__epigraph {
                margin-bottom: 20px;
                @media (--screen-md-min) {
                    font-size: 20px;
                    line-height: normal;
                }
            }
            .c-detail__title {
                text-align: left;
                font-size: 33px;
                line-height: 35px;
                font-style: normal;
                margin-bottom: 20px;
                @media (--screen-sm-min) {
                    margin-bottom: 0;
                }
                @media (--screen-md-min) {
                    font-size: 39px;
                    line-height: 41px;
                }
            }
            .c-detail__letters_body {
                margin-bottom: 15px;
                .paragraph {
                    &:first-child {
                        &:first-letter {
                            float: none;
                            margin: inherit;
                            font-size: inherit;
                        }
                    }
                }
            }
            .c-detail__toolbar {
                @media (--screen-sm-min) {
                    flex-direction: row;
                    justify-content: space-between;
                    display: flex;
                }
            }
            .c-detail__signature {
                align-items: flex-start;
                margin-bottom: 10px;
                @media (--screen-sm-min) {
                    margin-bottom: 0;
                }
                .c-detail__author {
                    font-style: normal;
                    font-size: 14px;
                    color: var(--texto-secundario);
                    font-weight: 600;
                    @media (--screen-md-min) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &-live {
        .c-detail__toolbar {
            padding-bottom: 20px;
            border-bottom: 1px solid var(--bordes);
            .c-icon-print {
                display: none;
            }
        }
        .c-detail__body {
            .paragraph {
                &:first-child {
                    &:first-letter {
                        text-transform: none;
                        margin: 0;
                        padding: 0;
                        font-size: inherit;
                        line-height: inherit;
                        letter-spacing: inherit;
                        float: none;
                    }
                }
            }
        }
        .c-detail__media {
            margin-bottom: 20px;
        }
    }
    &-top {
    }
    &-gallery {
        & > .u-grid-col-span-3 {
            border-bottom: 1px solid var(--bordes);
        }
        @media (--screen-sm-min) {
            grid-template-columns: 1fr 620px 1fr;
        }
        @media (--screen-md-min) {
            grid-template-columns: 1fr 780px 1fr;
        }
    }
    &-podcast {
        & > .u-grid-col-span-3 {
            background-color: var(--fondo);
            margin: -16px -16px 0 -16px;
            padding: 16px;
            @media (--screen-md-min) {
                margin: -20px -30px 0 -30px;
                padding: 20px 30px;
            }
        }
        .c-detail__epigraph {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            strong {
                font-weight: 600;
            }
            span:before {
                content: "- ";
                padding-left: 5px;
            }
            &:before {
                content: "";
                background: url(../images/icon/podcast-red.svg) no-repeat center
                    center;
                width: 20px;
                height: 20px;
                display: block;
                margin-right: 10px;
            }
            @media (--screen-md-min) {
                font-size: 20px;
                font-weight: normal;
            }
        }
        .c-detail__title {
            font-size: 33px;
            @media (--screen-md-min) {
                font-size: 39px;
                font-weight: 600;
            }
        }
        .c-detail__body {
            .paragraph {
                &:first-child {
                    &:first-letter {
                        float: none;
                        margin: inherit;
                        font-size: inherit;
                    }
                }
            }
        }
        .podcast {
            iframe {
                margin: 0 auto 10px;
                display: block;
                border: 1px solid #eee;
                @media (--screen-sm-max) {
                    height: 180px;
                }
            }
        }
    }
    &-video {
        .c-detail__body {
            .paragraph {
                &:first-child {
                    &:first-letter {
                        float: none;
                        margin: inherit;
                        font-size: inherit;
                    }
                }
            }
        }
    }
    &-error {
        .c-detail__media__thumb {
            max-width: 220px;
            margin: 0 auto;
        }
        .c-detail__body {
            border-top: none;
            padding-bottom: 40px;
            .paragraph {
                &:first-child {
                    &:first-letter {
                        float: none;
                        margin: inherit;
                        font-size: inherit;
                    }
                }
            }
            & > * {
                text-align: center;
            }
            .buscador {
                background: var(--fondo-pagina);
                padding: 16px;
                form {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    input[type="text"] {
                        position: relative;
                        z-index: 1;
                        width: calc(100% - 95px);
                        height: 44px;
                        padding: 10px 13px;
                        color: var(--texto);
                        background: var(--blanco);
                        text-align: left;
                        font-size: 15px;
                    }
                    input::placeholder {
                        color: var(--texto);
                    }
                    input[type="reset"] {
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: var(--fondo);
                        color: var(--texto);
                        opacity: 0;
                        z-index: 2;
                        font-size: 14px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        position: absolute;
                        right: 80px;
                        transition: opacity 0.3s;
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--blanco);
                        text-transform: uppercase;
                        padding: 14px 13px 13px;
                        border-radius: 5px;
                        font-size: 14px;
                        cursor: pointer;
                        background-color: var(--enlace);
                    }
                    > div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        margin-top: 20px;
                        p {
                            font-size: 16px;
                            color: var(--texto-suave);
                            font-weight: 600;
                        }
                        input[type="radio"] {
                            margin: 0 5px 0 15px;
                        }
                        label {
                            font-size: 16px;
                            color: var(--texto-secundario);
                        }
                    }
                }
                @media (--screen-sm-min) {
                    padding: 20px;
                }
            }
        }
        @media (--screen-sm-min) {
            grid-template-columns: 1fr 620px 1fr;
        }
    }
    &-branded {
        @media (--screen-sm-min) {
            grid-template-columns: 1fr 620px 1fr;
        }
        .article-branded {
            display: block;
            padding: 16px;
            background: var(--fondo-beige);
            margin-bottom: 20px;
            strong,
            a {
                color: var(--texto);
                font-size: 14px;
                font-weight: 600;
                @media (--screen-sm-min) {
                    font-size: 16px;
                }
            }
        }
        .c-detail {
            &__subtitle {
                font-size: 24px;
                @media (--screen-md-min) {
                    font-size: 27px;
                }
            }
            &__info {
                display: inline-block;
                margin-bottom: 0;
                font-size: 16px;
            }
            &__share {
                display: inline-flex;
                float: right;
            }
            &__body {
                padding-bottom: 20px;
                margin-bottom: 0;
                .paragraph {
                    font-family: var(--primary-font);
                    font-size: 16px;
                    @media (--screen-sm-min) {
                        font-size: 20px;
                    }
                    &:first-child {
                        &:first-letter {
                            color: inherit;
                            text-transform: inherit;
                            font-size: inherit;
                            line-height: inherit;
                            letter-spacing: inherit;
                            float: none;
                            margin: 0;
                        }
                    }
                }
                & > h3 {
                    font-family: var(--primary-font);
                    font-size: 19px;
                    @media (--screen-sm-min) {
                        font-size: 24px;
                    }
                }
            }
        }
        &-special {
            margin-top: -16px;
            @media (--screen-md-min) {
                margin-top: -22px;
            }
            .main-image {
                img {
                    /*object-fit: initial;*/
                }
                @media (--screen-md-min) {
                    width: 1000px;
                    margin: 0 -30px;
                }
            }
            .c-detail__media.composite_image {
                figcaption {
                    display: block;
                }
                @media (--screen-md-min) {
                    width: 780px;
                    margin: 0 -80px;
                }
            }
            .article-branded {
                .c-branded__epigraph {
                    text-transform: none;
                    margin-bottom: 0;
                }
            }
        }
    }
    &-schedule {
        &__date {
            text-transform: uppercase;
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            text-align: left;
            color: var(--acento);
            margin-bottom: 20px;
            margin-top: 20px;
            a {
                color: var(--acento);
            }
        }
        &__item {
            padding-bottom: 20px;
            border-bottom: 1px solid var(--bordes);
            &__title {
                font-size: 18px;
                line-height: 25px;
                font-weight: 600;
                color: var(--texto);
                margin-bottom: 7px;
                &::before {
                    background-color: #c51126;
                    border-radius: 50%;
                    content: "";
                    height: 8px;
                    width: 8px;
                    display: inline-block;
                    margin-right: 6px;
                }
            }
            &__location {
                font-size: 16px;
                line-height: 1.56;
                color: var(--texto-secundario);
                margin-bottom: 7px;
            }
            &__txt {
                font-size: 18px;
                line-height: 21px;
                color: var(--texto-suave);
                font-family: var(--secondary-font);
            }
        }
        .content-show__more {
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 2.14;
            color: var(--acento);
            text-align: center;
            font-family: var(--primary-font);
            cursor: pointer;
            a {
                color: var(--acento);
            }
        }
    }
    &-chronicle {
        > .u-grid-col-span-3 {
            @media (--screen-xs-max) {
                .c-detail__media {
                    margin-bottom: 10px;
                }
            }
        }
        .c-detail {
            &__address {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 4px;
                > .c-detail__author__txt {
                    @media (--screen-sm-min) {
                        display: flex;
                        flex-direction: row;
                    }
                }
                @media (--screen-md-min) {
                    margin-top: 12px;
                    margin-left: 80px;
                    margin-right: 80px;
                }
            }
            &__author {
                &__txt {
                    padding-top: 0;
                    @media (--screen-xs-max) {
                        padding-right: 0;
                    }
                }
                &__name {
                    font-size: 23px;
                    line-height: 25px;
                    font-family: var(--third-font);
                    letter-spacing: -0.23px;
                    @media (--screen-sm-min) {
                        font-size: 24px;
                        line-height: 27px;
                    }
                }
                &__job__position {
                    margin-top: 3px;
                    font-size: 11px;
                    line-height: 13px;
                }
            }
            &__epigraph {
                font-size: 23px;
                line-height: 25px;
                font-family: var(--third-font);
                letter-spacing: -0.23px;
                padding-right: 5px;
                @media (--screen-sm-min) {
                    font-size: 24px;
                    line-height: 27px;
                }
            }
            &__title {
                margin-bottom: 8px;
                font-size: 37px;
                line-height: 39px;
                font-style: italic;
                letter-spacing: -0.23px;
                @media (--screen-sm-min) {
                    font-size: 40px;
                    line-height: 46px;
                }
                @media (--screen-md-min) {
                    margin-left: 80px;
                    margin-right: 80px;
                }
            }
            &__subtitle {
                h2 {
                    margin: 0;
                }
                @media (--screen-md-min) {
                    margin-bottom: 10px;
                    margin-left: 80px;
                    margin-right: 80px;
                }
            }
            &__toolbar {
                @media (--screen-sm-min) {
                    margin-bottom: 15px;
                }
                @media (--screen-md-min) {
                    margin-left: 80px;
                    margin-right: 80px;
                }
            }
            &__info {
                font-size: 14px;
                line-height: 16px;
                &__locate {
                    display: none;
                }
            }
            &__media {
                margin-bottom: 10px;
                &__txt {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
        @media (--screen-sm-min) {
            grid-row-gap: 10px;
        }
    }
    &__case-02 {
        .u-grid {
            &-col-span {
                &-3 {
                    .c-detail__media {
                        .c-detail__media__thumb {
                            @media (--screen-sm-max) {
                                aspect-ratio: 16/9;
                            }
                        }
                    }
                    .main_video {
                        .video-player {
                            .c-detail__media__thumb {
                                img {
                                    height: auto;
                                }
                            }
                        }
                    }
                    @media (--screen-md-min) {
                        grid-column: auto / span 2;
                    }
                }
            }
            &-sticky-add {
                @media (--screen-md-min) {
                    grid-row: 1 / span 3;
                    grid-column: 3 / span 2;
                }
            }
        }
        .main_video {
            .video-player {
                &.player-inline {
                    height: 342px;
                    @media (--screen-sm-max) {
                        height: 462px;
                    }
                    @media (--screen-xs-max) {
                        height: 211px;
                    }
                }
            }
        }
    }
    @media (--screen-sm-only) {
        &.u-grid {
            display: initial;
            &-columns-3 {
                grid-template-columns: none;
                .u-grid-col {
                    &-span-3 {
                        grid-column: none;
                    }
                    &-span-2 {
                        grid-column: none;
                    }
                }
            }
        }
    }

    &__video-main {
        background-color: #030303;
        margin: -16px -16px 20px;
        padding: 0 0 16px;
        @media (--screen-md-min) {
            margin: -30px -30px 20px;
            padding: 30px;
        }
        .c-detail {
            &__media {
                &__credit {
                    color: #fff;
                }
                &__author {
                    color: #fff;
                }
            }
            &__epigraph {
                color: #fff;
                a {
                    color: #fff;
                }
            }
            &__title {
                color: #fff;
            }
            &__subtitle {
                color: #fff;
                margin-bottom: 0;
            }
        }
        &.u-grid-col-span-3 {
            .c-detail__media {
                .c-detail__media__thumb {
                    margin-left: 0;
                    margin-right: 0;
                    position: relative;
                    @media (--screen-md-min) {
                        margin-left: -30px;
                        margin-right: -30px;
                        margin-top: -20px;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        z-index: 2;
                        background-image: linear-gradient(
                            75deg,
                            rgba(0, 0, 0, 1),
                            rgba(0, 0, 0, 0) 50%
                        );
                    }
                }
                .c-detail__media__txt {
                    padding: 10px 16px 0;
                    border-bottom: none;
                    @media (--screen-md-min) {
                        margin-bottom: 20px;
                    }
                }
                .c-detail__media__icon {
                    z-index: 3;
                }
            }
        }
    }
    &__video-main-details {
        padding: 0 16px;
    }

    &__adult-consent {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        z-index: 2;
        background: rgba(0, 0, 0, 0.75);
        align-items: center;
        justify-content: center;
        .adult-consent-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            text-align: center;
            @media (--screen-sm-min) {
                width: 380px;
            }
        }
        .adult-consent-title {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            line-height: 1.18;
            letter-spacing: -0.22px;
            margin: 10px 0;
            @media (--screen-sm-min) {
                margin: 15px 0;
                font-size: 22px;
            }
        }
        .adult-consent-btn {
            cursor: pointer;
            margin-bottom: 15px;
        }
        &.hidden {
            display: none;
        }
    }
}
#SunMediaIntext {
    background-color: var(--fondo-gris);
    height: 620px;
}
#startContent {
    #SunMediaIntext {
        background-color: var(--fondo-gris);
        height: 485px;
        margin-bottom: 20px;
        @media (--screen-sm-only) {
            height: 591px;
        }
    }
}

.flex-post {
    display: flex;
    flex-direction: column;
}

.flex-pinned {
    order: -1;
}

.flex-default-position {
    order: 1;
}

.o-container.hide-author-image {
    .c-detail__signature {
        display: none;
    }
}

body.app .c-icon-print {
    display: none;
}

body.article-video-page {
    /*.o-section-head {
        .c-nav {
            &__group {
                background-color: #030303;
            }
            &__links__item {
                color: #fff;
            }
        }
    }*/
    .o-section-head{
        .c-nav__group__link[data-type="menu"] {
            color: #fff;
        }
        > .o-container .c-nav__group.c-nav__group__horizontal {
            @media (--screen-md-min) {
                background: #fff;
            }
            .c-nav__links__item {
                color: #313131;
            }
        }
    }
}
