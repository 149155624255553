/*
  Extends
*/

/*** button ***/
%ext__primary-button {
  background-color: var(--enlace);
  border-radius: 6px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: min-content;
  height: 44px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--blanco);
  cursor: pointer;
  transition: box-shadow .3s;
  font-family: var(--profile-font);
  padding: 12px 16px 14px;
  span{
    &:not(.sr-only){
      display: block;
    }
  }
  .icon{
    display: none;
  }
  &:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  &:active{
    border: solid 1px var(--enlace-oscuro);
  }
  &:focus{
    box-shadow: 0 0 4px 0 var(--enlace);
    background-color: var(--enlace);
  }
  &:disabled{
    background-color: var(--bordes);
    box-shadow: none;
  }
  @media (--screen-md-min) {
    padding: 10px 16px 10px 8px;
    .icon{
      display: block;
    }
  }
}
%ext__primary-button-rounded {
  background-color: var(--enlace);
  border-radius: 50%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 44px;
  height: 44px;
  color: var(--blanco);
  cursor: pointer;
  transition: box-shadow .3s;
  font-family: var(--profile-font);
  &:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  &:active{
    border: solid 1px var(--enlace-oscuro);
  }
  &:focus{
    box-shadow: 0 0 4px 0 var(--enlace);
    background-color: var(--enlace);
  }
  &:disabled{
    background-color: var(--bordes);
    box-shadow: none;
  }
}
%ext__secundary-button {
  border: solid 2px var(--enlace);
  background-color: var(--blanco);
  border-radius: 6px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: min-content;
  height: 44px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--enlace);
  cursor: pointer;
  transition: box-shadow .3s;
  font-family: var(--profile-font);
  padding: 12px 16px 14px;
  span{
    &:not(.sr-only){
      display: block;
    }
  }
  .icon{
    display: none;
  }
  &:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  &:active{
    border: solid 2px var(--enlace-oscuro);
  }
  &:focus{
    box-shadow: 0 0 4px 0 var(--enlace);
    background-color: var(--enlace);
  }
  &:disabled{
    color: var(--bordes);
    box-shadow: none;
    border-color: var(--bordes);
    background-color: var(--blanco);
  }
  @media (--screen-md-min) {
    padding: 10px 16px 10px 8px;
    .icon{
      display: block;
    }
  }
}
%ext__secundary-button-rounded {
  background-color: var(--enlace);
  border-radius: 50%;
  border: solid 2px var(--enlace);
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 44px;
  height: 44px;
  color: var(--blanco);
  cursor: pointer;
  transition: box-shadow .3s;
  font-family: var(--profile-font);

  &:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  &:active{
    border: solid 1px var(--enlace-oscuro);
  }
  &:focus{
    box-shadow: 0 0 4px 0 var(--enlace);
    background-color: var(--enlace);
  }
  &:disabled{
    color: var(--bordes);
    box-shadow: none;
    border-color: var(--bordes);
    background-color: var(--blanco);
  }
}

/*** icon media ***/
%ext__icon-media {
    padding: 6px 16px 6px 8px;
    border-radius: 22px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--c-icon-multimedia-bg);
    color: var(--c-icon-multimedia-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
}

%ext__mini-icon-media {
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 2px var(--c-icon-multimedia-border);
    background-color: var(--c-icon-multimedia-bg);
    width: 20px;
    height: 20px;
    color: var(--c-icon-multimedia-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/*** títulos ***/
%ext__title-1{
    --font-size:var(--ext__title-1-font-size);
    --line-height:var(--ext__title-1-line-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--third-font);
    font-weight: 600;
    letter-spacing: -0.23px;
    color: var(--texto);
    @media (--screen-sm-min){
        letter-spacing: -0.27px;
    }
}
%ext__title-2{
    --font-size:var(--ext__title-2-font-size);
    --line-height:var(--ext__title-2-line-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--third-font);
    font-weight: 600;
    color: var(--texto);
}
%ext__title-3{
    --font-size:var(--ext__title-3-font-size);
    --line-height:var(--ext__title-3-line-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--third-font);
    font-weight: 600;
    color: var(--texto);
}
%ext__title-4{
    --font-size:var(--ext__title-4-font-size);
    --line-height:var(--ext__title-4-line-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--third-font);
    font-weight: 600;
    color: var(--texto);
}
%ext__title-5{
    --font-size:var(--ext__title-5-font-size);
    --line-height:var(--ext__title-5-line-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--third-font);
    font-weight: 600;
    color: var(--texto);
}
%ext__title-6{
    --font-size:var(--ext__title-6-font-size);
    --line-height:var(--ext__title-6-line-height);
    font-size: var(--font-size);
    line-height: var(--line-height);
    font-family: var(--third-font);
    font-weight: 600;
    color: var(--texto);
}


/*** subtítulos ***/
%ext__subtitle-1{
    font-family: var(--secondary-font);
    font-size: 14px;
    @media (--screen-sm-min){
        font-size: 16px;
    }
}
%ext__subtitle-2{
    font-family: var(--secondary-font);
    font-size: 11px;
    @media (--screen-sm-min){
        font-size: 14px;
    }
}
%ext__subtitle-3{
    font-family: var(--secondary-font);
    font-size: 17px;
    @media (--screen-sm-min){
        font-size: 18px; /* antes 21px;*/
        line-height: 22px;
    }
}
%ext__subtitle-4{
    font-family: var(--secondary-font);
    font-size: 16px;
    @media (--screen-sm-min){
        font-size: 20px;
    }
}
%ext__subtitle-5{
    font-family: var(--secondary-font);
    font-size: 19px;
    line-height: 1.26;
    @media (--screen-sm-min){
        font-size: 21px; /*  antes 24px; */
        line-height: 27px;  /*antes normal*/;
    }
}
%ext__subtitle-6{
    font-family: var(--secondary-font);
    font-size: 21px;
    @media (--screen-sm-min){
        font-size: 25px; /*  antes 27px; */
        line-height: 30px;
    }
}

%ext__no-padding{
  margin-left: -16px;
  margin-right: -16px;
  @media (--screen-md-min) {
    margin-left: 0;
    margin-right: 0;
  }
}

%ext__static-page{
    padding-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid var(--bordes);
    & > p {
        font-family: var(--secondary-font);
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--texto);
        a {
            color: var(--enlace);
            display: inline;
        }
        @media (--screen-md-min) {
            font-size: 21px;
            line-height: normal;
        }
    }
    & > h2{
        font-family:  var(--third-font);
        font-size: 23px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: normal;
        text-align: left;
        color: var(--texto);
        @media (--screen-md-min) {
            font-size: 27px;
            line-height: 1.07;
        }
    }
    & > h3{
        font-family: var(--profile-font);
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--texto-secundario);
        display: block;
        @media (--screen-md-min) {
            font-size:20px;
        }
    }
    ol {
        li {
            counter-increment: my-counter;
            font-family: var(--secondary-font);
            font-size: 17px;
            line-height: normal;
            margin-bottom: 11px;
            &:before {
                content: counter(my-counter) ".";
                margin-right: 7px;
            }
            a {
                display: inline;
                color: var(--enlace);
            }
            @media (--screen-md-min) {
                font-size: 21px;
                line-height: normal;
            }
        }
    }
    ul {
        li {
            font-family: var(--secondary-font);
            font-size: 17px;
            line-height: normal;
            margin-bottom: 11px;
            &:before {
                content: '';
                width: 10px;
                height: 10px;
                background-color: var(--fondo-intermedio);
                border-radius: 50%;
                margin-right: 16px;
                display: inline-block;
            }
            a {
                display: inline;
                color: var(--enlace);
            }
            @media (--screen-md-min) {
                font-size: 21px;
                line-height: normal;
            }
        }
    }
    & > * {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

/* botones */
%ext__btn-cta{
    width: 167px;
    height: 40px;
    margin: 0;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: var(--color-01,#a6245e);
    font-family: var(--profile-font);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.14px;
    color:var(--white);
    text-transform: uppercase;
}
%ext__btn-play{
    width: 55px;
    height: 30px;
    margin: 0;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-01,#a6245e);
    color:var(--white);
}
%ext__btn-play-last{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    text-transform: uppercase;
    color:var(--white);
    cursor: pointer;
    svg{
        width: 55px;
        height: 30px;
        margin: 0;
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--color-01,#a6245e);
        color:var(--white);
    }
    span{
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        width: 80px;
    }
}
