.c-detail {
    .iframe-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 5;
    }
    &__media {
    }

    &__closed {
        display: grid;
        gap: 16px;
        padding: 32px 0 16px;
        border-top: solid 4px var(--bordes);
        border-bottom: solid 1px var(--bordes);
        text-align: center;
        position: relative;
        @media (--screen-md-min) {
            gap: 20px;
            padding: 40px 0 20px;
            max-width: 620px;
            margin: 0 auto;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 174px;
            background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0),
                    var(--blanco)
            );
            top: -178px;
            left: 0;
            right: 0;
        }

        &-title {
            font-family: var(--third-font);
            font-size: 33px;
            font-weight: 600;
            line-height: 1.06;
            letter-spacing: -0.23px;
            color: var(--texto);
        }

        &-subtitle {
            font-family: var(--third-font);
            font-size: 23px;
            font-weight: 600;
            line-height: 1.09;
            letter-spacing: -0.23px;
            color: var(--texto);

            &-red {
                color: var(--acento);
            }

            a {
                display: inline;
                color: var(--enlace);
            }

            @media (--screen-md-min) {
                font-size: 24px;
            }
        }

        &-txt {
            font-family: var(--secondary-font);
            font-size: 17px;
            line-height: 1.29;
            color: var(--texto);
            @media (--screen-md-min) {
                font-size: 18px;
            }
        }

        .c-form__btn {
            display: inline-block;
            width: auto;
        }

        &-info {
            font-family: var(--primary-font);
            font-size: 14px;
            line-height: 1.2;
            color: var(--enlace);
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: "";
                background-image: url("../images/icon/iconos_info.svg");
                width: 18px;
                height: 18px;
                background-repeat: no-repeat;
                background-position: center;
                margin-left: 6px;
            }
        }
    }

    &__closed-moreinfo {
        display: grid;
        gap: 16px;
        padding: 44px 24px 24px;
        background-color: var(--fondo-gris2);
        text-align: center;
        margin-bottom: 32px;
        @media (--screen-md-min) {
            gap: 20px;
            padding: 44px 20px 20px;
        }

        &_title {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-family: var(--third-font);
                font-size: 23px;
                font-weight: 600;
                line-height: 1.09;
                letter-spacing: -0.27px;
                color: var(--acento);
                margin-right: 8px;
                @media (--screen-md-min) {
                    font-size: 24px;
                    line-height: 1.13;
                }
            }

            a {
                width: 168px;
                height: 18px;
            }
        }

        &_txt-container {
            display: grid;
            gap: 4px;
            @media (--screen-md-min) {
                gap: 5px;
            }
        }

        &_txt {
            font-family: var(--secondary-font);
            font-size: 17px;
            line-height: 1.29;
            color: var(--texto-suave);
            @media (--screen-md-min) {
                font-size: 18px;
                line-height: 1.22;
            }
        }
    }

    &__closed-bluebox {
        position: absolute;
        display: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        padding: 16px;
        box-shadow: 0 1px 4px 0 var(--negro-transparente);
        border: solid 1px var(--auxiliares-informacion);
        background-color: var(--fondo-informacion);
        z-index: 9;
        max-width: 342px;

        &.active {
            display: flex;
        }

        @media (--screen-md-min) {
            gap: 10px;
            padding: 20px;
        }

        &_info-icon {
            flex-grow: 0;
        }

        &_text-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &_title {
            font-family: var(--primary-font);
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            color: var(--auxiliares-informacion);
        }

        &_txt {
            font-family: var(--primary-font);
            font-size: 14px;
            line-height: 1.2;
            color: var(--texto-suave);
        }

        .c-form__btn {
            display: inline-block;
            padding: 8px 16px 7px;
            width: auto;
        }

        .c-icon-close {
            position: absolute;
            right: 0;
            top: 0;
        }

        &__widget {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 342px;
            width: 100%;
            padding-right: 30px;
            @media (--screen-md-min) {
                padding-right: 30px;
            }
        }

    }
}

.c-detail__gallery + .c-detail__closed {
    margin-top: -16px;
    @media (--screen-md-min) {
        margin-top: -22px;
    }
}

.c-detail-gallery {
    .c-detail__closed:before {
        @media (--screen-md-min) {
            left: -80px;
            width: 780px;
        }
    }
}

.c-detail__comments + .c-detail__closed {
    margin-top: -20px;
    border-top: none;

    &:before {
        display: none;
    }
}

.c-icon-close {
    background-image: url(../../images/icon/iconos_aspa-black.svg);
    background-size: 24px;
    display: flex;
    cursor: pointer;
}
