.c-form {
  /*common*/
  &__text,
  &__select {
    width: 100%;
    margin: 0 auto;
    @media (--screen-md-min) {
      max-width: 420px;
    }
  }

  /* input text */
  &__text {
    display: block;
    margin-bottom: 20px;
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="date"],
    input[type="number"] {
      border-radius: 0;
      background-color: var(--white);
      border: none;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      display: block;
      padding: 0;
      width: 100%;
      height: 40px;
      margin: 0;
      text-indent: 0;
      color: var(--grey-05);
      font-family: var(--profile-font);
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid var(--grey-06);
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
        color: var(--grey-05);
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
      }
      &:focus {
        /*outline: none;*/
        color: #1f1f1f;
      }
      &.error {
        &:focus {
        }
      }
    }
    span {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: var(--grey-03);
      font-family: var(--profile-font);
      text-transform: uppercase;
      letter-spacing: 0.35px;
    }
    textarea {
      border-radius: 0;
      background-color: var(--white);
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      display: block;
      padding: 0;
      width: 100%;
      height: 200px;
      margin: 0;
      color: var(--black);
      font-family: var(--profile-font);
      font-size: 14px;
      line-height: 24px;
      border-bottom: 1px solid var(--grey-06);
      text-indent: 10px;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
        color: var(--grey-05);
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
      }
      &:focus {
        /*outline: none;*/
        color: #1f1f1f;
      }
      &.error {
        &:focus {
        }
      }
    }
  }
  /* input file */
  &__file {
    position: relative;
    display: inline-block;
    height: 40px;
    margin-bottom: 20px;
    width: 100%;
    .txt {
      background-color: var(--white);
      border-radius: 19px;
      border: 1px solid var(--color-01);
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.14px;
      text-align: left;
      color: var(--color-01);
      font-family: var(--profile-font);
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      content: "Examinar";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 168px;
      height: 38px;
      &:before {
        content: "";
        margin-left: -5px;
        width: 16px;
        height: 16px;
        display: block;
        background-color: var(--color-01);
        clip-path: polygon(
          0 45%,
          45% 45%,
          45% 0,
          55% 0,
          55% 45%,
          100% 45%,
          100% 55%,
          55% 55%,
          55% 100%,
          45% 100%,
          45% 55%,
          0 55%
        );
        transition: all 0.3s;
        margin-right: 12px;
      }
    }
    input[type="file"] {
      height: 100%;
      width: 100%;
      color: var(--grey-05);
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      margin-top: 9px;
      &::file-selector-button {
        background: none;
        border: none;
        font-size: 0;
        margin-right: 15px;
        width: 168px;
      }
    }
  }
  &__icon {
    input[type="text"] {
      background-image: url(/images/icon/play.svg);
      background-size: 9px 11px;
      background-repeat: no-repeat;
      background-position: 80px 15px;
    }
  }

  /* select */
  &__select {
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
    label {
      display: block;
      margin-bottom: 8px;
    }
    select {
      border: none;
      height: 40px;
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      appearance: none;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      margin: 0;
      background-size: 20px;
      background: #fff url(/images/icon/angle.svg) no-repeat right center;
      color: var(--grey-05);
      line-height: 24px;
      font-family: var(--profile-font);
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid var(--grey-06);
      text-indent: 10px;
      &:focus {
        /* border-color: #06f3f9;*/
        /*outline: none;*/
        color: var(--grey-03);
      }
      @media (--screen-sm-min) {
        font-size: 18px;
      }
    }
    select::-ms-expand {
      display: none;
    }
    span {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: var(--grey-03);
      font-family: var(--profile-font);
      text-transform: uppercase;
      letter-spacing: 0.35px;
    }
  }

  /* radio */
  &__radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    line-height: 1;
    position: relative;
    padding-bottom: 20px;
    cursor: pointer;
    width: 100%;
    input[type="radio"] {
      display: none;
      &:checked {
        & + .fake-radio {
          border: 2px solid var(--color-01);
          &:before {
            content: "";
            width: 12px;
            height: 12px;
            background-color: var(--color-01);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
    }
    .fake-radio {
      position: relative;
      display: block;
      background: var(--white);
      border: 2px solid var(--grey-06);
      cursor: pointer;
      z-index: 10;
      top: 0;
      left: 0;
      height: 20px;
      vertical-align: top;
      max-width: 20px;
      width: 20px;
      margin-right: 10px;
      border-radius: 50px;
    }
    p {
      color: var(--grey-05);
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      a {
        color: var(--black);
        display: inline;
        text-decoration: underline;
      }
      @media (--screen-sm-min) {
        font-size: 16px;
      }
    }
    @media (min-width: 768px) {
      max-width: inherit;
      justify-content: center;
      width: max-content;
    }
  }

  /* check */
  &__check {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    line-height: 1;
    position: relative;
    padding-bottom: 20px;
    width: 100%;
    input[type="checkbox"] {
      display: none;
      &:checked {
        & + .fake-check {
          &:before {
            content: "";
            width: 4.7px;
            height: 7.7px;
            margin-left: 0;
            transform: rotate(45deg);
            border-bottom: 2px solid var(--color-01);
            border-right: 2px solid var(--color-01);
            display: block;
            position: absolute;
            top: 5px;
            left: 8px;
            z-index: 1;
          }
        }
      }
    }
    .fake-check {
      &:after {
        content: "";
        display: block;
        background: none;
        border: 3px solid var(--bordes);
        cursor: pointer;
        position: relative;
        z-index: 10;
        top: 0;
        left: 0;
        height: 16px;
        vertical-align: top;
        max-width: unset;
        width: 16px;
        margin-right: 10px;
        border-radius: 4px;
      }
    }
    p:not(.c-form__error-text) {
      color: var(--black);
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.18px;
      line-height: 26px;
      a {
        color: var(--color-02);
        display: inline;
      }
    }
  }

  /*check-button */
  &__check-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0 20px;
    border-radius: 19px;
    width: 159px;
    height: 38px;
    border: 1px solid var(--color-01);
    color: var(--color-01);
    input[type="checkbox"] {
      display: none;
      &:checked {
        & + .icon {
          color: var(--white);
        }
      }
    }
    &:has(input:checked) {
      background-color: var(--color-01);
    }
  }

  /*switch*/
  &__switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__text {
      width: 100%;
      max-width: calc(100% - 36px);
      p {
        height: 40px;
        font-family: var(--profile-font);
        font-size: 14px;
        line-height: 40px;
        @media (--screen-sm-min) {
          font-size: 18px;
        }
      }
    }
    &__label {
      position: relative;
      display: inline-block;
      width: 36px;
      height: 20px;
      input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(46, 46, 46, 0.6);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
        &.round {
          border-radius: 20px;
          &:before {
            border-radius: 50%;
          }
        }
      }
      input:checked + .slider {
        background-color: var(--color-01);
      }
      input:focus + .slider {
        box-shadow: 0 0 1px var(--color-01);
      }
      input:checked + .slider:before {
        transform: translateX(15px);
      }
    }
  }

  /*btn*/
    &__btn {
        width: 100%;
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 20px 16px;
        border-radius: 4px;
        background-color: var(--enlace);
        font-family: var(--primary-semibold-font);
        font-size: 11px;
        line-height: 1.2;
        color: var(--blanco);
        cursor: pointer;
        text-transform: uppercase;

        &-fine {
            background-color: var(--white);
            color: var(--enlace);
            border: 2px solid var(--enlace);
            padding: 13px 20px;
        }

        &-simple {
            background-color: var(--white);
            color: var(--enlace);
            border: none;
        }

        &-remove {
            background-color: var(--white);
            color: var(--bordes);
            border: 2px solid var(--bordes);
            padding: 13px 20px;
        }

        &-delete {
            color: var(--auxiliares-error);
        }

        &-small {
            width: auto;
        }

        &-fixed130 {
            width: 100%;
            @media (--screen-md-min) {
                min-width: 130px;
            }
            padding: 15px 5px 16px;
        }

        &-fixed163 {
            width: 163px;
            @media (--screen-md-min) {
                min-width: 163px;
            }
            padding: 16px 20px 14px;
        }

        &-low {
            padding: 6px 20px;
        }

        &:hover {
            opacity: 0.8;
        }
    }

  /*recover*/
  &__recover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    p {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.12px;
      a {
        color: var(--color-02);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.12px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .c-form__check {
      .fake-check {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.12px;
      }
    }
  }

  /* list */
  &-list {
    padding: 20px 0;
    input[type="checkbox"] {
      display: none;
    }
    &__item {
      cursor: pointer;
      position: relative;
      padding: 0 20px;
      border-radius: 19px;
      height: 38px;
      border: 1px solid var(--color-01);
      color: var(--color-01);
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
      }
      .txt {
        font-size: 14px;
        font-weight: 600;
      }
      .check {
        margin-left: -5px;
        width: 16px;
        height: 16px;
        display: block;
        background-color: var(--color-01);
        clip-path: polygon(
          0 45%,
          45% 45%,
          45% 0,
          55% 0,
          55% 45%,
          100% 45%,
          100% 55%,
          55% 55%,
          55% 100%,
          45% 100%,
          45% 55%,
          0 55%
        );
        transition: all 0.3s;
      }
    }
    /* cat-1 */
    #cat-1-item-1:checked ~ label[for="cat-1-item-1"],
    #cat-1-item-2:checked ~ label[for="cat-1-item-2"],
    #cat-1-item-3:checked ~ label[for="cat-1-item-3"],
    #cat-1-item-4:checked ~ label[for="cat-1-item-4"],
    #cat-1-item-5:checked ~ label[for="cat-1-item-5"],
    #cat-1-item-6:checked ~ label[for="cat-1-item-6"],
    #cat-1-item-7:checked ~ label[for="cat-1-item-7"],
    /* cat-2 */
    #cat-2-item-1:checked ~ label[for="cat-2-item-1"],
    #cat-2-item-2:checked ~ label[for="cat-2-item-2"],
    #cat-2-item-3:checked ~ label[for="cat-2-item-3"],
    #cat-2-item-4:checked ~ label[for="cat-2-item-4"],
    #cat-2-item-5:checked ~ label[for="cat-2-item-5"] {
      background-color: var(--color-01);
      color: var(--white);
      border-color: var(--color-01);
      p {
        gap: 10px;
      }
      .check {
        --borderWidth: 2px;
        --height: 16px;
        --width: 6px;
        margin-top: -5px;
        margin-left: 0;
        --borderColor: var(--white);
        transform: rotate(45deg);
        height: var(--height);
        width: var(--width);
        border-bottom: var(--borderWidth) solid var(--borderColor);
        border-right: var(--borderWidth) solid var(--borderColor);
        clip-path: none;
      }
    }
  }

  /*Definition List*/
  &-definition {
  }

  /* sintoniza */
  &-sintoniza {
    .max {
      @media (--screen-md-min) {
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  &-sign-in {
    width: 406px;
    height: 563px;
    border: 1px solid var(--grey-05);
    background-color: var(--white);
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .brand {
        margin-bottom: 20px;
      }
      .c-user-body__icons {
        .link {
          width: 30px;
          height: 30px;
          &:nth-child(2) {
            width: 35px;
            height: 35px;
          }
          svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .c-form__recover {
        padding: 10px 0 20px;
      }
    }
    p {
      color: #000;
      font-weight: 500;
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  &-static {
    .c-form {
      &__text,
      &__select,
      &__file {
        margin-bottom: 55px;
        @media (--screen-md-min) {
          max-width: inherit;
        }
      }
    }
  }

  &-cv {
    &.form-success {
      .c-form-body {
        display: none;
      }
    }
    .error.field-error {
      color: #a6255e;
      font-weight: 600;
    }

    .form-message {
      display: block;
      text-align: center;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 17px;
    }

    .message-error {
      color: #a6255e;
    }

    .message-success {
      color: green;
    }
  }
}

/* ED-533 @see https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge */
.grecaptcha-badge {
    visibility: hidden !important;
}
