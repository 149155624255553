.c-nav {
    &__links {
        display: none;

        &__item {
            text-transform: uppercase;
            padding: 5px 10px;
            color: var(--c-nav-color);
        }

        @media (--screen-sm-max) {
            &:nth-child(2) {
                display: block;
            }
        }
        @media (--screen-md-min) {
            height: 43px;
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            &__item {
                font-size: 14px;
                cursor: pointer;

                &[data-type="more"], &[data-type="menu"] {
                    margin-left: auto;
                }
            }
            .current {
                position: relative;

                &:after {
                    content: '';
                    height: 4px;
                    width: calc(100% - 20px);
                    position: absolute;
                    bottom: -8px;
                    display: block;
                    background-color: var(--c-nav-current-color);
                }
            }
        }
    }

    &__group {
        border-top: 1px solid var(--c-nav-border-color);
        border-bottom: 1px solid var(--c-nav-border-color);
        width: 100%;
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        &__link {
            display: inline-block;
            text-transform: uppercase;
            padding: 5px 10px;
            font-size: 14px;
            cursor: pointer;

            &[data-type="more"], &[data-type="menu"] {
                margin-left: auto;
            }

        }

        @media (--screen-md-min) {
            display: flex;
        }

        &__horizontal {
            border-bottom: 1px solid var(--c-nav-border-hamburger-color);
            border-top: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 8px 0 0 0;
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            @media (--screen-sm-min) {
                border-top: 1px solid var(--c-nav-border-hamburger-color);
                border-bottom: 0;
            }

            .c-nav__title {
                text-transform: uppercase;
                font-size: 11px;
                color: var(--c-nav-horizontal-title-color);
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 600;
                white-space: nowrap;
                position: relative;
                background: linear-gradient(90deg, #fff 95%, transparent);
                z-index: 2;
                /*height: 40px;*/
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .c-nav__links {
                height: inherit;
                z-index: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                    border-radius: 5px
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #f3f3f3;
                }

                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;

                li {
                    margin-right: 8px;
                    margin-bottom: 5px;
                    min-width: auto;
                    line-height: 15px;
                    .c-nav__links__item {
                        font-size: 11px;
                        text-transform: initial;
                        color: var(--c-nav-horizontal-color);
                        padding: 0;
                    }
                }
            }

            &-2 {
                .c-nav__title {
                    text-transform: inherit;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 13px;
                }

                .c-nav__links {
                    font-family: var(--primary-semibold-font);
                    color: var(--enlace);

                    li {
                        .c-nav__links__item {
                            color: var(--enlace);
                            text-transform: capitalize;
                            font-weight: 600;
                        }
                    }

                }
            }
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        display: none;
        z-index: 4;
        right: auto;
        width: 360px;
        left: 0;

        &-btn {
            --width: 24px;
            --height: 24px;
            --height-slash: 3px;
            position: absolute;
            top: 0;
            left: 0;
            @media (--screen-md-min) {
                --height-slash: 4px;
                --height: 40px;
                display: none;
            }
            outline: none;
            cursor: pointer;
            transition-property: opacity, filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            font: inherit;
            color: inherit;
            text-transform: none;
            background-color: transparent;
            border: 0;
            margin: 0;
            overflow: visible;
            width: var(--width);
            height: var(--height);

            padding: 15px;
            box-sizing: content-box;
            transition: background-color .3s;

            .hamburger-box {
                display: block;
                position: relative;
                margin: 0 auto;
            }

            .hamburger-inner {
                display: block;
                top: 50%;
                margin-top: -2px;
            }

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                width: var(--width);
                height: var(--height-slash);
                background-color: var(--c-nav-burger-color);
                border-radius: var(--height-slash);
                position: absolute;
                transition-property: transform;
                transition-duration: .15s;
                transition-timing-function: ease;
            }

            .hamburger-inner::before,
            .hamburger-inner::after {
                content: "";
                display: block;
            }

            .hamburger-inner::before {
                top: -10px;
            }

            .hamburger-inner::after {
                bottom: -10px;
            }

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                /*transition-property: none;*/
            }

        }

        .o-container {
            padding: 0;
            /*editora*/
            .wf-page-container {
                background-color: #FFF;
            }

            > div {
                height: 100vh;
                height: calc(100vh - 36px);
                background: var(--blanco);
            }
        }

        &__close {
            display: none;
            @media (--screen-md-min) {
                display: block;
                cursor: pointer;
                width: 44px;
                height: 44px;
                margin: 0 0 0 auto;
                padding: 10px;
            }

            &-megamenu {
                position: absolute;
                top: 26px;
                right: 16px;
                z-index: 2;
                display: block;
                @media (--screen-md-min) {
                    display: block;
                    cursor: pointer;
                    width: 44px;
                    height: 44px;
                    margin: 0 0 0 auto;
                    padding: 10px;
                    top: 16px;
                    right: 10px;
                }
            }
        }

        &__link {
            & > li {
                position: relative;
                a {
                    text-transform: uppercase;
                    padding: 12px 0;

                    &.current {
                        position: relative;
                        width: max-content;

                        &:after {
                            content: '';
                            height: 4px;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            display: block;
                            background-color: var(--c-nav-current-color);
                        }
                    }

                    &.capitalize {
                        text-transform: none;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--c-nav-border-hamburger-color);
                }

                ul {
                    background-color: var(--c-nav-bg);
                    padding-bottom: 12px;

                    & > li {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        &:before {
                            content: '';
                            background: url(../images/icon/punta-derecha-fondo.svg) no-repeat 0 0;
                            width: 24px;
                            height: 24px;
                            display: block;
                            margin-right: 4px;
                        }

                        & > a {
                            text-transform: none;
                            padding: 6px 0;
                        }

                        &:first-child > a {
                            padding-top: 0;
                        }
                        &:last-child > a {
                            padding-bottom: 0;
                        }
                    }

                    .c-nav__menu_title__link:before {
                        content: '';
                        background: unset;
                        width: 0px;
                        height: 0px;
                        display: block;
                        margin-right: 0px;
                    }
                }

            }

            @media (--screen-sm-min) {
                padding: 15px;
                border-top: 1px solid var(--c-nav-border-hamburger-color);
                align-items: flex-start;
            }

            &-megamenu {
                padding: 15px;

                & > li ul > li {
                    margin-left: 8px;

                    &:before {
                        content: none;
                    }
                }

                .c-nav__menu__special ul > li {
                    margin-left: 0;
                }

                > li:last-child > .c-nav__menu__special {
                    border-bottom: solid 1px var(--bordes);
                }
            }

            &__title {
                font-family: var(--primary-semibold-font);
                font-size: 18px;
                line-height: 1;
                color: var(--enlace-oscuro);

                a {
                    color: var(--enlace-oscuro);
                }
            }

            &__subtitle {
                font-family: var(--third-font);
                font-size: 23px;
                font-weight: 600;
                line-height: 1.09;
                letter-spacing: -0.23px;
                color: var(--acento);

                a {
                    color: var(--acento);
                }
            }
        }

        &__item {
            color: var(--enlace-oscuro);
            &-special {
                font-family: var(--primary-semibold-font);
                font-size: 18px;
                font-weight: 500;
                line-height: 1;
                color: var(--enlace-oscuro);
                padding: 6px 0;
            }
        }

        &__special {
            padding: 4px 16px;
            border: solid 1px var(--bordes);
            margin: 12px 0;
            &::before {
                content:"";
                border-top: 1px solid var(--white);
                display: block;
                position: absolute;
                top: -13px;
                width: 100%;
                left: 0;
            }
            &::after {
                content:"";
                border-bottom: 1px solid var(--white);
                display: block;
                position: absolute;
                bottom: -1px;
                width: 100%;
                left: 0;
            }

            img {
                display: none;
            }

            &--icon {
                ul {
                    display: flex;
                    justify-content: flex-start;
                }
                .c-nav__menu__item {
                    &__container {
                        display: flex;
                        flex-direction: column;
                    }
                    &__image {
                        background-color: var(--fondo-gris);
                        aspect-ratio: 1 / 1;
                        padding: 19px;
                        max-height: 100px;
                        align-content: center;
                        margin-bottom: 6px;

                        img {
                            display: block;
                            max-width: 100%;
                            height: 100%;
                            max-height: 100%;
                            object-fit: contain;
                            margin: 0 auto;
                        }
                    }
                    &-special {
                        font-family: var(--primary-font);
                        font-size: 14px;
                        text-align: center;
                        padding: 0;
                        position: relative;
                        width: 33%;
                        max-width: 100px;
                        &:not(:last-child) {
                            margin-right: 5px;
                        }
                        a {
                            cursor: pointer;
                            text-transform: none;
                            padding: 0;
                            color: var(--enlace-oscuro);
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 1;
                            }
                        }
                    }
                }
                .c-nav__menu__link > li ul > li {
                    justify-content: center;
                }
                .c-nav__menu_title__link {
                    align-items: flex-start;
                    width: 100%
                }
            }
        }

        &__cta {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-top: 1px solid var(--c-nav-border-hamburger-color);
            padding-bottom: 12px;

            &__txt {
                font-family: var(--secondary-font);
                text-align: center;
                color: var(--texto-suave);
                padding: 12px 0;
            }

            &__btn {
                &.c-form__btn {
                    width: auto;
                    margin: 0 auto;
                    padding: 8px 16px 6px;

                    > a {
                        padding: 0;
                        color: var(--white);
                    }
                }
            }
        }

        .c-nav__group__horizontal .c-nav__links {
            height: inherit;
        }
    }

    &__donation {
        position: fixed;
        z-index: 1;
        bottom: 0;
        padding: 24px;
        padding-bottom: 0;
        box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--fondo-verde3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 33px;
        width: 100%;

        &__title {
            font-family: var(--third-font);
            font-size: 23px;
            line-height: 1.09;
            letter-spacing: -0.23px;
            padding-bottom: 8px;
            color: var(--texto);
        }

        &__btn {
            &.c-form__btn {
                width: auto;
                margin-bottom: 24px;
                padding: 15px 20px 16px;
            }
            a {
                padding: unset;
                color: var(--white);
            }
        }

        @media (--screen-xs-min) {
            position: sticky;
        }
    }
}

.open-navigation {
    .c-nav {
        font-family: var(--c-nav-font);

        &__menu {
            z-index: 4;
            display: block;
            padding: 0;
            right: auto;
            width: 100%;
            max-width: 980px;
            left: 0;

                .o-container {
                background: var(--c-nav-bg);
                margin: 0;
                @media (--screen-md-min) {
                    max-width: 414px;
                    margin: 0;
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                }

                > div {
                    height: 100vh;
                    background: var(--blanco);
                }
            }

            &__link {
                padding: 12px 24px;
                overflow-y: auto;
                height: calc(100vh - 75px - 33px);
                padding-bottom: 175px;
                overscroll-behavior: none;

                @supports (-webkit-touch-callout: none) {
                    padding-bottom: 250px;
                }
            }
            @media (--screen-xs-min) {
                width: 414px;
                right: 0;
            }

            &__close{
                &-megamenu {
                    &-mask {
                        @media (--screen-xs-min) {
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100vw;
                            height: 100vh;
                            background-color: var(--negro-transparente);
                            z-index: 2;
                        }
                    }
                }
            }
        }

        @media (--screen-sm-max) {
            top: 44px;
            z-index: 4;
            &__group {
                &__horizontal {
                    &-2 {
                        .c-nav__links {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
    @media (--screen-xxs-max) {
        overflow: hidden;
    }
}

.c-nav__menu + .c-header-registro {
    top: 106px;
}

/*
@keyframes desplazar-x {
    0% {
        transform: translateX(0);

    }
    100%{
        transform: translateX(-100%);
    }
}*/
