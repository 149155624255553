.c-lomas{
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 20px 0;
  border-top: 1px solid var(--bordes);
  &__title{
    font-family: var(--third-font);
    color: var(--acento);
    font-size: 23px;
    line-height: 25px;
    font-weight: 600;
    padding: 10px 0 10px;
    text-transform: uppercase;
    @media (--screen-sm-min) {
      font-size: 24px;
      line-height: 27px;
    }
  }
  &__list{
    border-bottom: 4px solid var(--texto-secundario);
    &__item {
      padding: 16px 0;
      position: relative;
      &:not(:last-child) {
        border-bottom:1px solid var(--bordes);
      }
      a{
        font-family: var(--third-font);
        font-size: 23px;
        font-weight: 600;
        line-height: 1.09;
        letter-spacing: -0.23px;
        text-align: left;
        color: var(--texto);
        width: 100%;
        max-width: calc(100% - 34px);
        @media (--screen-sm-min) {
          font-size: 24px;
          line-height: 1.13;
          letter-spacing: -0.27px;
          break-inside: avoid;
        }
      }
      @media (--screen-sm-min) {
        padding: 10px 0;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
    &.show-number{
    .c-lomas__list__item{
      counter-increment: step-counter;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      &:before {
        content: counter(step-counter);
        color: var(--texto-secundario);
        font-size: 16px;
        font-weight: 600;
        display: block;
        width: 20px;
        margin-right: 10px;
        text-align: right;
        padding: 2px 0;
        @media (--screen-sm-min) {
          font-size: 20px;
        }
      }


    }
  }
  }
  .o-tab{
    &__nav{
      padding: 15px 15px 0 15px;
      background-color: var(--fondo);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      label{
        display: block;
        text-transform: uppercase;
        color: var(--texto);
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 15px;
        position: relative;
        cursor: pointer;
        &:not(:last-child){
          margin-right: 15px;
        }

      }

    }

  }
  &.opinion-listing {
    border-top: 0;
    border-bottom: 4px solid var(--texto-secundario);
    .c-lomas__title {
      border-bottom: 1px solid var(--bordes);
      padding: 2px 0 10px;
    }
    .c-lomas__list__item  {
        padding: 18px 0 8px;
        @media (--screen-sm-min) {
            padding-top: 12px;
        }
      &:last-of-type {
        padding-bottom: 13px;
        @media (--screen-sm-min) {
          padding-bottom: 16px;
        }
      }
      .c-article__title {
        font-style: italic;
        margin-bottom: 2px;
        @media (--screen-sm-min) {
          margin-bottom: 6px;
        }
      }
    }
  }
  &.author-listing {
    padding: 10px;
    border-bottom: 4px solid var(--texto-secundario);
    .c-lomas__title {
      border-bottom: 1px solid var(--fondo-intermedio);
      padding: 2px 0 9px;
    }
    .o-section .o-container {
      background: none;
      padding: 0;
      padding-top: 12px !important;
      article {
        padding: 0;
        margin-bottom: 13px;
        .c-article__signature {
          display: none;
        }
        .author {
          text-transform: uppercase;
          font-size: 14px;
          color: var(--texto-secundario);
          margin-bottom: 9px;
        }
        .c-lomas__list__item {
          padding: 0;
          font-style: italic;
          a {
            max-width: none;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .c-line {
        margin-bottom: 12px;
        &:last-child {
          display: none;
        }
      }
    }
  }
}
