@mixin paragraph-lists {
    & + ol {
        font-family: var(--c-detail-body-text-font);
        font-size: var(--c-detail-body-text-font-size);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: var(--c-detail-body-text-line-height);
        letter-spacing: normal;
        text-align: left;
        color: var(--c-detail-body-text-color);

        li {
            counter-increment: my-counter;
            font-size: 19px;
            line-height: 1.26;
            margin-bottom: 11px;
            &:before {
                content: counter(my-counter) ".";
                margin-right: 7px;
            }
            a {
                display: inline;
                color: var(--c-detail-body-text-color);
            }
            @media (--screen-md-min) {
                font-size: 24px;
                line-height: normal;
            }
        }
    }
    & + ul {
        font-family: var(--c-detail-body-text-font);
        font-size: var(--c-detail-body-text-font-size);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: var(--c-detail-body-text-line-height);
        letter-spacing: normal;
        text-align: left;
        color: var(--c-detail-body-text-color);

        li {
            font-size: 19px;
            line-height: 1.26;
            margin-bottom: 11px;
            padding-left: 48px;
            position: relative;
            &:before {
                content: "";
                width: 10px;
                height: 10px;
                background-color: var(--fondo-intermedio);
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 9px;
                left: 20px;
            }
            a {
                display: inline;
                color: var(--enlace);
            }
            @media (--screen-md-min) {
                font-size: 24px;
                line-height: normal;
            }
        }
    }
}
@mixin gradient-bg($color) {
    @if $enable-gradients {
        background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x;
    } @else {
        background-color: $color;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin alert-variant($background, $border, $color) {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;

    hr {
        border-top-color: darken($border, 5%);
    }

    .alert-link {
        color: darken($color, 10%);
    }
}

