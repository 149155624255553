.title-container {
    padding: 8px 0 16px;
    .title {
        font-family: var(--third-font);
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: var(--texto-secundario);
        @media (--screen-md-min) {
            font-size: 35px;
            line-height: 1.06;
        }
    }
}
.c-static {
    @extend %ext__static-page;
}
