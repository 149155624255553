.c-branded{
    position: relative;
    z-index:1;
    .c-article__txt{
        padding: 16px;
        background-color: var(--fondo-beige);
    }
    &__epigraph{
        font-size: 14px;
        line-height: normal;
        color: var(--texto-suave);
        font-family: var(--primary-font);
        text-transform: uppercase;
        margin-bottom: 9px;
        &__sp1 {
            margin-right: 5px;
        }
        &__sp2 {
            font-weight: 600;
        }
    }

    .c-article__title {
        margin-bottom: 0;
    }
    .c-article__signature {
        display: none;
    }
    &-lateral-md{
      background-color: var(--fondo-beige);
      .c-article__txt {
        background: none;
      }
      .c-branded__epigraph{
        padding-bottom: 9px;
        margin-bottom: 0;
        background: var(--blanco);
      }
      &::after {
        @media (--screen-xs-max) {
          position: absolute;
          bottom: -16px;
          left: 0;
          margin: 0 auto !important;
        }
      }
      @media (--screen-sm-min) {
        display: grid;
        grid-template-columns: 6fr 6fr;
        grid-column-gap: 0;
        margin-bottom: 0 !important;
        .c-branded__epigraph{
          grid-row-start: 1;
          grid-column: 1 / span 2;
        }
        .c-article__thumb{
          grid-row-start: 2;
          grid-column-start: 2;
        }
        .c-article__txt{
          grid-row-start: 2;
          grid-column-start: 1;
        }
      }
      @media (--screen-xs-max) {
        position: relative;
        margin-bottom: 32px !important;
      }
      &:last-child {
        @media (--screen-xs-max) {
          margin-bottom: 16px !important;
        }
        &::after {
          @media (--screen-xs-max) {
            display: none;
          }
        }
      }

    }
}
