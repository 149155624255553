.c-article {
    position: relative;
    z-index: 1;
    .video-player:hover {
        cursor: pointer;
    }
    .video-player .multimedia-video {
        display: none;
    }
    .video-player.player-inline .c-article__icon {
        display: none;
    }
    .video-player.player-inline .multimedia-video {
        /*display: block;
      height: 100%;*/
        img {
            display: none;
        }
    }
    &__thumb-video {
        position: relative;
        height: fit-content;
        a:before {
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
        }
        &_top,
        &-top {
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }
        img {
            width: 100%;
            object-fit: cover;
            height: auto;
        }
        .icon {
            visibility: hidden;
        }
        & + .c-article__txt {
            padding-top: 10px;
        }
        &-rounded {
            img {
                border-radius: 50%;
                overflow: hidden;
                width: 80px;
                height: 80px;
                display: block;
                margin-left: auto;
            }
        }
        &.video-player .multimedia-video  {
             display: none;
        }
        .c-info {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: help;

            svg {
                height: 20px;
                color: white;
            }
        }
    }
    &__thumb {
        position: relative;
        height: fit-content;
        .multimedia-video {
            display: none;
        }
        a:before {
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
        }
        &_top,
        &-top {
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }

            .c-info {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: help;

                svg {
                    height: 20px;
                    color: white;
                }
            }
        }
        img {
            width: 100%;
            object-fit: cover;
            height: auto;
        }
        .icon {
            visibility: hidden;
        }
        & + .c-article__txt {
            padding-top: 10px;
        }
        .c-info {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: help;

            svg {
                height: 20px;
                color: white;
            }
        }
        &-rounded {
            img {
                border-radius: 50%;
                overflow: hidden;
                width: 80px;
                height: 80px;
                display: block;
                margin-left: auto;
            }
        }
    }
    div + .c-article__txt {
        padding-top: 10px;
    }
    .custom-podcast-module + .c-article__txt {
        .c-article__title {
            a {
                &:before {
                    display: none;
                }
            }
        }
    }
    &__image-author-content {
        display: none;
    }
    &__signature-content {
        display: flex;
        .c-article__signature {
            margin-top: 4px;
            .c-article__author {
                span {
                    display: inline;
                }
                span[name="author-position"] {
                    display: none;
                }
            }
            .c-opinion__author {
                span {
                    display: inline-block;
                }
            }
        }
    }
    &__signature {
        &.v2 {
            .c-article__author {
                span {
                    display: none;
                }
            }
            .c-opinion__author {
                span {
                    display: inline-block;
                }
            }
        }
    }
    &-author-image {
        .c-article__image-author-content {
            display: inline-block;
            margin-right: 10px;
            img {
                border-radius: 50%;
            }
        }
    }
    &__image-author {
        width: 32px;
        display: inline-block;
        img {
            border-radius: 50%;
            margin: -12px auto;
        }
    }
    &__two-image {
        margin-bottom: 0px !important;
    }
    &__link {
        z-index: 2;
        position: relative;
    }
    &__txt {
        z-index: 1;
        &-center {
            & > * {
                text-align: center;
                &.c-article__signature-content {
                    justify-content: center;
                }
            }
        }
        & > *:last-child {
            margin-bottom: 0;
        }
        .c-article__title {
            display: block;
        }
    }
    &__epigraph {
        font-family: var(--c-article-epigraph-font);
        text-transform: uppercase;
        font-size: var(--c-article-epigraph-font-size);
        color: var(--c-article-epigraph-color);
        margin-bottom: 8px;
        a {
            color: var(--c-article-epigraph-color);
        }
        &_opinion {
            display: none;
        }
    }
    &__title {
        display: inline-block;
        font-size: var(--c-article-title-font-size);
        font-weight: var(--c-article-title-font-weight);
        line-height: var(--c-article-title-line-height);
        letter-spacing: -0.23px;
        color: var(--c-article-title-color);
        font-family: var(--c-article-title-font);
        margin-bottom: 8px;
        a {
            color: var(--texto);
            display: inline;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
            &.link-with-video,
            &.link-with-author {
                &:before {
                    display: none;
                }
            }
        }
        &.size-1 {
            @extend %ext__title-1;
            .c-article__live {
                &:before {
                    width: 8px;
                    height: 8px;
                    margin-top: -4px;
                }
            }
        }
        &.size-2 {
            @extend %ext__title-2;
        }
        &.size-3 {
            @extend %ext__title-3;
        }
        &.size-4 {
            @extend %ext__title-4;
        }
        &.size-5 {
            @extend %ext__title-5;
        }
        &.size-6 {
            @extend %ext__title-6;
            margin-bottom: 5px;
            & + .c-article__signature {
                margin-bottom: 15px;
                display: inline-block;
            }
        }
        &.style-italic {
            font-style: italic;
        }
    }
    &__subtitle {
        padding-top: 8px;
        color: var(--texto-suave);
        &.size-1 {
            @extend %ext__subtitle-1;
        }
        &.size-2 {
            @extend %ext__subtitle-2;
        }
        &.size-3 {
            @extend %ext__subtitle-3;
        }
        &.size-4 {
            @extend %ext__subtitle-4;
        }
        &.size-5 {
            @extend %ext__subtitle-5;
        }
        &.size-6 {
            @extend %ext__subtitle-6;
        }
    }
    &__signature {
        position: relative;
        z-index: 2;
        display: inline-block;
    }
    &__author {
        font-family: var(--c-article-author-font);
        font-size: var(--c-article-author-font-size);
        font-weight: 600;
        color: var(--c-article-author-color);
        a {
            display: inline;
        }
    }
    &__time {
        font-size: 11px;
        color: var(--texto);
    }
    &__pretitle {
        color: var(--c-article-pretitle-color);
        padding-right: 4px;
        display: none;
    }
    &__pretitle_listing {
        color: var(--c-article-epigraph-color);
        display: inline;
    }
    .date {
        font-size: 11px;
        color: var(--texto);
        display: inline;
    }
    &__author + &__time,
    &__author + .date {
        &:before {
            content: " - ";
        }
    }

    &__comment {
        font-size: 11px;
        font-weight: 600;
        color: var(--c-article-comment-color);
        display: inline-block;
        vertical-align: middle;
        z-index: 2;
        position: relative;
        margin-left: 4px;
        svg {
            height: 13px;
            width: 16px;
            display: block;
            float: left;
            margin-right: 2px;
        }
    }
    &__icon {
        position: absolute;
        bottom: 16px;
        left: 16px;
        display: none;
        cursor: pointer;
        @extend %ext__icon-media;
    }
    &__live {
        justify-content: flex-start;
        align-items: center;
        display: none;
        position: relative;
        &:after {
            content: "Directo";
            padding-left: 20px;
            padding-right: 4px;
            color: var(--acento);
        }
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background-color: var(--acento);
            border-radius: 50%;
            overflow: hidden;
            animation: bullet 1s infinite;
            animation-delay: 1s;
            animation-duration: 1s;
            position: absolute;
            top: 50%;
            margin-top: -8px;
        }
        &.u-visible {
            display: inline-flex;
        }
        &.related:after {
            padding-left: 9px;
            padding-right: 0px;
        }
    }
    &__exclusive {
        justify-content: flex-start;
        align-items: center;
        display: none;
        position: relative;
        &:after {
            content: "Exclusiva";
            color: var(--acento);
        }
        &.u-visible {
            display: inline-flex;
        }
    }
    &__interview {
        display: none;
        p {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            color: var(--acento);
            &:before {
                content: "Entrevista";
                color: var(--acento);
                margin-right: 3px;
                font-weight: normal;
                font-size: 14px;
                text-transform: uppercase;
            }
        }
        &.inicio {
            svg {
                vertical-align: top;
                color: var(--acento);
                width: 17px;
                height: 12px;
                margin-top: 4px;
                @media (--screen-md-min) {
                    width: 20px;
                    height: 15px;
                    margin-top: 6px;
                }
            }
        }
    }
    &__related {
        text-align: left;
        z-index: 2;
        position: relative;
        display: none;
        &.u-grid-gap-20 {
            @media (--screen-sm-min) {
                display: none;
                li {
                    width: 100%;
                }
            }
        }
        .c-opinion {
            &__author {
                a {
                    color: var(--texto-secundario);
                    font-weight: normal;
                }
            }
        }
        .c-article-no-author-charge {
            span[name="author-position"] {
                display: none;
            }
        }
    }
    &__wink {
        display: none;
    }
    &-lateral {
        display: grid;
        grid-template-columns: 1fr 120px;
        grid-column-gap: 10px;
        &.u-border,
        &.u-border-xs {
            &:after {
                grid-column: 1 / span 2;
            }
        }
        .c-article__txt,
        .date {
            word-break: break-word;
            text-align: inherit !important;
        }
        .c-article__txt {
            padding-top: 0;
        }
        & > .c-article__related {
            grid-column: 1 / span 2;
        }
        &.c-article {
            &-bg {
                .c-related__txt {
                    padding: 16px 0 16px 16px;
                    max-width: 100%;
                }
                .c-article__txt {
                    padding: 16px 0 16px 16px;
                }
                @media (--screen-xs-max) {
                    position: relative;
                    &.u-nomargin-bottom {
                        margin-bottom: 32px !important;
                    }
                }
            }
            &-red {
                background-color: var(--acento);
            }
            &-grey {
                background-color: var(--fondo-gris);
            }
            &-green {
                background-color: var(--fondo-verde);
            }
            &-dark-grey {
                background-color: var(--enlace-oscuro);
            }
            &-soft-green {
                background-color: var(--fondo-verde3);
            }
        }
    }
    &-lateral-sm-only {
        &.c-article-lateral-case-3 {
            .c-article {
                &__icon {
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    left: 10px;
                    bottom: 10px;
                    span {
                        display: none;
                    }
                    svg {
                        width: 16px;
                    }
                }
            }
        }
        @media (--screen-sm-only) {
            display: flex;
            flex-direction: column;
            grid-template-columns: initial;
            grid-column-gap: initial;
            &.c-article {
                &-bg {
                    .c-related__txt {
                        padding: 16px;
                    }
                    .c-article__txt {
                        padding: 16px;
                    }
                }
            }
            .c-article__txt,
            .c-related__txt {
                order: 2;
                padding-top: 10px;
            }
            figure {
                object-fit: initial;
                order: 1;
            }
            &.u-border.c-article:after {
                order: 3;
            }
            &.c-article-opinion {
                .c-opinion__author,
                .c-opinion__title {
                    text-align: center;
                }
                figure {
                    margin: 0 auto;
                }
            }
        }
    }
    &-lateral-case-2 {
        display: grid;
        grid-template-columns: 80px 1fr;
        grid-column-gap: 20px;
        .c-article__thumb + .c-article__txt {
            padding-top: 0;
        }
        .c-article__thumb-video + .c-article__txt {
            padding-top: 0;
        }
        .c-article__txt {
            word-break: break-word;
        }
        & > .c-article__related {
            grid-column: 1 / span 2;
        }
        &.u-border,
        &.u-border-xs {
            &:after {
                grid-column: 1 / span 2;
            }
        }
    }
    &-lateral-md {
        .c-article__txt {
            & > * {
                text-align: center;
            }
            word-break: break-word;
            /*@media (--screen-xs-max) {
          .c-article__subtitle {
            margin-bottom:12px;
          }
        }*/
        }
        &.c-article {
            &-bg {
                .c-article__related {
                    &:empty {
                        padding: 0;
                    }
                    .c-related:first-child {
                        padding-top: 0;
                        @media (--screen-sm-min) {
                            padding-top: 12px;
                        }
                    }
                }
                @media (--screen-sm-max) {
                    .c-article__thumb {
                        padding: 0;
                    }
                }
            }
            &:not(.c-article-bg) {
                .c-article__thumb {
                    & + .c-article__txt {
                        padding-top: 0;
                    }
                }
                .c-article__thumb-video {
                    & + .c-article__txt {
                        padding-top: 0;
                    }
                }
            }
            &-interview {
                .c-article__txt {
                    & > * {
                        text-align: left;
                        @media (--screen-md-min) {
                            padding-right: 10px;
                        }
                    }
                }
            }
            &-exclusive {
                .c-article__txt {
                    & > * {
                        @media (--screen-md-min) {
                            padding-right: 10px;
                        }
                    }
                }
            }
            &-perfil-visual {
                @media (--screen-sm-max) {
                    flex-direction: column-reverse;
                    .c-article__txt > * {
                        text-align: left;
                    }
                    &:not(.c-article-interview) .c-article__signature-content {
                        justify-content: start;
                    }
                }
                .c-article__signature-content {
                    @media (--screen-md-min) {
                        position: absolute;
                        bottom: 16px;
                    }
                }
                &.c-article {
                    &-grey {
                        .c-article__epigraph {
                            color: var(--acento);
                        }
                    }
                    &-soft-green {
                        .c-article__epigraph {
                            color: var(--acento);
                        }
                    }
                }
            }
        }
        @media (--screen-md-min) {
            display: grid;
            grid-template-columns: 6fr 6fr;
            grid-column-gap: 20px;
            &.u-border {
                &:after {
                    grid-column: 1 / span 2;
                }
            }
            &-col-3-5 {
                grid-template-columns: 3fr 5fr;
                &.u-border {
                    &:after {
                        grid-column: 1 / span 2;
                    }
                }
            }
            .c-article__thumb {
                grid-row-start: 1;
                grid-column-start: 2;
            }
            .c-article__thumb-video {
                grid-row-start: 1;
                grid-column-start: 2;
            }
            .marcador-portada {
                grid-row-start: 2;
                grid-column-start: 2;
            }
            .c-article__txt {
                grid-row-start: 1;
                grid-column-start: 1;
                & > * {
                    text-align: left;
                }
            }
            .c-article__related {
                grid-column: 1 / span 2;
            }
            &.c-article {
                &-red {
                    background-color: var(--acento);
                }
                &-grey {
                    background-color: var(--fondo-gris2);
                }
                &-green {
                    background-color: var(--fondo-verde);
                }
                &-dark-grey {
                    background-color: var(--enlace-oscuro);
                }
                &-soft-green {
                    background-color: var(--fondo-verde3);
                }
            }
        }
        @media (--screen-sm-max) {
            display: flex;
            flex-direction: column;
            .c-article__thumb {
                order: 1;
                padding: 0 0 16px;
            }
            .c-article__thumb-video {
                order: 1;
                padding: 0 0 16px;
            }
            .c-article__txt {
                order: 2;
            }
            .c-article__related {
                order: 3;
            }
            &.u-border-xs:after {
                order: 4;
            }
            &:not(.c-article-interview) {
                .c-article__signature-content {
                    justify-content: center;
                }
            }
            &-col-3-5 {
                .c-article__txt {
                    & > * {
                        text-align: left;
                    }
                }
                &:not(.c-article-interview, .c-article-perfil-visual) {
                    .c-article__signature-content {
                        justify-content: flex-start;
                    }
                }
            }
        }
        &.mega {
            @media (--screen-sm-min) {
                display: grid;
                grid-template-columns: 6fr 6fr;
                grid-column-gap: 20px;
                .c-article__thumb {
                    grid-row-start: 1;
                    grid-column-start: 2;
                }
                .c-article__thumb-video {
                    grid-row-start: 1;
                    grid-column-start: 2;
                }
                .c-article__txt {
                    grid-row-start: 1;
                    grid-column-start: 1;
                    & > * {
                        text-align: left;
                        justify-content: initial;
                    }
                }
                .c-article__related {
                    grid-column: 1 / span 2;
                    padding-bottom: 0;
                }
                &.c-article {
                    &-red {
                        background-color: var(--acento);
                    }
                    &-grey {
                        background-color: var(--fondo-gris2);
                    }
                    &-green {
                        background-color: var(--fondo-verde);
                    }
                    &-dark-grey {
                        background-color: var(--enlace-oscuro);
                    }
                    &-soft-green {
                        background-color: var(--fondo-verde3);
                    }
                    &:not(.c-article-bg) {
                        .c-article__thumb {
                            & + .c-article__txt {
                                padding-top: 0;
                            }
                        }
                        .c-article__thumb-video {
                            & + .c-article__txt {
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
            @media (--screen-xs-max) {
                .c-article__thumb {
                    order: 2;
                    padding: 0;
                }
                .c-article__thumb-video {
                    order: 2;
                    padding: 0;
                }
                .c-article__txt {
                    order: 1;
                    padding: 0 0 16px;
                }
                .c-article__related {
                    order: 3;
                }
                .c-article__signature {
                    margin-bottom: 10px;
                }
            }
        }
        @media (min-width: 768px) {
            .marcador-portada {
                height: 0px;
                min-height: 0px;
            }
        }
    }
    &-lateral-case-3 {
        .c-article__thumb {
            grid-column-start: 2;
            grid-row-start: 1;
        }
        .c-article__thumb-video {
            grid-column-start: 2;
            grid-row-start: 1;
        }
    }
    &-live {
        .c-article__title {
            a {
                display: inline;
            }
        }
        &:not(.c-article-opinion, .c-article-perfil) {
            .c-article__live {
                display: inline-flex;
            }
        }
        @media (--screen-md-min) {
            grid-column-gap: 0;
        }
    }
    &-exclusive {
        .c-article__title {
            padding-right: 10px;
            a {
                display: inline;
            }
        }
        &:not(.c-article-opinion, .c-article-perfil) {
            .c-article__exclusive {
                display: inline-flex;
            }
        }
        @media (--screen-md-min) {
            grid-column-gap: 0;
        }
    }
    &-interview {
        .c-article__epigraph {
            display: none;
        }
        .c-article__interview {
            display: inline-flex;
            > div {
                margin-bottom: 6px;
            }
            &.inicio {
                display: inline;
            }
        }
        .c-article__txt-center {
            .c-article__interview {
                display: inline;
                justify-content: center;
            }
        }
        .c-article__title {
            a {
                margin-left: -2px;
                display: inline;
            }
            &:after {
                content: "";
                background: url(../images/icon/cita-end-black.svg) no-repeat
                    center left;
                display: inline-block;
                background-size: 100%;
                margin-left: 3px;
                width: 11px;
                height: 8px;
                margin-bottom: 12px;
                @media (--screen-sm-min) {
                    width: 13px;
                    height: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        @media (--screen-md-min) {
            grid-column-gap: 0;
        }
        &.size-1 {
            .c-article__title {
                .inicio {
                    svg {
                        width: 15px;
                        height: 11px;
                        margin-top: 4px;
                        @media (--screen-sm-min) {
                            width: 16px;
                            height: 12px;
                            margin-top: 5px;
                        }
                    }
                }
                &:after {
                    width: 9px;
                    height: 6px;
                    margin-bottom: 10px;
                    @media (--screen-sm-min) {
                        width: 11px;
                        height: 8px;
                        margin-bottom: 9px;
                    }
                }
            }
        }
        &.size-2 {
            .c-article__title {
                .inicio {
                    svg {
                        width: 17px;
                        height: 12px;
                        margin-top: 4px;
                        @media (--screen-sm-min) {
                            width: 20px;
                            height: 15px;
                            margin-top: 6px;
                        }
                    }
                }
                &:after {
                    width: 11px;
                    height: 8px;
                    margin-bottom: 12px;
                    @media (--screen-sm-min) {
                        width: 13px;
                        height: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &.size-3 {
            .c-article__title {
                .inicio {
                    svg {
                        width: 20px;
                        height: 15px;
                        margin-top: 6px;
                        @media (--screen-sm-min) {
                            width: 23px;
                            height: 18px;
                            margin-top: 8px;
                        }
                    }
                }
                &:after {
                    width: 12px;
                    height: 9px;
                    margin-bottom: 15px;
                    @media (--screen-sm-min) {
                        width: 15px;
                        height: 11px;
                        margin-bottom: 12px;
                    }
                }
            }
        }
        &.size-4 {
            .c-article__title {
                .inicio {
                    svg {
                        width: 25px;
                        height: 19px;
                        margin-top: 6px;
                        @media (--screen-sm-min) {
                            width: 28px;
                            height: 22px;
                            margin-top: 9px;
                        }
                    }
                }
                &:after {
                    width: 16px;
                    height: 12px;
                    margin-bottom: 13px;
                    @media (--screen-sm-min) {
                        width: 19px;
                        height: 15px;
                    }
                }
            }
        }
        &.size-5 {
            .c-article__title {
                .inicio {
                    svg {
                        width: 28px;
                        height: 21px;
                        margin-top: 7px;
                        @media (--screen-sm-min) {
                            width: 31px;
                            height: 24px;
                            margin-top: 10px;
                        }
                    }
                }
                &:after {
                    width: 18px;
                    height: 13px;
                    margin-bottom: 16px;
                    @media (--screen-sm-min) {
                        width: 21px;
                        height: 16px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        &.size-6 {
            .c-article__title {
                .inicio {
                    svg {
                        width: 28px;
                        height: 21px;
                        margin-top: 7px;
                        @media (--screen-sm-min) {
                            width: 31px;
                            height: 24px;
                            margin-top: 10px;
                        }
                    }
                }
                &:after {
                    width: 18px;
                    height: 13px;
                    margin-bottom: 16px;
                    @media (--screen-sm-min) {
                        width: 21px;
                        height: 16px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    &-pretitle {
        .c-article__pretitle {
            display: inline;
        }
        .c-article__title,
        .c-opinion__title {
            a {
                display: inline;
            }
        }
    }
    &-related {
        .c-article__related {
            display: block;
            &.u-grid-gap-20 {
                @media (--screen-sm-min) {
                    display: flex;
                    li {
                        width: 100%;
                    }
                }
            }
        }
    }
    &-wink {
        .c-article__wink {
            display: inline;
            position: relative;
            z-index: 2;
            img {
                font-size: 0;
                line-height: 0;
                margin-bottom: 10px;
                width: auto;
                height: auto;
                max-height: 20px;
                max-width: 125px;
            }
        }
    }
    .icon {
        visibility: hidden;
        &_txt {
            padding-top: 2px;
            font-family: var(--c-icon-txt-font);
            font-size: var(--c-icon-txt-size);
            line-height: var(--c-icon-txt-line-height);
            font-weight: var(--c-icon-txt-font-weight);
            text-transform: uppercase;
            display: none;
        }
        &_time {
            display: none;
            font-family: var(--c-icon-time-font);
            font-size: var(--c-icon-time-size);
            line-height: var(--c-icon-time-line-height);
            font-weight: var(--c-icon-time-font-weight);
            color: var(--c-icon-time-color);
            text-transform: uppercase;
        }
    }
    &-module-video {
        .c-article__icon {
            display: flex;
        }
        .icon_txt_video {
            display: flex;
        }
        .icon_video {
            visibility: visible;
        }
        .icon_time {
            display: flex;
        }
    }
    &-module-gallery {
        .c-article__icon {
            display: flex;
        }
        .icon_txt_gallery {
            display: flex;
        }
        .icon_gallery {
            visibility: visible;
        }
    }
    &-module-podcast {
        .c-article__icon {
            display: flex;
        }
        .icon_txt_podcast {
            display: flex;
        }
        .icon_podcast {
            visibility: visible;
        }
    }
    &-module-interactivo {
        .c-article__icon {
            display: flex;
        }
        .icon_txt_interactivo {
            display: flex;
        }
        .icon_interactivo {
            visibility: visible;
        }
    }
    &-module-encuesta {
        .c-article__icon {
            display: flex;
        }
        .icon_txt_encuesta {
            display: flex;
        }
        .icon_encuesta {
            visibility: visible;
        }
    }
    .c-related,
    .c-opinion {
        padding-top: 12px;

        @media (--screen-md-min) {
            padding-top: 20px;
        }
    }
    &-fix {
        display: grid;
        .c-article__thumb {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;
            z-index: 1;
            img {
                height: 100%;
            }
        }
        .c-article__thumb-video {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;
            z-index: 1;
            img {
                height: 100%;
            }
        }
        .c-article__txt {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 20px !important;
            background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0),
                var(--negro)
            );
        }
        .c-article__title {
            color: var(--blanco);
            a {
                color: var(--blanco);
            }
        }
        .c-article__signature {
            color: var(--blanco);
            margin-top: 8px;
        }
        .c-article__epigraph {
            color: var(--blanco);
            display: block;
            font-size: 14px;
            margin-bottom: 8px;
            a {
                color: var(--blanco);
            }
        }
        .c-article__author {
            color: var(--blanco);
            a {
                color: var(--blanco);
            }
        }
        .c-article__comment {
            color: var(--blanco);
        }
        .c-article__time {
            color: var(--blanco);
        }
        &.c-article-wink {
            .c-article {
                &__thumb,
                &__txt {
                    grid-row: 2 / span 1;
                }
                &__wink {
                    grid-row: 1 / span 1;
                }
            }
        }
        &-aspect-1 {
            .c-article__thumb {
                img {
                    height: 100%;
                }
            }
            .c-article__thumb-video {
                img {
                    height: 100%;
                }
            }
        }
        &.c-article-interview {
            .c-article__interview {
                p {
                    color: var(--blanco);
                    &:before {
                        color: var(--blanco);
                    }
                }
            }
            &.inicio {
                svg {
                    color: var(--blanco);
                }
            }
            .c-article__title {
                &:after {
                    background: url(../images/icon/cita-end-white.svg) no-repeat
                        center left;
                    background-size: 100%;
                }
            }
        }
        &.c-article-live {
            .c-article__live {
                &:before {
                    color: var(--blanco);
                }
                &:after {
                    color: var(--blanco);
                }
            }
        }
        &.c-article-exclusive {
            .c-article__exclusive {
                &:after {
                    color: var(--blanco);
                }
            }
        }
        &-vertical {
            .c-article__thumb {
                background: var(--negro);
                img {
                    opacity: 0.9;
                }
            }
            .c-article__txt {
                background-image: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0),
                    rgba(0, 0, 0, 0) 40%,
                    var(--negro)
                );
            }
            &.c-article-top {
                .c-article__txt {
                    justify-content: flex-start;
                    background-image: linear-gradient(
                        to top,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0) 40%,
                        var(--negro)
                    );
                }
            }
            &.c-article-no-gradient {
                .c-article__txt {
                    background-image: none;
                }
            }
        }
    }
    &-perfil {
        background-color: var(--fondo);
        display: flex;
        flex-direction: column-reverse;
        .c-article__txt {
            padding: 10px;
        }
        .c-article__thumb {
            margin-top: auto;
            align-self: end;
            img {
                width: 100%;
                object-fit: none;
                @media (--screen-sm-max) {
                    object-fit: cover;
                }
            }
        }
        &.u-border-xs {
            @media (--screen-xs-max) {
                margin-bottom: 32px !important;
            }
        }
        &.c-article-wink {
            .c-article__wink {
                order: 1;
                img {
                    margin-top: 10px;
                    margin-left: 10px;
                    margin-bottom: 0;
                }
            }
        }
    }
    &:not(:last-child) {
        margin-bottom: 16px;
        margin-top: 0;
        @media (--screen-md-min) {
            margin-bottom: 20px;
        }
    }
    &-opinion {
        background-color: var(--fondo);
        padding: 10px;
        position: relative;
        grid-template-columns: 1fr;
        .c-article {
            &__thumb {
                margin: 0 auto;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                width: max-content;
                display: none;
                img {
                    border-radius: 50%;
                    width: 105px;
                    height: 105px;
                }
            }
            &__thumb-video {
                margin: -10px -10px 0 -10px;
                display: block;
                border-radius: 0;
                width: auto;
                img {
                    border-radius: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &__txt {
                text-align: center;
            }
            &__subtitle {
                margin-bottom: 0;
                @media (--screen-md-min) {
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
            &__title {
                font-style: italic;
                padding-top: 9px;
            }
            &__epigraph_opinion {
                display: inline-block;
            }
            &-lateral {
                grid-template-columns: 1fr 100px;
                .c-article__txt {
                    word-break: break-word;
                    text-align: inherit;
                }
            }
        }
        &.u-nomargin {
            &-bottom {
                @media (--screen-xs-max) {
                    margin-bottom: 32px !important;
                    &:after {
                        margin-top: 0 !important;
                    }
                    &.u-col-2 {
                        margin-bottom: 0 !important;
                    }
                }
            }
            &-xs {
                &-bottom {
                    @media (--screen-xs-max) {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
        &.u-border-xs {
            &:after {
                grid-column: inherit;
            }
        }
        &-double {
            margin-bottom: 32px;
            .c-article {
                &__txt {
                    text-align: inherit;
                }
            }
            &:not(:last-child) {
                margin-bottom: 32px;
                &.u-nomargin-bottom {
                    margin-bottom: 32px !important;
                }
            }
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--bordes);
                max-width: 980px;
                position: absolute;
                left: 0;
                bottom: -32px;
                margin: 16px auto;
            }
            &.u-nomargin-bottom {
                &:last-child {
                    margin-bottom: 0 !important;
                    &:after {
                        display: none;
                    }
                    &.u-border-xs {
                        @media (--screen-xs-max) {
                            margin-bottom: 32px !important;
                            &:after {
                                display: block;
                            }
                        }
                    }
                }
            }
            @media (--screen-sm-min) {
                &:not(:last-child) {
                    margin-bottom: 40px;
                    &:after {
                        margin: 20px auto;
                        bottom: -40px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                    &:after {
                        display: none;
                    }
                }
                &.u-nomargin-bottom {
                    &:not(:last-child) {
                        margin-bottom: 40px !important;
                    }
                }
            }
        }
        &-opinion {
            &.c-article-author-image {
                .c-article__txt {
                    padding-left: 70px;
                    .c-article__epigraph {
                        color: var(--acento);
                    }
                }
                .c-article__image-author-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &.u-nomargin-bottom {
                @media (--screen-xs-max) {
                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
            &.u-border-xs:last-child {
                &:after {
                    display: none;
                }
            }
            .c-article {
                &__txt {
                    text-align: inherit;
                    .c-article__signature {
                        .c-article__author {
                            font-size: 14px;
                            font-weight: 600;
                        }
                        .c-article__author__job {
                            display: block;
                            font-size: 11px;
                            color: var(--texto-suave);
                        }
                    }
                }
                &__epigraph {
                    margin-bottom: 0;
                }
            }
        }
        .c-article__thumb_top {
            margin: -10px -10px 10px -10px;
            position: relative;
        }
        &.c-article-wink {
            .c-article__thumb_top {
                margin-top: 10px;
            }
        }
        /*Selector de tamaños de titular*/
        &.size-1 {
            .c-article {
                &__title {
                    @extend %ext__title-1;
                    .c-article__live {
                        &:before {
                            width: 8px;
                            height: 8px;
                            margin-top: -4px;
                        }
                    }
                }
            }
        }
        &.size-2 {
            .c-article {
                &__title {
                    @extend %ext__title-2;
                }
            }
            .c-opinion {
                &__title {
                    font-size: 26px;
                }
            }
        }
        &.size-3 {
            .c-article {
                &__title {
                    @extend %ext__title-3;
                }
            }
        }
        &.size-4 {
            .c-article {
                &__title {
                    @extend %ext__title-4;
                }
            }
        }
        &.size-5 {
            .c-article {
                &__title {
                    @extend %ext__title-5;
                }
            }
        }
        &.size-6 {
            .c-article {
                &__title {
                    @extend %ext__title-6;
                    margin-bottom: 5px;
                    & + .c-article__signature {
                        margin-bottom: 15px;
                        display: inline-block;
                    }
                }
            }
        }
        &.c-article {
            &-author-image {
                grid-template-columns: 1fr 120px;
                .c-article__thumb {
                    display: block;
                }
            }
            &-no-epigraph {
                .c-article__epigraph {
                    display: none;
                }
            }
            &-no-author {
                .c-opinion__signature {
                    display: none;
                }
            }
            &__txt-center {
                .c-article__thumb {
                    display: block;
                }
            }
        }
        .c-opinion__title {
            padding-top: 9px;
        }
    }
    &.c-board-opinion {
        .c-article__thumb {
            display: block;
        }
        .c-opinion__author {
            span {
                display: block;
                color: var(--texto);
                text-transform: uppercase;
                font-weight: 600;
            }
            span:nth-child(2) {
                display: none;
            }
        }
    }
    &-opinion-lateral-case-2 {
        align-items: center;
        grid-column-gap: 10px;
        grid-template-columns: 106px 1fr;
        @media (--screen-md-min) {
            grid-column-gap: 20px;
            grid-template-columns: 80px 1fr;
        }
        .c-article {
            &__thumb {
                img {
                    @media (--screen-md-min) {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
            &__title {
                font-size: 23px;
                @media (--screen-md-min) {
                    font-size: 23px;
                }
            }
            &__txt {
                word-break: break-word;
            }
        }
    }
    @media (--screen-xs-only) {
        &-lateral-xs {
            display: grid;
            grid-template-columns: 6fr 120px;
            grid-column-gap: 20px;
            .c-article {
                &__thumb {
                    grid-column-start: 2;
                    grid-row-start: 1;
                }
                &__thumb-video {
                    grid-column-start: 2;
                    grid-row-start: 1;
                }
                &__txt {
                    grid-column-start: 1;
                    grid-row-start: 1;
                }
            }
            &.u-border-xs {
                &:after {
                    margin: 16px auto;
                    grid-column: 1 / span 2;
                }
            }
        }
    }
    &-ratingnew {
        margin-right: 10px;
        @media (--screen-xs-max) {
            overflow: hidden;
        }
        &:not(:last-child) {
            margin-bottom: 0;
        }
        figure {
            margin-bottom: 5px;
            @media (--screen-sm-min) {
                margin-right: 7px;
                margin-bottom: 0;
            }
            img {
                width: 100%;
                height: 100%;
                @media (--screen-sm-min) {
                    width: 60px;
                    height: 60px;
                }
            }
        }
        div {
            @media (--screen-xs-max) {
                display: flex;
            }
            h3 {
                font-size: 14px;
                line-height: 18px;
                margin-top: 3px;
                color: var(--texto);
                @media (--screen-xs-max) {
                    height: 36px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                @media (--screen-sm-min) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .c-article__ratingnew__icon {
                color: var(--blanco);
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 5px;
                @media (--screen-sm-min) {
                    margin-right: 0;
                }
            }
        }
        @media (--screen-sm-min) {
            display: flex;
        }
        &-positive {
            .c-article__ratingnew__icon {
                display: flex;
                background: var(--auxiliares-acierto);
            }
            .icon_up {
                visibility: visible;
            }
        }
        &-negative {
            .c-article__ratingnew__icon {
                display: flex;
                background: var(--acento);
            }
            .icon_down {
                visibility: visible;
            }
        }
        &-neutral {
            .c-article__ratingnew__icon {
                display: flex;
                background: var(--auxiliares-advertencia);
            }
            .icon_question {
                visibility: visible;
            }
        }
    }
    &-double {
        &:first-child {
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--bordes);
                max-width: 980px;
                margin: 20px auto;
            }
            @media (--screen-xs-max) {
                &:after {
                    margin: 16px auto;
                }
            }
        }
        @media (--screen-xs-max) {
            &:after {
                margin: 16px auto;
            }
        }
        &.c-article-fix {
            &:first-child {
                &:after {
                    margin-bottom: 0;
                }
                @media (--screen-xs-max) {
                    &:after {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &-opening-reverse {
        .u-grid-col-span-2 {
            grid-column-start: 2;
            grid-row-start: 1;
            @media (--screen-xs-max) {
                grid-column-start: 1;
                grid-row-start: 2;
                &:before {
                    margin: 16px auto;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: var(--bordes);
                    max-width: 980px;
                }
                &.c-article__thumb-top {
                    &:before {
                        display: none;
                    }
                }
                .c-article {
                    &:before {
                        margin: 16px auto;
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background-color: var(--bordes);
                        max-width: 980px;
                    }
                    &.u-border-xs::after {
                        display: none;
                    }
                    &.c-article-fix::before {
                        display: none;
                    }
                }
            }
        }
        .u-grid-columns-3 .u-grid-col-span-2 {
            .u-grid-col-span-2 {
                grid-column-start: initial;
                grid-row-start: initial;
                &:before {
                    display: none;
                }
            }
            @media (--screen-xs-max) {
                .c-article {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    &-related {
        .c-article__related {
            display: block;
        }
    }
    &-no-epigraph {
        .c-article__epigraph {
            display: none;
        }
    }
    &-no-subtitle {
        .c-article__subtitle {
            display: none;
        }
    }
    &-no-author > .c-article__txt {
        .c-article__author {
            display: none;
        }
    }
    &-no-author div > .c-article__txt {
        .c-article__author {
            display: none;
        }
    }
    &-no-author > .c-opinion__txt {
        .c-opinion__signature {
            display: none;
        }
    }
    &-no-author > .c-related__txt {
        .c-article__author {
            display: none;
        }
    }
    &-author-no-job {
        .c-opinion__author {
            span[name="author-position"] {
                display: none;
            }
        }
    }
    &-bg {
        .c-article__txt {
            padding: 16px;
            + .c-article__related {
                padding: 0 16px 16px 16px;
                @media (--screen-md-min) {
                    padding: 0 16px 16px 16px;
                }
            }
        }
    }
    &-red {
        .c-article__txt {
            background-color: var(--acento);
        }
        .c-article__epigraph,
        .c-article__title,
        .c-article__subtitle,
        .c-article__author,
        .c-article__time,
        .c-article__comment,
        .c-article__pretitle {
            color: var(--blanco);
        }
        .c-article__title,
        .c-article__author,
        .c-related__title {
            a {
                color: var(--blanco);
            }
        }
        .c-article__related {
            background-color: var(--acento);
            .c-opinion__author,
            a {
                color: var(--blanco);
            }
            .icon {
                color: var(--fondo-rosa);
            }
        }
        .c-opinion-arrow .icon {
            color: var(--fondo-rosa);
        }
        .c-article__live {
            &:before {
                background-color: var(--fondo-rosa);
            }
            &:after {
                color: var(--fondo-rosa);
            }
        }
        .c-article__exclusive {
            &:after {
                color: var(--fondo-rosa);
            }
        }
        .c-article__interview {
            p {
                color: var(--blanco);
                &:before {
                    color: var(--blanco);
                }
            }
            &.inicio {
                svg {
                    color: var(--blanco);
                }
            }
        }
        .c-article__title {
            &:after {
                background: url(../images/icon/cita-end-white.svg) no-repeat
                    center left;
                background-size: 100%;
            }
        }
        .c-related-module-live {
            .c-related__icon {
                &:after {
                    color: var(--fondo-rosa);
                }
                &:before {
                    background-color: var(--fondo-rosa);
                }
            }
        }
    }
    &-red.c-article-perfil {
        background-color: var(--acento);
    }
    &-grey {
        .c-article__txt {
            background-color: var(--fondo-gris2);
        }
        .c-article__related {
            background-color: var(--fondo-gris2);
        }
    }
    &-grey.c-article-perfil {
        background-color: var(--fondo-gris2);
    }
    &-green {
        .c-article__txt {
            background-color: var(--fondo-verde);
        }
        .c-article__epigraph,
        .c-article__title,
        .c-article__subtitle,
        .c-article__author,
        .c-article__time,
        .c-article__comment,
        .c-article__pretitle {
            color: var(--blanco);
        }
        .c-article__title,
        .c-article__author,
        .c-related__title {
            a {
                color: var(--blanco);
            }
        }
        .c-article__related {
            background-color: var(--fondo-verde);
            .c-opinion__author,
            a {
                color: var(--blanco);
            }
            .icon {
                color: var(--fondo-verde2);
            }
        }
        .c-opinion-arrow .icon {
            color: var(--fondo-verde2);
        }
        .c-article__live {
            &:before {
                background-color: var(--fondo-verde2);
            }
            &:after {
                color: var(--fondo-verde2);
            }
        }
        .c-article__exclusive {
            &:after {
                color: var(--fondo-verde2);
            }
        }
        .c-article__interview {
            p {
                color: var(--fondo-verde2);
                &:before {
                    color: var(--fondo-verde2);
                }
            }
            &.inicio {
                svg {
                    color: var(--blanco);
                }
            }
        }
        .c-article__title {
            &:after {
                background: url(../images/icon/cita-end-white.svg) no-repeat
                    center left;
                background-size: 100%;
            }
        }
        .c-related-module-live {
            .c-related__icon {
                &:after {
                    color: var(--fondo-verde2);
                }
                &:before {
                    background-color: var(--fondo-verde2);
                }
            }
        }
    }
    &-green.c-article-perfil {
        background-color: var(--fondo-verde);
    }
    &-beige {
        .c-article__txt {
            padding: 16px;
            background-color: var(--fondo-beige);
        }
    }
    &-beige.c-article-perfil {
        background-color: var(--fondo-beige);
    }
    &-dark-grey {
        .c-article__txt {
            background-color: var(--enlace-oscuro);
        }
        .c-article__epigraph,
        .c-article__title,
        .c-article__subtitle,
        .c-article__author,
        .c-article__time,
        .c-article__comment,
        .c-article__pretitle {
            color: var(--blanco);
        }
        .c-article__title,
        .c-article__author,
        .c-related__title {
            a {
                color: var(--blanco);
            }
        }
        .c-article__related {
            background-color: var(--enlace-oscuro);
            .c-opinion__author,
            a {
                color: var(--blanco);
            }
            .icon {
                color: var(--fondo-oscuro2);
            }
        }
        .c-opinion-arrow .icon {
            color: var(--fondo-oscuro2);
        }
        .c-article__live {
            &:before {
                background-color: var(--fondo-oscuro2);
            }
            &:after {
                color: var(--fondo-oscuro2);
            }
        }
        .c-article__exclusive {
            &:after {
                color: var(--fondo-oscuro2);
            }
        }
        .c-article__interview {
            p {
                color: var(--fondo-oscuro2);
                &:before {
                    color: var(--fondo-oscuro2);
                }
            }
            &.inicio {
                svg {
                    color: var(--blanco);
                }
            }
        }
        .c-article__title {
            &:after {
                background: url(../images/icon/cita-end-white.svg) no-repeat
                    center left;
                background-size: 100%;
            }
        }
        .c-related-module-live {
            .c-related__icon {
                &:after {
                    color: var(--fondo-oscuro2);
                }
                &:before {
                    background-color: var(--fondo-oscuro2);
                }
            }
        }
    }
    &-dark-grey.c-article-perfil {
        background-color: var(--enlace-oscuro);
    }
    &-soft-green {
        .c-article__txt {
            background-color: var(--fondo-verde3);
        }
        .c-article__related {
            background-color: var(--fondo-verde3);
        }
        &.c-epigraph-highlighted {
            .c-article__interview p {
                color: var(--color-naranja);
                &:before {
                    color: var(--color-naranja);
                }
            }
            .c-article__comment {
                color: var(--color-naranja);
            }
        }
    }
    &-soft-green.c-article-perfil {
        background-color: var(--fondo-verde3);
    }
    &-last {
        &:last-child {
            margin-bottom: 0 !important;
            &:after {
                display: none;
            }
        }
    }
    &-book {
        .c-article {
            &__title {
                &.size-1 {
                    @extend %ext__title-1;
                    .c-article__live {
                        &:before {
                            width: 8px;
                            height: 8px;
                            margin-top: -4px;
                        }
                    }
                }
                &.size-2 {
                    @extend %ext__title-2;
                }
                &.size-3 {
                    @extend %ext__title-3;
                }
                &.size-4 {
                    @extend %ext__title-4;
                }
                &.size-5 {
                    @extend %ext__title-5;
                }
                &.size-6 {
                    @extend %ext__title-6;
                    margin-bottom: 5px;
                    & + .c-article__signature {
                        margin-bottom: 15px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    &-autoplay {
        .c-article {
            &__thumb {
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    background-image: linear-gradient(
                        10deg,
                        var(--negro) 0%,
                        rgba(0, 0, 0, 0) 50%
                    );
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 104px;
                }
            }
            &__icon {
                --c-icon-multimedia-bg: none;
                --c-icon-multimedia-color: var(--blanco);
                position: absolute;
                bottom: 20px;
                left: 20px;
                padding: 0;
                width: 64px;
                height: 64px;
                border: 4px solid var(--blanco);
                border-radius: 50%;
                span {
                    display: none;
                }
                svg {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
    /*Selector de tamaños de titular*/
    &.size-1 {
        .c-article {
            &__title {
                @extend %ext__title-1;
                .c-article__live {
                    &:before {
                        width: 8px;
                        height: 8px;
                        margin-top: -4px;
                    }
                }
            }
        }
    }
    &.size-2 {
        .c-article {
            &__title {
                @extend %ext__title-2;
            }
        }
    }
    &.size-3 {
        .c-article {
            &__title {
                @extend %ext__title-3;
            }
        }
    }
    &.size-4 {
        .c-article {
            &__title {
                @extend %ext__title-4;
            }
        }
    }
    &.size-5 {
        .c-article {
            &__title {
                @extend %ext__title-5;
            }
        }
    }
    &.size-6 {
        .c-article {
            &__title {
                @extend %ext__title-6;
                margin-bottom: 5px;
                & + .c-article__signature {
                    margin-bottom: 15px;
                    display: inline-block;
                }
            }
        }
    }
    &.c-epigraph-highlighted {
        .c-article__epigraph {
            color: var(--c-article-epigraph-highlighted-color);
        }
    }
    &.c-nogrid {
        display: block;
    }
    &.c-article__thumb-top {
        margin-bottom: 0;
    }


    &-calado{
        background-color: var(--negro);
        &.u-nomargin-bottom {
            margin-bottom: auto !important
        }
        &.u-border-xs:after {
            position: absolute;
            top: 100%
        }
        &:not(.c-article-lateral-md) {
            .c-article__txt {
                margin-top: -116px;
                padding-top: 32px;
                &:before {
                    background: transparent;
                    background: linear-gradient(180deg, transparent, var(--negro));
                    bottom: calc(100% - 116px);
                    content: "";
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            &.c-article-calado-vertical{
                &.c-article-top {
                    .c-article__txt {
                        margin-bottom: -116px;
                        margin-top: 0;
                        padding-top: 16px;
                        &:before {
                            background: var(--negro);
                            background: linear-gradient(180deg, var(--negro), transparent);
                            bottom: 0;
                            top: calc(100% - 116px)
                        }
                    }
                }
            }
        }
        &.c-article-interview{
            .c-article__title{
                &:after {
                    background: url(https://www.eldebate.com/images/icon/cita-end-white.svg) no-repeat 0;
                    background-size: 100%;
                    position: absolute;
                    transform: translateY(2px)
                }
            }
        }
        &.c-article-calado-vertical{
            &.c-article-top {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column-reverse;
                .c-article__txt {
                    justify-content: flex-start
                }
            }
            &.c-article-no-gradient {
                .c-article__txt {
                    background-image: none
                }
            }
        }
        &.c-article-lateral-md {
            .c-article__txt {
                margin-top: 16px;
                &:before{
                    @media (max-width: 991px){
                        background: var(--negro);
                        background: linear-gradient(180deg, var(--negro), transparent);
                        content: "";
                        height: 120px;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: calc(100% - 90px);
                    }
                    @media (min-width: 992px){
                        background: var(--negro);
                        background: linear-gradient(90deg, var(--negro), transparent);
                        bottom: 0;
                        content: "";
                        left: calc(100% - 100px);
                        position: absolute;
                        top: -16px;
                        width: 120px
                    }
                }

                @media (min-width: 992px){
                    padding-right: 32px;
                }
            }
            .c-article__thumb {
                &, img, picture {
                    @media (min-width: 992px){
                        height: 100%;
                    }
                }
                @media (max-width: 991px){
                    margin-top: -90px;
                    padding-bottom: 0
                }
                @media (min-width: 992px){
                    margin-left: -100px;
                }
            }
            @media (max-width: 991px){
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column-reverse;
            }
            @media (min-width: 992px){
                grid-column-gap: 0;
                grid-template-columns:4fr 6fr;
            }
        }
        .c-article__txt {
            padding: 16px;
            position: relative;
            .c-article__epigraph, a {
                color: var(--blanco);
                position: relative
            }
        }
        .c-article__signature-content {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .c-article__signature {
                margin-top: 0
            }
        }
        @media (max-width: 767px) {
            .c-article-calado {
                margin-bottom: 33px
            }
        }
    }
}
@keyframes bullet {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.u-bg {
    .c-branded {
        &:last-child {
            &.u-border-xs {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.marcador-portada {
    height: 33px;
    min-height: 33px;
}



.u-grid-columns-2 {
    &:not(.u-grid-col-span-2) {
        .c-article-calado {
            .c-article__txt {
                margin-top: -80px;
                &:before {
                    bottom: calc(100% - 80px);
                }
            }
            &.c-article-calado-vertical {
                &.c-article-top {
                    .c-article__txt {
                        margin-bottom: -65px;
                        margin-top: 0;
                        &:before {
                            bottom: 0;
                            top: calc(100% - 65px);
                        }
                    }
                }
            }
            .c-article__live {
                position: relative;
                &:before {
                    background-color: var(--fondo-rosa);
                    background-color: var(--fondo-rosa);
                    position: relative;
                }

                &:after {
                    padding-left: 0.7em;
                    padding-right: 0.2em;
                }
            }
            .c-article__exclusive:after,
            .c-article__interview p,
            .c-article__interview p:before,
            .c-article__interview.inicio svg,
            .c-article__live:after,
            .c-article__pretitle {
                color: var(--fondo-rosa);
                color: var(--fondo-rosa);
                position: relative;
            }
            &.c-article-live {
                .c-article__title {
                    .c-article__live {
                        &:before {
                            height: 0.5em;
                            left: 0;
                            margin: 0;
                            position: absolute;
                            top: calc(50% - 0.3em);
                            width: 0.5em;
                        }
                    }
                }
            }
        }
    }
    &.u-grid-col-span-3 {
        .u-grid-columns-2 {
            .c-article {
                &__icon {
                    width: 36px;
                    height: 36px;
                    padding: 0;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    &-xs.u-grid-columns-4 {
        .c-article {
            &__icon {
                width: 36px;
                height: 36px;
                padding: 0;
            }
        }
    }
}
.u-grid-columns-6 {
    .c-article {
        &__icon {
            width: 24px;
            height: 24px;
            padding: 0;
            bottom: 14px;
            left: 14px;
            span {
                display: none;
            }
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
.u-grid-columns-3 {
    .u-grid-columns-2 {
        .c-article-lateral {
            .c-article {
                &__icon {
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    bottom: 10px;
                    left: 10px;
                    span {
                        display: none;
                    }
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

    }
}


