.c-sidebar {
  &-dynamic {
    @media (--screen-sm-min) {
      display:flex;
      height:100%;
    }
  }
  &-content {
    @media (--screen-sm-min) {
      display: flex;
      height: 100%;
      flex-direction: column;
      &-sticky {
        height:100%;
        width:100%;
        &-add {
          position: sticky;
          top: 65px;
        }
      }
    }
  }

}

