.c-authorpage {
  &-head {
    display: flex;
    gap: 20px;
    border-bottom: solid 1px var(--bordes);
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (--screen-md-min) {
      flex-direction: row;
    }

    &__thumb {
      img {
        border-radius: 50%;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      @media (--screen-md-min) {
        align-items: flex-start;
      }
    }

    &__name {
      font-family: var(--third-font);
      font-size: 33px;
      line-height: 1.06;
      letter-spacing: -0.23px;
      color: var(--texto-basico);
    }
    &__social {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    &__following {
      margin-left: 8px;
    }
    &__subtitle {
      font-family: var(--secondary-font);
      font-size: 19px;
      line-height: 1.21;
      color: var(--texto-suave);
      font-weight: normal;
      text-align: center;

      @media (--screen-md-min) {
        font-size: 24px;
        text-align: left;
      }
    }

    .c-detail__closed-bluebox {
      bottom: -15px;
      transform: translateX(-20%);
    }
  }
}
