.u-grid{

  @media(max-width: 767px){
    &-xs{
      display: grid;
      grid-column-gap:16px;
      /*grid-row-gap: 16px;*/
    }
    &-columns-2-xs {
      display: grid;
      grid-column-gap:16px;
      grid-row-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
      &.u-border-xs {
        &:after {
          grid-column: 1 / span 2;
        }
      }
    }
    &-columns-3-xs {
      display: grid;
      grid-column-gap:var(--gap);
      grid-row-gap: var(--gap);
      grid-template-columns: repeat(3, 1fr);
      &.u-border-xs {
        &:after {
          grid-column: 1 / span 3;
        }

      }
    }

    &-col{
      &-xs-start-1{
        grid-column-start: 1 !important;
      }
      &-xs-start-2{
        grid-column-start: 2 !important;
      }
      &-xs-start-3{
        grid-column-start: 3 !important;
      }
      &-xs-start-4{
        grid-column-start: 4 !important;
      }
      &-xs-span-1{
        grid-column: 1 / span 1;
      }
      &-xs-span-2{
        grid-column: 1 / span  2;
      }
      &-xs-span-3{
        grid-column: 1 / span  3;
      }
      &-xs-span-4{
        grid-column: 1 / span 4;
      }
      &-xs-gap-0 {
        grid-column-gap: 0;
      }
    }
    &-row{
      &-xs-start-1{
        grid-row-start: 1;
      }
      &-xs-start-2{
        grid-row-start: 2 ;
      }
      &-xs-start-3{
        grid-row-start: 3;
      }
      &-xs-start-4{
        grid-row-start: 4;
      }
      &-xs-start-5{
        grid-row-start: 5;
      }
      &-xs-start-6{
        grid-row-start: 6;
      }
      &-xs-start-7{
        grid-row-start: 7;
      }
      &-xs-start-8{
        grid-row-start: 8;
      }
      &-xs-start-9{
        grid-row-start: 9;
      }
      &-xs-start-10{
        grid-row-start: 10;
      }

      &-xs-span-1{
        grid-row: 1 / span 1;
      }
      &-xs-span-2{
        grid-row: 1 / span  2;
      }
      &-xs-span-3{
        grid-row: 1 / span  3;
      }
      &-xs-span-4{
        grid-row: 1 / span 4;
      }
      &-xs-gap-0 {
        grid-row-gap: 0;
      }
    }
    &-sticky-add{
      padding-top: 16px;
    }
  }
  @media (min-width: 768px) {
    display: grid;
    grid-column-gap:var(--gap);
    grid-row-gap: var(--gap);
    align-items: self-start;

    &-columns-2 {
      grid-template-columns: repeat(2, 1fr);
      &.u-border {
        &:after {
          grid-column: 1 / span 2;
        }
        &.u-grid-related {
          &:after {
            max-width:735px;
            width:177%;
          }
          @media (min-width: 991px) {
              &:after {
                  max-width:940px;
                  width:155%;
              }
          }
        }
      }
    }
    &-columns-3 {
      grid-template-columns: repeat(3, 1fr);
      &.u-border {
        &:after {
          grid-column: 1 / span 3;
        }

      }
    }
    &-columns-4 {
      grid-template-columns: repeat(4, 1fr);
      &.u-border {
        &:after {
          grid-column: 1 / span 4;
        }
      }
    }
    &-columns-5 {
      grid-template-columns: repeat(5, 1fr);
      &.u-border {
        &:after {
          grid-column: 1 / span 5;
        }
      }
    }
    &-columns-6 {
      grid-template-columns: repeat(6, 1fr);
      &.u-border {
        &:after {
          grid-column: 1 / span 6;
        }
      }
    }
    &-columns-12 {
      grid-template-columns: repeat(12, 1fr);
      &.u-border {
        &:after {
          grid-column: 1 / span 12;
        }
      }
    }

    /*& > [class*="c-"]{
      flex: 1 1 auto;
      flex-direction: column;
    }*/

    &-start-1{
      grid-column-start: 1 !important;
    }
    &-start-2{
      grid-column-start: 2 !important;
    }
    &-start-3{
      grid-column-start: 3 !important;
    }
    &-start-4{
      grid-column-start: 4 !important;
    }
    &-start-5{
      grid-column-start: 5 !important;
    }
    &-col{
      &-start-1{
        grid-column-start: 1 !important;
      }
      &-start-2{
        grid-column-start: 2 !important;
      }
      &-start-3{
        grid-column-start: 3 !important;
      }
      &-start-4{
        grid-column-start: 4 !important;
      }
      &-start-5{
        grid-column-start: 5 !important;
      }
      &-start-6{
        grid-column-start: 6 !important;
      }
      &-start-7{
        grid-column-start: 7 !important;
      }
      &-start-8{
        grid-column-start: 8 !important;
      }
      &-start-9{
        grid-column-start: 9 !important;
      }
      &-span-1{
        grid-column: auto / span 1;
      }
      &-span-2{
        grid-column: auto / span  2;
      }
      &-span-3{
        grid-column: auto / span  3;
      }
      &-span-4{
        grid-column: auto / span 4;
      }
      &-span-5{
        grid-column: auto / span 5;
      }
      &-span-6{
        grid-column: auto / span 6;
      }
      &-span-7{
        grid-column: auto / span 7;
      }
      &-span-8{
        grid-column: auto / span 8;
      }
      &-gap-0 {
        grid-column-gap: 0;
      }
    }
    &-row{
      &-start-1{
        grid-row-start: 1 !important;
      }
      &-start-2{
        grid-row-start: 2 !important;
      }
      &-start-3{
        grid-row-start: 3 !important;
      }
      &-start-4{
        grid-row-start: 4 !important;
      }
      &-start-5{
        grid-row-start: 5 !important;
      }
      &-start-6{
        grid-row-start: 6 !important;
      }
      &-start-7{
        grid-row-start: 7 !important;
      }
      &-start-8{
        grid-row-start: 8 !important;
      }
      &-start-9{
        grid-row-start: 9 !important;
      }
      &-start-10{
        grid-row-start: 10 !important;
      }
      &-span-1{
        grid-row: 1 / span 1;
      }
      &-span-2{
        grid-row: 1 / span  2;
      }
      &-span-3{
        grid-row: 1 / span  3;
      }
      &-span-4{
        grid-row: 1 / span 4;
      }
      &-span-5{
        grid-row: 1 / span 5;
      }

      &-gap-0 {
        grid-row-gap: 0;
      }
    }
    &-col-gap-15{
      grid-column-gap: 15px;
    }
    &-col-gap-30{
      grid-column-gap: 30px;
    }
    &-gap-20{
      grid-column-gap:20px;
      grid-row-gap: 20px;
    }
    &-gap-30{
      grid-column-gap:30px;
      grid-row-gap: 30px;
    }
    &-gap-40{
      grid-column-gap:40px;
      grid-row-gap: 40px;
    }
    &-gap-60{
      grid-column-gap:60px;
      grid-row-gap: 60px;
    }
    &-sticky-add{
      display: flex;
      flex-direction: column;
      align-self: stretch;
      margin-bottom: 40px;
      .c-add-sticky{
          position: sticky;
          top: 45px;
      }
    }
  }
  @media(--screen-sm-only){
    &-sm{
        display: grid;
        grid-column-gap:16px;
        grid-row-gap: 16px;
        grid-template-columns: initial;
    }
    &-columns-1-sm {
        display: flex;
        flex-direction: column;
        article {
            width: 100%;
        }
    }
    &-columns-2-sm {
        display: grid;
        grid-column-gap:16px;
        grid-row-gap: 16px;
        grid-template-columns: repeat(2, 1fr);
        &.u-border-sm {
            &:after {
                grid-column: 1 / span 2;
            }
        }
    }
    &-columns-3-sm {
      display: grid;
      grid-column-gap:var(--gap);
      grid-row-gap: var(--gap);
      grid-template-columns: repeat(3, 1fr);
      align-items: initial;
      &.u-border-xs {
        &:after {
            grid-column: 1 / span 3;
        }
      }
    }
    &-row{
      &-sm-gap-0 {
          grid-row-gap: 0;
      }
    }
  }
  &-gap-15{
    grid-column-gap:15px;
    grid-row-gap: 15px;
  }
}

.u-border {
  .bg-modulo {
    .u-grid {
      &-row {
        &-gap-0 {
          .u-bg {
            &.u-margin-bt-sm {
              margin-bottom:0 !important;
            }
          }
        }
      }
    }
  }
}
