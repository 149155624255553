.c-aside{
  position: relative;
  color: var(--blanco);
  .o-container{
    background-color: var(--fondo-oscuro);
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__group{
    display: flex;
    flex-direction: row;
    align-items: center;
    &:nth-child(1){
      justify-content: flex-start;
      & > * {
        &:not(:last-child){
          margin-right: 10px;
        }
      }
    }
    &:nth-child(2){
      justify-content: flex-end;
      & > * {
          &:not(:last-child){
              margin-right: 10px;
          }
      }
    }
  }
  &__weather{
    &__location{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-transform: uppercase;
      & > * {
        &:not(:last-child, .c-aside__weather__max){
          margin-right: 10px;
        }
      }
    }
    &__max{
      color: var(--auxiliares-error);
      margin-right: 4px;
    }
    &__min{
      color: var(--auxiliares-informacion);
    }

  }
  &__button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--blanco);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-transform: uppercase;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
  }
  @media (--screen-md-min) {
    display: block;
  }

}
