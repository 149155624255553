.c-highlight {
    position: relative;
    z-index: 1;
    display: grid;
    overflow: hidden;
    &__thumb {
        grid-column-start: 1;
        grid-row-start: 1;
        z-index: 1;
        position: relative;

        &:after {
            content: "";
            width: 100%;
            height: 25%;
            display: block;
            z-index: 1;
            bottom: 0;
            background-image: linear-gradient(
                    0,
                    rgba(0, 0, 0, 0.7) 0,
                    rgba(0, 0, 0, 0.7) 15%,
                    rgba(0, 0, 0, 0) 40%
            );
            position: absolute;
            @media (--screen-md-min) {
                bottom: inherit;
                top: 0;
                background-image: linear-gradient(
                        90deg,
                        rgba(0, 0, 0, 0.7) 0%,
                        rgba(0, 0, 0, 0.07) 40%
                );
                left: 50%;
                height: 100%;
                margin-left: calc(-1254px / 2);
            }
        }
        img {
            z-index: -1;
            width: 100%;
            height: 100%;
            min-height: 211px;
            object-fit: cover;
            @media (--screen-md-min) {
                min-height: 360px;
                max-height: 360px;
            }
        }
    }
    &__txt {
        grid-column-start: 1;
        grid-row-start: 2;
        align-self: end;
        max-width: 1254px;
        width: 100%;
        margin: 0 auto;
        z-index: 3;
        .o-container {
            background: transparent;
            margin-bottom: 0;
            padding-bottom: 0;
            position: relative;
            @media (--screen-md-min) {
                padding-bottom: 20px;
            }
        }
        @media (--screen-md-min) {
            grid-row-start: 1;
            grid-row: 1 / span 2;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
    &__category {
        color: var(--white);
        line-height: 1.07;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    &__title {
        color: var(--white);
        font-size: 25px;
        font-weight: 800;
        line-height: 1.52;
        margin-bottom: 10px;
        max-width: 22ch;
    }
    &__subtitle {
        color: var(--white);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
        margin-bottom: 15px;
        max-width: 30ch;
    }
    &__cta {
        @extend %ext__btn-cta;
    }
    &__play {
        @extend %ext__btn-play;
    }
    &__link {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
            z-index: 4;
        }
    }
    @media (--screen-md-min) {
        &:after {
            background-color: rgba(0, 0, 0, 0.7);
            content: "";
            --middle: calc(1254px / 2);
            margin-left: 0;
            text-align: right;
            right: calc(50% + var(--middle));
            width: 100vw;
            position: absolute;
            height: 100%;
            display: block;
            z-index: 2;
        }
    }
}
