.u-bg{
    padding: 16px;
    &-fondo{
      background-color: var(--fondo);
      .c-line {
            background-color: var(--bordes);
        }
      .u-border {
            &:after {
                background-color: var(--bordes);
            }
            &-xs {
                &:after {
                    background-color: var(--bordes);
                }
            }
        }
      .c-article-double {
            &:first-child {
                &::after {
                    background-color: var(--fondo-intermedio);
                }
            }
        }
      .c-branded.c-branded-lateral-md {
        &:last-child {
          @media (--screen-xs-max) {
            margin-bottom: 0 !important;
          }
        }
      }
      &.u-bg-blanco {
        .c-branded.c-branded-lateral-md {
          &:last-child {
            @media (--screen-xs-max) {
              margin-bottom: 16px !important;
            }
          }
        }
      }
      &-pagina{
        background-color: var(--fondo-pagina);
      }
    }
    &-gris{
        background-color: var(--fondo-gris);
    }
    &-gris2{
      background-color: var(--fondo-gris2);
      .c-article-double {
        &:first-child {
          &::after {
            background-color: var(--bordes);
          }
        }
      }
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
          background: var(--fondo-gris2);
        }
      }
    }
    &-gris3{
      background-color: var(--enlace-oscuro);
      .c-title .c-title__name {
            color: var(--blanco);
        }
      .c-line {
            background-color: var(--blanco);
        }
      .u-border {
            &:after {
                background-color: var(--blanco);
            }
            &-xs {
                &:after {
                    background-color: var(--blanco);
                }
            }
      }
      .c-article-double {
        &:first-child {
            &::after {
                background-color: var(--blanco);
            }
        }
      }
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
          background: var(--fondo-verde);
        }
      }
      .c-article:not(.c-article-opinion, .c-article-fix, .c-article-perfil, .c-branded, .c-article-grey, .c-article-beige, .c-article-soft-green, .c-article-bg-modulo){
        .c-article__epigraph, .c-article__title, .c-article__subtitle, .c-article__author, .c-article__time, .c-article__comment, .c-article__pretitle {
              color: var(--blanco);
          }
        .c-article__title, .c-article__author, .c-related__title,.c-article__epigraph  {
              a{
                  color: var(--blanco);
              }
          }
        .c-branded__epigraph {
              color: var(--blanco);
          }
        .c-article__related {
            .c-opinion__author, a{
              color: var(--blanco);
            }
          }
        &:not(.c-article-dark-grey, .c-article-red, .c-article-green) {
            .c-article__related {
              .c-opinion-arrow .icon {
                color: var(--fondo-verde2);
              }
              .c-related-arrow {
                .icon_arrow {
                  color: var(--fondo-verde2);
                }
              }
              .c-related-module-live {
                .c-related__icon {
                  &:after {
                    color: var(--fondo-verde2);
                  }
                  &:before {
                    background-color: var(--fondo-verde2);
                  }
                }
              }
            }
            &.c-article-interview {
                  .c-article__interview{
                      p {
                          color: var(--fondo-verde2);
                          &:before{
                              color: var(--fondo-verde2);
                          }
                      }
                      &.inicio {
                          svg {
                              color: var(--blanco);
                          }
                      }
                      .c-article__title{
                          &:after{
                              background: url(../images/icon/cita-end-white.svg) no-repeat center left;
                              background-size: 100%;
                          }
                      }
                  }
                  .inicio {
                    svg {
                        color: var(--blanco);
                    }
                  }
                  .c-article__title{
                      &:after{
                          background: url(../images/icon/cita-end-white.svg) no-repeat center left;
                          background-size: 100%;
                      }
                  }
              }
            &.c-article-live {
                  .c-article__live{
                      &:before {
                          background-color: var(--fondo-verde2);
                      }
                      &:after{
                          color: var(--fondo-verde2);
                      }
                  }
              }
            &.c-article-exclusive {
                  .c-article__exclusive{
                      &:after{
                          color: var(--fondo-verde2);
                      }
                  }
              }
          }
      }
      .u-bg-gris2.bg-modulo {
          .c-line {
              background-color: var(--bordes);
          }
          .u-border {
              &:after {
                  background-color: var(--bordes);
              }
              &-xs {
                  &:after {
                      background-color: var(--bordes);
                  }
              }
          }
      }
      .c-branded__epigraph {
        color: var(--blanco);
      }
    }
    &-blanco{
      background-color: var(--blanco);
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
          background: var(--blanco);
        }
      }
    }
    &-crema {
      background-color: var(--fondo-crema);
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
          background: var(--fondo-crema);
        }
      }
    }
    &-opinion {
      background-color: var(--fondo);
      .c-line {
            background-color: var(--fondo-intermedio);
        }
      .u-border {
            &:after {
                background-color: var(--fondo-intermedio);
            }
            &-xs {
                &:after {
                    background-color: var(--fondo-intermedio);
                }
            }
        }
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
          background: var(--fondo);
        }
      }
      .u-bg-gris2.bg-modulo {
        .c-line {
            background-color: var(--bordes);
        }
        .u-border {
            &:after {
                background-color: var(--bordes);
            }
            &-xs {
                &:after {
                    background-color: var(--bordes);
                }
            }
        }
        .u-grid-row-xs-gap-0 {
          .u-bg {
            &.u-margin-bt-sm {
              margin-bottom:0 !important;
            }
          }
        }
      }
      .c-article-opinion {
          padding: 0;
      }
    }
    &-urgente {
      background-color: var(--acento);
      .c-title .c-title__name {
        color: var(--blanco);
      }
      .c-line {
        background-color: var(--blanco);
      }
      .u-border {
        &:after {
            background-color: var(--blanco);
        }
        &-xs {
            &:after {
                background-color: var(--blanco);
            }
        }
      }
      .c-article-double {
        &:first-child {
            &::after {
                background-color: var(--blanco);
            }
        }
      }
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
            background: var(--acento);
        }
      }
      .c-article:not(.c-article-opinion, .c-article-fix, .c-article-perfil, .c-branded, .c-article-grey, .c-article-beige, .c-article-soft-green, .c-article-bg-modulo) {
          .c-article__epigraph, .c-article__title, .c-article__subtitle, .c-article__author, .c-article__time, .c-article__comment, .c-article__pretitle {
              color: var(--blanco);
          }
          .c-article__title, .c-article__author, .c-related__title {
              a{
                  color: var(--blanco);
              }
          }
          .c-article__related {
              .c-opinion__author, a{
                  color: var(--blanco);
              }
          }
          &:not(.c-article-dark-grey, .c-article-red, .c-article-green) {
            .c-article__related {
              .c-opinion-arrow .icon {
                color: var(--fondo-rosa);
              }
              .c-related-arrow {
                .icon_arrow {
                  color: var(--fondo-rosa);
                }
              }
              .c-related-module-live {
                .c-related__icon {
                  &:after {
                    color: var(--fondo-rosa);
                  }
                  &:before {
                    background-color: var(--fondo-rosa);
                  }
                }
              }
            }
            &.c-article-live {
                  .c-article__live{
                      &:before {
                          background-color: var(--fondo-rosa);
                      }
                      &:after{
                          color: var(--fondo-rosa);
                      }
                  }
              }
            &.c-article-exclusive {
                  .c-article__exclusive{
                      &:after{
                          color: var(--fondo-rosa);
                      }
                  }
              }
            &.c-article-interview {
                  .c-article__interview{
                      p {
                          color: var(--blanco);
                          &:before{
                              color: var(--blanco);
                          }
                      }
                      &.inicio {
                          svg {
                              color: var(--blanco);
                          }
                      }
                      .c-article__title{
                          &:after{
                              background: url(../images/icon/cita-end-white.svg) no-repeat center left;
                              background-size: 100%;
                          }
                      }
                  }
                  .inicio {
                    svg {
                        color: var(--blanco);
                    }
                  }
                  .c-article__title{
                      &:after{
                          background: url(../images/icon/cita-end-white.svg) no-repeat center left;
                          background-size: 100%;
                      }
                  }
              }
            .c-related-arrow {
                  .icon{
                      &.icon_arrow {
                          color: var(--fondo-rosa);
                      }
                  }
              }
          }
      }
      .u-bg-gris2.bg-modulo {
          .c-line {
             background-color: var(--bordes);
          }
          .u-border {
              &:after {
                  background-color: var(--bordes);
              }
              &-xs {
                  &:after {
                      background-color: var(--bordes);
                  }
              }
          }
        }
      .c-branded__epigraph {
        color: var(--blanco);
      }
    }
    &-deportes {
      background-color: var(--fondo-verde);
        &--light {
            background-color: var(--fondo-verde4);
        }
        .c-title .c-title__name {
            color: var(--blanco);
        }
      .c-line {
            background-color: var(--blanco);
        }
      .u-border {
            &:after {
                background-color: var(--blanco);
            }
            &-xs {
                &:after {
                    background-color: var(--blanco);
                }
            }
      }
      .c-article-double {
        &:first-child {
            &::after {
                background-color: var(--blanco);
            }
        }
      }
      .c-branded.c-branded-lateral-md {
        .c-branded__epigraph {
          background: var(--fondo-verde);
        }
      }
      .c-article:not(.c-article-opinion, .c-article-fix, .c-article-perfil, .c-branded, .c-article-grey, .c-article-beige, .c-article-soft-green, .c-article-bg-modulo){
        .c-article__epigraph, .c-article__title, .c-article__subtitle, .c-article__author, .c-article__time, .c-article__comment, .c-article__pretitle {
              color: var(--blanco);
          }
        .c-article__title, .c-article__author, .c-related__title,.c-article__epigraph  {
              a{
                  color: var(--blanco);
              }
          }
        .c-branded__epigraph {
              color: var(--blanco);
          }
        .c-article__related {
            .c-opinion__author, a{
              color: var(--blanco);
            }
          }
        &:not(.c-article-dark-grey, .c-article-red, .c-article-green) {
            .c-article__related {
              .c-opinion-arrow .icon {
                color: var(--fondo-verde2);
              }
              .c-related-arrow {
                .icon_arrow {
                  color: var(--fondo-verde2);
                }
              }
              .c-related-module-live {
                .c-related__icon {
                  &:after {
                    color: var(--fondo-verde2);
                  }
                  &:before {
                    background-color: var(--fondo-verde2);
                  }
                }
              }
            }
            &.c-article-interview {
                  .c-article__interview{
                      p {
                          color: var(--fondo-verde2);
                          &:before{
                              color: var(--fondo-verde2);
                          }
                      }
                      &.inicio {
                          svg {
                              color: var(--blanco);
                          }
                      }
                      .c-article__title{
                          &:after{
                              background: url(../images/icon/cita-end-white.svg) no-repeat center left;
                              background-size: 100%;
                          }
                      }
                  }
                  .inicio {
                    svg {
                        color: var(--blanco);
                    }
                  }
                  .c-article__title{
                      &:after{
                          background: url(../images/icon/cita-end-white.svg) no-repeat center left;
                          background-size: 100%;
                      }
                  }
              }
            &.c-article-live {
                  .c-article__live{
                      &:before {
                          background-color: var(--fondo-verde2);
                      }
                      &:after{
                          color: var(--fondo-verde2);
                      }
                  }
              }
            &.c-article-exclusive {
                  .c-article__exclusive{
                      &:after{
                          color: var(--fondo-verde2);
                      }
                  }
              }
          }
      }
      .u-bg-gris2.bg-modulo {
          .c-line {
              background-color: var(--bordes);
          }
          .u-border {
              &:after {
                  background-color: var(--bordes);
              }
              &-xs {
                  &:after {
                      background-color: var(--bordes);
                  }
              }
          }
      }
      .c-branded__epigraph {
        color: var(--blanco);
      }
    }
    &-salud {
        background-color: var(--fondo-verde3);
        .c-branded.c-branded-lateral-md {
            .c-branded__epigraph {
                background: var(--fondo-verde3);
            }
        }
        .c-article:not(.c-article-opinion, .c-article-fix, .c-article-perfil, .c-branded, .c-article-grey, .c-article-beige, .c-article-bg-modulo, .c-article-dark-grey, .c-article-red, .c-article-green) {
            &.c-article-interview {
                &.c-epigraph-highlighted {
                    .c-article__interview p {
                        color: var(--color-naranja);
                        &:before {
                            color: var(--color-naranja);
                        }
                    }
                    .c-article__comment {
                        color: var(--color-naranja);
                    }
                }
            }
        }
    }
}
